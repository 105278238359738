import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WorkitemFormCaptureService {

  constructor(private commonService: CommonService) {
  }

  onLoad(formData: any){
   this.commonService.logging("-- Workitem Form Loaded --")
   this.setMinLengthForQuantity(formData)
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Workitem')
  }

  onFieldChange(tableId : string, formId : string,formData: any,comp:any){
    this.commonService.logging('On field Change for Workitem')
    this.setFormFields(formData,comp)
  }

  onSubgridRefresh(formData: any, comp: any, headerData: any, rowData: any){
    this.commonService.logging('On Subgrid Refresh for Workitem')
    return
    // return this.setParentFormFields(formData,comp,headerData,rowData)
  }

  setFormFields(formData : any,component: any){
    let row
    if(component && component.selectedOptionLabel && component.selectedOptionLabel.id){
      for(let i of component.allTableData){
        if(i[component.lookUpTablePrimaryColumn] == component.selectedId){
          row = i
        }
      }
      if(row){
        for(let i of formData){
          for(let sec of i.section){
            for(let comp of sec.components){
              if(comp.id){
                if(comp.id.includes("new_quantity")){
                  comp.value  = 1
                }
                if(comp.id.includes("cr400_price") && comp.datatype=='amount'){
                  let val = row[comp.id+"@OData.Community.Display.V1.FormattedValue"]
                  if(val){
                    comp.currency = val.charAt(0)
                    let num = row[comp.id]
                    comp.value = (Math.round(num * 100) / 100).toFixed(2);
                    comp.baseValue = (Math.round(num * 100) / 100).toFixed(2)
                  }
                }
                if(comp.id.includes("cr400_category")){
                  comp.value =  row[comp.id+"@OData.Community.Display.V1.FormattedValue"]
                }
              }
            }
          }
        }
      }
    }

    if(component && component.id.includes("new_quantity")){
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id.includes("cr400_price") && comp.datatype=='amount'){
              if(comp.baseValue){
                comp.value = (Math.round(comp.baseValue * Number(component.value) * 100) / 100).toFixed(2);
              }
              else{
                comp.baseValue = comp.value
                comp.value =  (Math.round(comp.baseValue * Number(component.value) * 100) / 100).toFixed(2);
              }
            }
          }
        }
      }
    }
    
  }

  setParentFormFields(formData: any, comp: any, headerData: any, rowData: any){
    let totalPrice = 0
    let taxPercent  = 0
    let discount = 0
    let couponId = 0
    for(const row of comp.tableData){
      totalPrice += row['cr400_price'] //*  (row['new_quantity'] ? row['new_quantity'] : 1)
    }

    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          // if(comp.id && comp.id.includes('cr400_totalamount@OData.Community.Display.V1.FormattedValue')){
          //   rupeeSymbol = comp.value ? comp.value.charAt(0) : ''
          // }
          
          if(comp.id && comp.id == 'new_discount'){
            discount = comp.value
          }
          if(comp.id && comp.id == 'new_tax'){
            taxPercent = comp.value
          }
          if(comp.id && comp.id == "new_couponcode"){
            if(comp.selectedItem && comp.selectedItem.length>0){
              let val = comp.selectedItem[0].item_text
              for(let i of comp.options){
                if(i.item_text == val && i.item_val){
                  couponId = i.item_id
                }
              }
            }
          }
        }
      }
    }

    

    if(rowData && rowData['cr400_totalamount'] !== totalPrice){
      let charVal =  rowData['cr400_totalamount@OData.Community.Display.V1.FormattedValue'] ?  rowData['cr400_totalamount@OData.Community.Display.V1.FormattedValue'].charAt(0) : ''
      rowData['cr400_totalamount'] = totalPrice
      rowData['cr400_totalamount@OData.Community.Display.V1.FormattedValue'] = charVal+totalPrice
      
      return {
        'cr400_totalamount':totalPrice,
        'new_discountedamount': discount,
        'new_tax': taxPercent,
        'new_couponcode': couponId,
        'new_finalamount' : Number(totalPrice) - (Number(totalPrice) * (Number(rowData["new_discount"])/100))
      }
    }
    return false;
  }

  setMinLengthForQuantity(formData: any){
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id && comp.id.includes("new_quantity")){
            comp.minValue = 0
          }
        }
      }
    }
  }

}
