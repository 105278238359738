
import {Component, ViewChild, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy, AfterViewInit } from '@angular/core';
import {CdkPortal,DomPortalOutlet} from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'window',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `
})
export class WindowComponent implements OnInit, OnDestroy, AfterViewInit {

  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal) portal?: CdkPortal;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow : any = null;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector){}


    ngOnInit(){
    }
    ngAfterViewInit(){
      // STEP 4: create an external window
      this.externalWindow = window.open('', '', 'width=1000,height=600,left=200,top=200');


      this.externalWindow.document.write('<html><head>'+
      '<link rel="stylesheet" type="text/css" href="assets/scss/myStyle.scss">'+
      '</head><body>');

      // this.externalWindow.document.write('<html><head><style type="text/css">.button-icon{width: 18px;height: 18px;margin-right: 6px;}</style></head><body>')


      // STEP 5: create a PortalHost with the body of the new window document
      const host = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
        );

      document.querySelectorAll('link, style').forEach(htmlElement => {
          this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
      });

      // STEP 6: Attach the portal
      host.attach(this.portal);
    }

    ngOnDestroy(){
      // STEP 7: close the window when this component destroyed
      this.externalWindow.close()
    }
}
