import { Component, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomePageComponent } from "./home-page.component";
import { MatTabsModule } from "@angular/material/tabs"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavComponent } from "../nav/nav.component";
import { FooterComponent } from "../footer/footer.component";
// import { LoginModule } from "../login/login.module";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "../login/login-component";
import { LoaderComponent } from "../loader/loader.component";
import { ProfileComponent } from "../profile/profile.component";
import { CommonModule } from "@angular/common";
import { MainComponent } from "../main/main.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ApplicationComponent } from "../application/application.component";
import { SideNavComponent } from "../side-nav/side-nav.component";
import { ChartsModule } from "ng2-charts";
import { AngularTableComponent } from "../angular-table/angular-table.component";
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { MatSortModule } from "@angular/material/sort";
import {MatInputModule} from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { FormComponent } from "../form/form.component";
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ShareDialogComponent } from "../share-dialog/share-dialog.component";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { StandardFormComponent } from "../standard-form/standard-form.component";
import { AppButtonComponent } from "../app-button/app-button.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { WindowComponent } from "../standard-form/window.component";
import { PortalModule } from "@angular/cdk/portal";
import { MatNativeDateModule } from "@angular/material/core";
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NewWndowComponent } from "../new-wndow/new-window.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { WoodsKitchenComponent } from "../woods-kitchen/woods-kitchen.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TermsAndConditionComponent } from "../terms-and-condition/terms-and-condition.component";
import { QuickCreateComponent } from "../quick-create/quick-create.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NotesComponent } from "../notes/notes.component";
import { PageNotFoundComponent } from "../page-not-found/page-not-found.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CheckInCheckOutComponent } from "../checkin-checkout/checkin-checkout-component";
import { WebcamModule } from "ngx-webcam";
import { CameraComponent } from "../camera/camera-component";
import { MatRadioModule } from '@angular/material/radio';
import { PromotionComponent } from "../promotion/promotion.component";
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { SearchPipe } from "../promotion/contacts.pipe";
import { QuickAddComponent } from "../quick-add/quick-add.component";
// import { MDBBootstrapModule } from "angular-bootstrap-md";

const myRoutes: Routes = [
  {
    path: 'mbp',
    component: MainComponent,
  },
  {
    path: 'at',
    component: MainComponent,
  },
  {
    path: 'lms',
    component: MainComponent,
  },
  {
    path: 'mv',
    component: MainComponent,
  },
  {
    path: 'hm',
    component: MainComponent,
  },
  {
    path: 'nbp',
    component: MainComponent,
  },
  {
    path: 'sbm',
    component: MainComponent,
  },
  // { path: '',   redirectTo: 'mbp', pathMatch: 'full' },
  {
    path:'application',
    component: ApplicationComponent
  },
  {
    path:'showDetails/:count/:compName',
    component: NewWndowComponent
  },
  {
    path:'woods-kitchen',
    component: WoodsKitchenComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

export const myRouting = RouterModule.forChild(myRoutes);


@NgModule({
    declarations: [
      HomePageComponent,
      NavComponent,
      FooterComponent,
      LoginComponent,
      LoaderComponent,
      ProfileComponent,
      MainComponent,
      SideNavComponent,
      ApplicationComponent,
      ApplicationComponent,
      AngularTableComponent,
      FormComponent,
      ConfirmationDialogComponent,
      ShareDialogComponent,
      StandardFormComponent,
      AppButtonComponent,
      WindowComponent,
      NewWndowComponent,
      ResetPasswordComponent,
      WoodsKitchenComponent,
      TermsAndConditionComponent,
      QuickCreateComponent,
      NotesComponent,
      PageNotFoundComponent,
      CheckInCheckOutComponent,
      CameraComponent,
      PromotionComponent,
      SearchPipe,
      QuickAddComponent
    ],
    imports: [
        myRouting,
        MatTabsModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        ChartsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        BsDropdownModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        ToastrModule.forRoot({
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-custom'
        }),
        MatDialogModule,
        MatSlideToggleModule,
        NgSelectModule,
        PortalModule,
        MatNativeDateModule,
        MatDatepickerModule,
        Ng2SearchPipeModule,
        MatTooltipModule,
        WebcamModule,
        MDBBootstrapModule.forRoot()
    ],
    exports: [HomePageComponent],
    entryComponents: [ConfirmationDialogComponent]
})
export class HomeModule{}


// children: [
//   {
//     path:'',
//     component: MainComponent,
//     outlet:'child',
//     pathMatch: 'full'
//   },
//   {
//     path:'profile',
//     component: ProfileComponent,
//     outlet:'child',
//     pathMatch: 'full'
//   },
//   // {
//   //   path:'notes', component: DepositbComponent
//   // }
// ]
