<div class="home-default" id="loginBackGround">



<div style="margin-top: 3rem;" class="login-container">
  <img *ngIf="logo_details" [src]="logo_details.logo_base64"
  [ngStyle]='{
    width : logo_details.logo_width  + "px",
    height : logo_details.logo_height + "px"
  }'
  class="logo"
  >

  <img *ngIf="logo_details" [src]="logo_details.default_home_image" class="default-home logo"
  [ngStyle]='{
    width : logo_details.default_home_image_width + "px",
    height: logo_details.default_home_image_height + "px"
  }'
  >

  <div id="mycontainer" class="center-content" [ngStyle]="{
    opacity: showLoader ? '0.5' : '1',
    'pointer-events': showLoader ? 'none' : 'all'
  }">
    <form #loginForm="ngForm" class="loginForm" (ngSubmit)="validateUser(loginForm)">

      <!-- Email input -->
      <div class="form-outline mb-4 hasValue">
        <input type="text" id="loginName" class="form-control" name="email" required [(ngModel)]="emailId"  (focus)="callCssForInputFocus()"/>
        <label class="form-label" for="loginName">Email</label>
      </div>

      <!-- Password input -->
      <div class="form-outline mb-4">
        <input type="password" id="loginPassword" class="form-control" name="password" required  [(ngModel)]="pass" (focus)="callCssForInputFocus()"/>
        <label class="form-label" for="loginPassword">Password</label>
      </div>

      <!-- 2 column grid layout -->
      <div class="row mb-4">
        <div class="col-md-6 d-flex justify-content-center">
          <!-- Checkbox -->
          <div class="form-check mb-3 mb-md-0" (click)="callCssForCheckBox()">
            <input class="form-check-input" type="checkbox" value="" id="loginCheck" checked />
            <label class="form-check-label" for="loginCheck"> &nbsp; Remember me </label>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center">
          <!-- Simple link -->
          <span  class="forgot-password" (click)="openForgotPasswordModal()">Forgot password?</span>
        </div>
      </div>

      <!-- Submit button -->
      <button type="submit" class="btn btn-primary btn-block mb-4"
        [disabled]="!loginForm.valid">Sign in</button>

        <div class="alert alert-danger text-center" *ngIf="errorMessage && errorMessage!=''">{{errorMessage}}</div>
      <!-- Register buttons -->
      <!-- <div class="text-center">
                <p>Not a member? <a href="#!">Register</a></p>
              </div> -->
    </form>
  </div>
</div>


<div class="modal" id="myOtpModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="!showVerified ,else OtpVerfied" style="height:260px;width:390px">
      <div class="modal-body">
        <div class="card py-5 px-3">
          <h4 class="m-0">Mobile phone verification</h4>
          <span class="mobile-text">Enter the code we just send on your mobile phone <b
              class="text-danger">{{signUpForm.value.mno}}</b></span>
          <form class="otp" [formGroup]="otpForm" style="margin-top: 20px;">
            <div class="d-flex flex-row mt-5">
              <input autofocus type="text" class="form-control mg-5" formControlName="otp1"
                maxlength="1">
              <input type="text" class="form-control mg-5" formControlName="otp2" maxlength="1">
              <input type="text" class="form-control mg-5" formControlName="otp3" maxlength="1">
              <input type="text" class="form-control mg-5" formControlName="otp4" maxlength="1">
              <input type="text" class="form-control mg-5" formControlName="otp5" maxlength="1">
            </div>
            <div class="text-center mt-5">
              <span class="d-block mobile-text">Don't receive the code?</span>
              <span class="font-weight-bold text-danger cursor"
                (click)="resendOtp(signUpForm.value.mno)"><b>Resend</b></span>
            </div>
            <div class="text-danger" *ngIf="errorMessage!=''">{{errorMessage}}</div>
            <button type="button" class="btn btn-primary confirmButton" (click)="confimOtp()" [disabled]="!otpForm.valid">Confirm
              OTP</button>

          </form>

        </div>
      </div>
    </div>
    <ng-template #OtpVerfied>
      <div class="modal-content" style="width: 340px;height: 240px;">
        <div class="modal-body">
          <img src="../../assets/Images/savedSuccess.gif" class="saveSuccess">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="verify">User verified successfully</div>
        </div>
      </div>
    </ng-template>
  </div>
</div>


<div class="modal" id="forgotPasswordModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="!emailVerified" style="height:260px;width:390px">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeForgotPasswordModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="card py-5 px-3" *ngIf="modalSuccessMessage == ''; else sentEmail">
          <h4 class="m-0">Enter your user name</h4>
          <input type="email" style="margin:20px 0px" [(ngModel)] = "emailAddress" class="form-control"  #Email="ngModel">
          <div class="text-danger" style="font-size:12px" *ngIf="modalErrorMessage !== ''">
            {{modalErrorMessage}}
         </div>
          <button type="button" class="btn btn-primary  submit-btn" [disabled]="!emailAddress" (click)="verifyEmail()">Send password reset email</button>
        </div>
        <ng-template #sentEmail>
          <img src="../../assets/Images/savedSuccess.gif" class="saveSuccess">
          <div class="verify">{{modalSuccessMessage}}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<app-loader [icon]="progressBarIcon" [left]="'46%'" [width]="'100px'" *ngIf="showLoader"></app-loader>

</div>
<!-- //code -->
<!-- <mat-tab-group #tabGroup> -->
  <!-- <mat-tab label="LOGIN"> -->
   <!-- <div class="text-center mb-3">
              <p>Sign in with:</p>
              <button type="button" class="btn btn-link btn-floating mx-1">
                <i class="fab fa-facebook-f"></i>
              </button>

              <button type="button" class="btn btn-link btn-floating mx-1">
                <i class="fab fa-google"></i>
              </button>

              <button type="button" class="btn btn-link btn-floating mx-1">
                <i class="fab fa-twitter"></i>
              </button>

              <button type="button" class="btn btn-link btn-floating mx-1">
                <i class="fab fa-github"></i>
              </button>
            </div>
       -->
    <!-- <p class="text-center">or:</p> -->

  <!-- </mat-tab> -->
  <!-- <mat-tab label="SIGN UP">
    <form class="signUp" [formGroup]="signUpForm" style="margin-top: 20px;"> -->
      <!-- <div class="text-center mb-3">
                <p>Sign up with:</p>
                <button type="button" class="btn btn-link btn-floating mx-1">
                  <i class="fab fa-facebook-f"></i>
                </button>

                <button type="button" class="btn btn-link btn-floating mx-1">
                  <i class="fab fa-google"></i>
                </button>

                <button type="button" class="btn btn-link btn-floating mx-1">
                  <i class="fab fa-twitter"></i>
                </button>

                <button type="button" class="btn btn-link btn-floating mx-1">
                  <i class="fab fa-github"></i>
                </button>
              </div>

              <p class="text-center">or:</p>
         -->
      <!-- Firstname input -->
      <!-- <div class="form-outline mb-4 hasValue">
        <input type="text" id="firstName" class="form-control" formControlName="fname" required />
        <label class="form-label" for="firstName">First Name</label>
      </div> -->

      <!-- Lastname input -->
      <!-- <div class="form-outline mb-4">
        <input type="text" id="lastName" class="form-control" formControlName="lname" required />
        <label class="form-label" for="lastName">Last Name</label>
      </div>

      <div class="form-outline mb-4">
        <input type="text" id="mobileNumber" class="form-control" formControlName="mno" required />
        <label class="form-label" for="mobileNumber">Mobile Number</label>
      </div> -->

      <!-- Email input -->
      <!-- <div class="form-outline mb-4">
        <input type="email" id="registerEmail" class="form-control" formControlName="email" required />
        <label class="form-label" for="registerEmail">Email</label>
      </div> -->

      <!-- Password input -->
      <!-- <div class="form-outline mb-4">
        <input type="password" id="registerPassword" class="form-control" formControlName="password" required />
        <label class="form-label" for="registerPassword">Password</label>
      </div> -->

      <!-- Repeat Password input -->
      <!-- <div class="form-outline mb-4">
        <input type="password" id="registerRepeatPassword" class="form-control" formControlName="rPassword"
          required />
        <label class="form-label" for="registerRepeatPassword">Repeat password</label>
      </div> -->

      <!-- Checkbox -->
      <!-- <div class="form-check d-flex justify-content-center mb-4">
                <input class="form-check-input me-2" type="checkbox" value="" id="registerCheck" checked
                  aria-describedby="registerCheckHelpText" />
                <label class="form-check-label" for="registerCheck">
                  I have read and agree to the terms
                </label>
              </div>
         -->
      <!-- Submit button -->
      <!-- <button type="submit" class="btn btn-primary btn-block mb-3" (click)="signUpNewUser()">Sign Up</button>
    </form>
  </mat-tab>
</mat-tab-group> -->
