import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class CourseFormCaptureService {

  constructor(private commonService: CommonService) {
    this.onLoad()
  }

  onLoad(){
   this.commonService.logging("-- Course Form Loaded --")
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Course')
  }

  onFieldChange(tableId : string, formId : string,formData: any){
    this.commonService.logging('On field Change for Course')
    this.generateCourseName(formData)
  }

  onSubgridRefresh(tableId : string, formId : string){
    this.commonService.logging('On Subgrid Refresh for Courses')
    return false
  }

  generateCourseName(formData: any){
    let name = ""
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id){
            if(comp.id.includes("eng_agegroup")){
              name += comp.selectedOptionLabel.key != '--select--' ? comp.selectedOptionLabel.key+" - " : ''
            }
            if(comp.id.includes("eng_proficiency")){
              name += comp.selectedOptionLabel.key != '--select--' ? comp.selectedOptionLabel.key+" - " : ''
            }
            if(comp.id.includes("eng_journey")){
              name += comp.selectedOptionLabel.key != '--select--' ? comp.selectedOptionLabel.key+" - " : ''
            }
            if(comp.id.includes("eng_duration")){
              name += comp.selectedOptionLabel.key != '--select--' ? comp.selectedOptionLabel.key : ''
            }
          }
        }
      }
    }
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id && comp.id == "eng_name"){
            comp.value = name
          }
        }
      }
    }
  }

}
