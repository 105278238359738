import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input()
  quick !: boolean 

  @Input()
  tableLogicalName !: string

  @Input()
  rowId !: string

  @Input()
  theme  !: any

  @Input()
  ownerName !: string

  @Input()
  ownerId !: string
 
  notesList !: any
  header_image_name_clr !: string
  timeline_icon_color!: string
  timeline_logo_color!: string
  searchText  !: string
  searchIcon !: string
  createNotes = false
  notesTitle : string | undefined
  notesDesp : string | undefined
  buttonColor !: string
  ownerInitials !: string
  noteEdit !: any
  showLoader = false
  action = 'Add Note'
  editDesp = 'Create a note'
  loaderIcon !: string

  constructor(
    private tableOperationService : TableOperationService,
    private toastr : ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if(this.theme){
      this.header_image_name_clr = this.theme["form-section_header_back_clr"]
      this.timeline_icon_color = this.theme['timeline_Icon_clr']
      this.timeline_logo_color = this.theme['timeline_logo_clr']
      this.searchIcon = this.theme["form-lookup_field_icon"]
      this.buttonColor = this.theme["form-section_header_back_clr"]
      this.loaderIcon = this.theme['progress-bar_icon']
    }
    let str = this.ownerName.split(" ")
    this.ownerInitials = ""
    for(let i=0;i<str.length;i++){
      this.ownerInitials += str[i].charAt(0)
    }
    // this.notesList = [
    //   {
    //     initials : 'YJ',
    //     ownerName : 'Yakshi Jain',
    //     header : 'Notes',
    //     description : 'some content',
    //     time : '08/04/2022 11:52PM'
    //   },
    //   {
    //     initials : 'YJ',
    //     ownerName : 'Yakshi Jain',
    //     header : 'Notes',
    //     description : 'some content',
    //     time : '08/04/2022 11:52PM'
    //   },
    //   {
    //     initials : 'NJ',
    //     ownerName : 'Nikita Jain',
    //     header : 'Notes',
    //     description : 'some content',
    //     time : '08/04/2022 11:52PM'
    //   },
    // ]
    this.getNotesList()
    
  }

  getNotesList(){
    this.notesList = []
    this.tableOperationService.getNotes(this.commonService.getAuthToken(),this.rowId,this.tableLogicalName).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        for(let i of res.value){
          this.notesList.push({
            initials : this.ownerInitials,
            ownerName : this.ownerName,
            header : i.cre57_title,
            description : i.cre57_description,
            notesId: i.cre57_at_notesid,
            editMode: false,
            time : i['createdon@OData.Community.Display.V1.FormattedValue']
          })
        }
      }
    })
  }

  showCreateNote(){
    this.notesTitle = undefined
    this.notesDesp = undefined
    this.action = 'Add Note'
    this.editDesp = 'Create a note'
    this.createNotes = true
    if(this.noteEdit){
      this.closeEditNote(this.noteEdit)
    }
  }

  closeCreateNote(){
    this.createNotes = false
  }

  closeEditNote(note : any){
    for(let i of this.notesList){
      if(i.header == note.header){
        i.editMode = false
      }
    }
  }

  openDialog(note: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.theme["form-section_header_back_clr"]
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.callDeletEndpoint(note)
      }
    });
  }

  onAddorUpdateNote(){
    // let date = new Date()
    // var currentDate = moment(date).format('DD/MM/YYYY');
    // var time = date.getHours() + ":" + date.getMinutes()
    this.showLoader = true
    let payload = {
      "cre57_title": this.notesTitle,
      "cre57_description": this.notesDesp,
      "cre57_tablelogicianname": this.tableLogicalName,
      "cre57_recordid": this.rowId
    }
    if(this.notesDesp && this.notesDesp!==''){
      if(this.action == 'Add Note'){
        this.tableOperationService.createTableRecords(this.commonService.getAuthToken(),'cre57_at_noteses',payload).subscribe((res)=>{
          if(res && res.status == 'True'){
            this.toastr.success('Note created successfully')
            this.getNotesList()
            this.closeCreateNote()
          }
          else{
            this.toastr.error('Failed to create a note')
          }
          this.notesTitle = undefined
          this.notesDesp = undefined
          this.showLoader = false
        })
      }
      else if(this.action == 'Update Note'){
        this.tableOperationService.updateTableRecords(this.noteEdit.notesId,this.commonService.getAuthToken(),'cre57_at_noteses',payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Note updated successfully")
            this.getNotesList()
            this.closeEditNote(this.noteEdit)
          }
          else{
            this.toastr.error("Failed to update note")
          }
          this.showLoader = false
        })
      }
    
    }
  }

  onDeleteNote(note : any){
    this.openDialog(note)
  }

  callDeletEndpoint(note : any){
    this.showLoader = true
    this.tableOperationService.deleteTableRecord(note.notesId,this.commonService.getAuthToken(),'cre57_at_noteses').subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
        this.toastr.success("Note deleted successfully")
        this.getNotesList()
      }
      else{
        this.toastr.error("Failed to delete note")
      }
      this.showLoader = false
    })
  }

  onEditNote(note : any){
    this.closeCreateNote()
    this.notesDesp = note.description
    this.notesTitle = note.header
    this.action = 'Update Note'
    this.editDesp = 'Update a note'
    this.noteEdit = note 
    for(let i of this.notesList){
      if(i.header == note.header){
        i.editMode = true
      }
    }
  }

}
