import { AfterViewInit, Component, OnInit, ViewChild,Input,Output,OnChanges, EventEmitter, HostListener, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-angular-table',
  templateUrl: './angular-table.component.html',
  styleUrls: ['./angular-table.component.scss']
})
export class AngularTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  showSearch = true

  @Input()
  displayedColumnForMobilesPhones !: any

  @Input()
  initialDisplayedColumns !: any;

  @Input()
  rowData !: any

  @Input()
  theme !: any

  @Input()
  viewList  !: any

  @Input()
  selectedView !: any

  @Input()
  subgridTable = false

  @Input()
  sideNav = false

  @Input()
  tableAllDataFetched = false

  @Input()
  checkBoxNeeded = true

  searchIcon !: string
  iconBackgroundColor!: string
  iconColor!: string

  @Output()
  emitSelectedRows = new EventEmitter<any>()

  @Output()
  emitSelectedView = new EventEmitter<any>()

  @Output()
  emitCellClicked = new EventEmitter<any>()

  @Output()
  emitTableClick = new EventEmitter<any>()

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

  if(event.target.innerWidth < 600) {
    this.columnHeader = this.displayedColumnForMobilesPhones;
  }
  else if(event.target.innerWidth >= 600) {
    this.columnHeader = this.initialDisplayedColumns;
  }
  this.displayedColumns = ['select']
  for(const i of this.columnHeader){
    this.displayedColumns.push(i.id)
  }
}

  columnHeader !: any
  displayedColumns: string[] = [];
  dataSource: any = []
  selection !: any
  selectedRows : any= []

  table_header_back_clr !: string
  table_header_text_clr !: string
  table_row_even_back_clr !: string
  table_row_odd_back_clr !: string
  table_row_text_clr !: string
  view_dropdown_text_clr !: string

  lookupColumns : string[] = []

  @ViewChild(MatSort) sort !: MatSort;
  @ViewChild(MatPaginator) paginator !: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer,private cdr: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.rowData && changes.rowData.currentValue){
      this.dataSource = new MatTableDataSource<any>(changes.rowData.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if(changes && changes.tableAllDataFetched){
      this.tableAllDataFetched  = changes.tableAllDataFetched.currentValue
      this.columnandLookUpSet()
      this.setLookUpCellCss()
    }
    if(changes && changes.sideNav){
      this.sideNav = changes.sideNav.currentValue
      this.resetTableWidth()
    }
  }

  ngOnInit(): void {
    this.columnandLookUpSet()
    this.dataSource = new MatTableDataSource<any>(this.rowData);
    this.selection = new SelectionModel<any>(true, []);

    if(this.theme){
      this.table_header_back_clr = this.theme["table-header_clr"]
      this.table_header_text_clr = this.theme["table-header_text_clr"]
      this.table_row_even_back_clr =  this.theme["table-row_even_clr"]
      this.table_row_odd_back_clr = this.theme["table-row_odd_clr"]
      this.table_row_text_clr = this.theme["table-row_text_clr"]
      this.view_dropdown_text_clr = this.theme["app-home_views_text_clr"]
      this.searchIcon = this.theme["table-row_search_icon"]
      this.iconBackgroundColor = this.theme["round_Icon_back_clr"]
      this.iconColor = this.theme["main_header_text_clr"]
      let elem = document.getElementsByClassName("divImage2")
      if(elem[0]){
        (<HTMLElement>elem[0]).style.background = this.searchIcon + " no-repeat fixed";
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resetTableWidth()
    if(this.tableAllDataFetched){
      this.setLookUpCellCss()
    }
  }

  columnandLookUpSet(){
    this.lookupColumns = []
    if(window.innerWidth < 600) {
      this.columnHeader = this.displayedColumnForMobilesPhones;
    }
    else if(window.innerWidth >= 600) {
      this.columnHeader = this.initialDisplayedColumns;
    }
    this.displayedColumns = ['select']
    for(const i of this.columnHeader){
      this.displayedColumns.push(i.id)
      if(i.type == 'lookup'){
        let newId = JSON.parse(JSON.stringify(i.id))
        newId = newId.replaceAll('@','-')
        newId = newId.replaceAll('.','-')
        this.lookupColumns.push("mat-column-"+newId)
      }
    }
  }

  resetTableWidth(){
    let elem = document.getElementsByClassName('mat-elevation-z8')
    if(elem){
      if(this.subgridTable){
        for(let i=0;i<elem.length;i++){
          (<HTMLElement>elem[i]).style.width = '100%'
        }
      }
      else{
        for(let i=0;i<elem.length;i++){
          (<HTMLElement>elem[i]).style.width = this.sideNav ? '100%' : 'calc(100% - 210px)'
        }
      }
    }
  }

  setLookUpCellCss(){
    let elem = document.getElementsByClassName('mat-cell')
    if(elem){
      for(let i=0;i<elem.length;i++){
        for(let l of this.lookupColumns){
          if(elem[i].classList.contains(l)){
            elem[i].classList.remove('lookUpStyles')
            if(!elem[i].classList.contains('lookUpStyles')){
              elem[i].classList.add('lookUpStyles')
              this.cdr.detectChanges()
            }
          }
        }
      }
    }
    let elems  = document.getElementsByClassName('mat-checkbox-frame')
    if(elems && elems.length>0){
      (<HTMLElement>elems[0]).style.borderColor = this.table_header_text_clr;
      for(let i=1;i<elems.length;i++){
        // if(this.rowData[i] && this.rowData[i].checkBoxIconType == 'initial'){
          (<HTMLElement>elems[i]).style.backgroundColor = this.iconBackgroundColor;
          (<HTMLElement>elems[i]).style.color = this.iconColor;
          (<HTMLElement>elems[i]).style.borderColor = this.iconBackgroundColor;
          this.cdr.detectChanges()
        // }
      }
    }

    const tds = document.getElementsByClassName('element-row');
    for (let i = 0; i < tds.length; i++) {
      (<HTMLElement>tds[i]).style.setProperty('--row-background-color', this.theme["table-row_hover_clr"]);
    }
  }

  setCheckBoxBackgroundColor(){
    let elems  = document.getElementsByClassName('mat-checkbox-background')
    if(elems && elems.length>0){
      (<HTMLElement>elems[0]).style.backgroundColor = this.selection._selection.size>0 ? this.iconBackgroundColor : 'transparent'
      for(let i=1;i<elems.length;i++){
          (<HTMLElement>elems[i]).style.backgroundColor = this.iconBackgroundColor;
          this.cdr.detectChanges()
      }
    }
    const tds = document.getElementsByClassName('element-row');
    for (let i = 0; i < tds.length; i++) {
      (<HTMLElement>tds[i]).style.setProperty('--row-background-color', this.theme["table-row_hover_clr"]);
      this.cdr.detectChanges()
    }
  }

  getTableRecord(row: any){
    this.emitTableClick.emit(row)
  }




    /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: any) {
      // This example uses English messages. If your application supports
      // multiple language, you would internationalize these strings.
      // Furthermore, you can customize the message to add additional
      // details about the values being sorted.
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.setLookUpCellCss()
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row  :any) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected() ?
      this.selection.deselect(...this.getPageData()) :
      this.selection.select(...this.getPageData());

    this.setCheckBoxBackgroundColor()

    this.emitSelectedRows.emit(this.selection)
  }

  // checkboxLabel(row : any): string {
  //   if (!row) {
  //     return `${this.isEntirePageSelected() ? 'select' : 'deselect'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  // }

   /** Whether the number of selected elements matches the total number of rows. */
  //  isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.data.forEach((row : any)=> this.selection.select(row));

  //   this.emitSelectedRows.emit(this.selection)
  // }

  checkBoxClicked(e : any,row: any, selection :any){
    this.selection.toggle(row)
    this.emitSelectedRows.emit(this.selection)
    this.setCheckBoxBackgroundColor()
  }

  getTableForSelectedView(event : any){
    this.emitSelectedView.emit(event)
  }

  onMatCellClick(elem : any,data : any,column: any){
    this.emitCellClicked.emit({row : elem,elem : data,col : column})
  } 

}


