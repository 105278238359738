import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, ReplaySubject } from 'rxjs';
import { map} from 'rxjs/operators';
import { CREATE_USER, SEND_SMS, SUBSCRIPTION, USERBYUSERNAME, USER_PROFILE} from '../app.global';
import { User } from '../models/User';
import { SessionService } from './session-service/session.service';
import { EnvService } from './env.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFqYWluQGFtYXlyYXRlY2guY29tIiwibmJmIjoxNjUyMTYzNTM5LCJleHAiOjE2ODM2OTk1MzksImlhdCI6MTY1MjE2MzUzOX0.iFjVbuSYAybzp_kp5aBe2FGidtOfNLtV630p9kYmjYk"
  }),
  withCredential: true
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public currentUserSource = new ReplaySubject<User|null>(1)
  currentUser$ = this.currentUserSource.asObservable()

  public currentTokenSource = new ReplaySubject<string|null>(1)
  currentToken$ = this.currentTokenSource.asObservable()

  public currentSessionSource = new ReplaySubject<string|null>(1)
  currentSession$ = this.currentSessionSource.asObservable()

  public authToken !: string
  constructor(private http: HttpClient,private sessionService: SessionService,private envService: EnvService) { }

  setCurrentUser(user : User){
    this.currentUserSource.next(user)
  }

  setCurrentToken(token: string){
    this.currentTokenSource.next(token)
  }

  getAuthToken(){
    return JSON.parse(localStorage.getItem('token') as string)
  }

  getCurrentToken(){
    return this.currentToken$
  }

  removeToken(){
    localStorage.removeItem('token')
    this.currentTokenSource.next(null)
  }

  logoutUser(){
    localStorage.removeItem('user')
    localStorage.removeItem('application')
    localStorage.removeItem('userProfile')
    localStorage.removeItem('applicationSettings')
    localStorage.removeItem('primaryContactInfo')
    localStorage.removeItem('primaryUserInfo')
    localStorage.removeItem('tncAgree')
    localStorage.removeItem('accessConfig')
    window.localStorage.clear()
    this.currentUserSource.next(null)
  }

  setCurrentSession(session: string){
    this.currentSessionSource.next(session)
  }

  getCurrentSession(){
    return this.currentSession$
  }

  removeSession(){
    localStorage.removeItem('session')
    this.currentSessionSource.next(null)
  }

  formatDate(date: any,session: boolean){
    date = date.toString().split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let year = date[5];
    let month = `0${(monthsList.indexOf(date[1]) + 1)}`.slice(-2);
    let day = date[2];

    if(session){
      year = date[3]
      let time = date[4]
      return `${month}/${day}/${year} ${time}`
    }
    else{
      return `${year}-${month}-${day}`;
    }
  }

  createUser(userDetail : any): Observable<any>{
    let endpointUrl = this.envService.apiUrl+CREATE_USER
    return this.http.post(endpointUrl, userDetail, httpOptions).pipe(
      map((res) => res as any)
    )
  }

  sendSMS(otpData : any): Observable<any>{
    let endpointUrl = this.envService.apiUrl+SEND_SMS
    return this.http.post(endpointUrl, otpData, httpOptions).pipe(
      map((res) => res as any)
    )
  }

  validateUserSubscription(uid:string,token: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+SUBSCRIPTION+"/"+uid+SUBSCRIPTION
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  validateUserByUserName(userName:string,token: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+USERBYUSERNAME+"/"+userName+USERBYUSERNAME
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  closeSession(sessionId :  string, authToken : string,poll?:boolean){
    const date = this.formatDate(new Date(),true)
    this.sessionService.endSession(sessionId,date,authToken).subscribe((res)=>{
      if(poll){
        this.removeToken()
        this.logoutUser()
        let url = localStorage.getItem('baseurl')
        if(url){
          window.location.href = JSON.parse(url)
        }
      }
    })
  }

  sortDataBySequence(data : any){
    return data.sort(this.compare)
  }

  getDefaultFormFirst(forms : any){
    let formData = []
    for(let i of forms){
      if(i.DefaultForm){
        formData.push(i)
      }
    }
    if(formData && formData.length>0 && formData[0].Type == 'Customized'){
      formData = []
      for(let i of forms){
        if(i.Type == 'Standard'){
          i.DefaultForm = true
          formData.push(i)
        }
      }
    }
    return formData
  }

  getLookupFormFirst(forms : any){
    let formData = []
    for(let i of forms){
      if(i.LookupForm){
        formData.push(i)
      }
    }
    if(formData && formData.length>0 && formData[0].Type == 'Customized'){
      formData = []
      for(let i of forms){
        if(i.Type == 'Standard'){
          i.LookupForm = true
          formData.push(i)
        }
      }
    }
    return formData
  }

  getViewFormFirst(forms : any){
    let formData = []
    for(let i of forms){
      if(i.ViewForm){
        formData.push(i)
      }
    }
    if(formData && formData.length>0 && formData[0].Type == 'Customized'){
      formData = []
      for(let i of forms){
        if(i.Type == 'Standard'){
          i.ViewForm = true
          formData.push(i)
        }
      }
    }
    return formData
  }

  checkQuickFormExist(forms :  any){
    for(let i of forms){
      if(i.Type == 'Quick Add'){
        return true
      }
    }
    return false
  }

  compare(a: any,b: any){
    return (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0)
  }

  getUserProfile(uid:string,token: string){
    let endpointUrl = this.envService.apiUrl+USER_PROFILE+"("+uid+")"
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    let payload = {
      id : uid
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  checkUserRoles(roles : any, tableData  : any){
    for(let tab of tableData){
      for(let role of roles.table_access){
        if(role.table == tab.Name){
          if(role.access['read']){
            switch(role.access['read']){
              case 'None':
                tab.Showonsitemap = false
                tab.readNotAllowed = true
                break;
              case 'User':
                tab.filterUserRecords = true
                break;
            }
          }

          if(role.access['write'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['write']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'Save' || element.actionApi == 'SaveClose'){
                    element.hide = true
                  }
                })
                break;
                case 'User':
                  formRibbon.forEach((element: any) => {
                    if(element.actionApi == 'Save' || element.actionApi == 'SaveClose'){
                      element.hideNonUser = true
                    }
                  })
                  break;
            }
          }

          if(role.access['delete'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['delete']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'Delete'){
                    element.hide = true
                  }
                });
                (tab.tableRibbons).forEach((element: any) => {
                  if(element.actionApi == 'Delete'){
                    element.hide = true
                  }
                })
                break;
                case 'User':
                  formRibbon.forEach((element: any) => {
                    if(element.actionApi == 'Delete'){
                      element.hideNonUser = true
                    }
                  });
                  (tab.tableRibbons).forEach((element: any) => {
                    if(element.actionApi == 'Delete'){
                      element.hideNonUser = true
                    }
                  })
                  break;
            }
          }

          if(role.access['assign'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['assign']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'Assign'){
                    element.hide = true
                  }
                });
                (tab.tableRibbons).forEach((element: any) => {
                  if(element.actionApi == 'Assign'){
                    element.hide = true
                  }
                })
                break;
                case 'User':
                  formRibbon.forEach((element: any) => {
                    if(element.actionApi == 'Assign'){
                      element.hideNonUser = true
                    }
                  });
                  (tab.tableRibbons).forEach((element: any) => {
                    if(element.actionApi == 'Assign'){
                      element.hideNonUser = true
                    }
                  })
                  break;
            }
          }

          if(role.access['share'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['share']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'Share'){
                    element.hide = true
                  }
                });
                (tab.tableRibbons).forEach((element: any) => {
                  if(element.actionApi == 'Share'){
                    element.hide = true
                  }
                })
                break;
                case 'User':
                  formRibbon.forEach((element: any) => {
                    if(element.actionApi == 'Share'){
                      element.hideNonUser = true
                    }
                  });
                  (tab.tableRibbons).forEach((element: any) => {
                    if(element.actionApi == 'Share'){
                      element.hideNonUser = true
                    }
                  })
                  break;
            }
          }

          if(role.access['export'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['export']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'ExportCSV'){
                    element.hide = true
                  }
                });
                (tab.tableRibbons).forEach((element: any) => {
                  if(element.actionApi == 'ExportCSV'){
                    element.hide = true
                  }
                })
                break;
                case 'User':
                  formRibbon.forEach((element: any) => {
                    if(element.actionApi == 'ExportCSV'){
                      element.hideNonUser = true
                    }
                  });
                  (tab.tableRibbons).forEach((element: any) => {
                    if(element.actionApi == 'ExportCSV'){
                      element.hideNonUser = true
                    }
                  })
                  break;
            }
          }

          if(role.access['create'] && tab.forms.length>0){
            let formRibbon = tab.forms[0].formRibbon
            switch(role.access['create']){
              case 'None':
                formRibbon.forEach((element: any) => {
                  if(element.actionApi == 'New'){
                    element.hide = true
                  }
                });
                (tab.tableRibbons).forEach((element: any) => {
                  if(element.actionApi == 'New'){
                    element.hide = true
                  }
                })
                break;

            }
          }
        }
      }
    }
  }

  tableDisplayAuthorization(displayRule : any){
    let user_detail
    const user = localStorage.getItem('application')
    if(user){
      user_detail = JSON.parse(user)
    }
    let display
    let flag = false
    if(displayRule.values && displayRule.values.length>0){
      // for(const i of displayRule.values){
      //   if(i.security == "user" && i.value == (user_detail.cre57_email).toLowerCase()){
      //     flag = i.visible
      //   }
      //   if(i.security == "role" && i.value == user_detail.cre57_role){

      //   }
      // }
    }
  }

  logging(elem : any){
    // console.log(elem)
  }

  checkAccess(id:string,prop: string){
    let config = localStorage.getItem("accessConfig")
    if(config && config !== '' && config !== "undefined"){
      let accessConfig = JSON.parse(config.toString())
      for(let i in accessConfig){
        if(i == id){
          return accessConfig[i][prop]
        }
      }
      return true
    }
    else{
      return true
    }
  }
}
