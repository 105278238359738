import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss']
})
export class AppButtonComponent implements OnInit {

  @Input()
  visibleButton !: any

  @Input()
  dropdownButton !: any

  @Input()
  formButton !: any

  @Output()
  emitButtonClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  performFormEvent(b : any){
    this.emitButtonClick.emit(b)
  }

}
