import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { User } from '../../models/User';
import { CommonService } from '../../services/common.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { TermsAndConditionComponent } from '../terms-and-condition/terms-and-condition.component';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit {

  @Input()
  user : any

  @Output()
  emitLogout = new EventEmitter()

  homeIconColor = "#FFF"
  homeIcon = ''
  theme : {[key: string]: string} = {}
  userId !: string
  content !: string[]
  firstName : string = "User"
  userImage :  string = ""
  imageName !:  string
  constructor(
    private tableOperationService : TableOperationService,
    private commonService: CommonService,
    private router : Router,
    private toastr : ToastrService,
    private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.user.subscribe((val: User)=>{
      if(val){
        if(this.theme){
          let elem1 = <HTMLElement>document.getElementsByClassName("navbar")[0]
          if(elem1 && this.theme["main-header_clr"]){
            elem1.style.backgroundColor = this.theme["main-header_clr"]
            elem1.style.color = this.theme["main_header_text_clr"]
          }

          let elem2 = <HTMLElement>document.getElementsByClassName("display-drop")[0]
          if(elem2 && this.theme["main_header_text_clr"]){
            elem2.style.color = this.theme["main_header_text_clr"]
          }
        }
      }
    })
  }

  ngOnInit(): void {
    this.commonService.logging("Nav component initialized")
   this.user.subscribe((val: User)=>{
    if(val){

      let profile = JSON.parse(val.cre57_userprofile)
      if(profile){
        this.theme = JSON.parse(profile.business.Theme)
        if(this.theme && this.theme["main-home_icon_clr"]){
          this.homeIconColor = this.theme["main-home_icon_clr"]
        }
        if(profile.tnc){
          this.content = profile.tnc.Condtions.split('*')
        }
        this.homeIcon = profile.business.Icon
      }
      this.firstName = val["cre57_name"].split(" ")[0]

      let str = val["cre57_name"].split(" ")
      this.imageName = ""
      for(let i=0;i<str.length;i++){
        this.imageName += str[i].charAt(0)
      }

      this.userId = val.cre57_at_userid
      let gender
      if(val.cre57_gender == "647510001"){
        gender = "Female"
      }
      else if(val.cre57_gender == "647510000"){
        gender="Male"
      }
      else{
        gender="Other"
      }
      if(val.cre57_image){
        this.userImage = 'data:image/jpg;base64,' + val .cre57_image
      }
    }
   })
  }

  logout(){
    this.emitLogout.emit()
  }

  goBackToHome(){
    let navigateRoute = localStorage.getItem('baseurl')
    if(navigateRoute){
      this.router.navigate([JSON.parse(navigateRoute)])
    }
    else{
      this.router.navigate(['/'])
    }
  }
  resetPassword(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Reset',
        cancel: 'Cancel'
      },
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ResetPasswordComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((password: any) => {
      if(password){
        let payload = {
          "cre57_password": password
        }
        this.tableOperationService.updateTableRecords(this.userId,this.commonService.getAuthToken(),'cre57_at_users',payload).subscribe((res)=>{
          if(res && res.status && res.status == "True"){
            this.toastr.success("Password reset successfully")
          }
          else{
            this.toastr.error("Failed to reset password")
          }
        })
      }
    })
  }
  showTermsAndConditions(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%'
    dialogConfig.height = '83%'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        cancel: 'Close'
      },
      dataInfo: this.content,
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(TermsAndConditionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((status: any) => {
      this.commonService.logging(status)
    })
  }
  viewProfile(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '66%'
    dialogConfig.height = '83%'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        cancel: 'Close'
      },
      dataInfo: this.content,
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((status: any) => {
      this.commonService.logging(status)
    })
  }
}
