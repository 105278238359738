import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceFormCaptureService {

  constructor(private commonService: CommonService) {
  }

  onLoad(formData: any){
   this.commonService.logging("-- Attendance Form Loaded --")
   this.setMinCheckInDate(formData)
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Attendance')
  }

  onFieldChange(tableId : string, formId : string,formData: any,comp:any){
    this.commonService.logging('On field Change for Attendance')
  }

  onSubgridRefresh(formData: any, comp: any, headerData: any, rowData: any){
    this.commonService.logging('On Subgrid Refresh for Attendance')
  }


  setMinCheckInDate(formData: any){
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id && comp.id.includes("new_checkin")){
            comp.minValue = new Date().toISOString().split("T")[0]+"T00:00"
          }
        }
      }
    }
  }

}
