import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-details',
  templateUrl: './new-window.component.html',
  styleUrls: ['./new-window.component.scss']
})
export class NewWndowComponent implements OnInit {

  id !: number
  compName !: string
  comp !: any
  userId !: string
  businessId !: string
  userName !: string
  theme !: any
  allTables !: any
  token !: string
  formTemplate !: any
  render = false

  @Output()
  emitCloseForm = new EventEmitter<boolean>();

  constructor(private routeParams: ActivatedRoute,private commonService : CommonService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.routeParams.params.subscribe(params => {
      this.id = parseInt(params['count']);
      this.compName = params['compName']
    });

    this.token = JSON.parse(localStorage.getItem('token') as string)

    if(this.compName == 'lookup'){
      let elem = localStorage.getItem('lookupComp'+this.id)
      if(elem){
        this.comp = JSON.parse(elem)
      }
    }
    else{
      let elem = localStorage.getItem('comp'+this.id)
      if(elem){
        this.comp = JSON.parse(elem)
      }
    }

    const user = localStorage.getItem('user')
    if(user){
      this.userId = JSON.parse(user).cre57_at_userid
      this.userName = JSON.parse(user).cre57_name
      let profile = JSON.parse(user).cre57_userprofile
      this.businessId = JSON.parse(profile).business.Id
      this.theme = JSON.parse(JSON.parse(profile).business.Theme)
    }
    const app = localStorage.getItem('application')
    if(app){
      let application = JSON.parse(app)
      this.allTables =  this.commonService.sortDataBySequence(application.tables)
      for(const i of this.allTables){
        if(i.Guid == this.comp.tableid){
          this.formTemplate = i.FormTemplate
        }
      }
    }
    this.render= true
    this.commonService.logging(this.comp)
  }

  // closeTableForm(comp: any){
  //   this.emitCloseForm.emit(comp)
  // }

}
