<div class="promotion-div" *ngIf="!showLoader">
  <div class="promo-header center-align">
    <img *ngIf="homeIcon !==''" class="homeIcon-image" [src]="homeIcon">
    <i *ngIf="homeIcon == ''" class="fa fa-home home-icon" [ngStyle]="{
      color : homeIconColor ? homeIconColor : '#FFF'
    }"></i>
    <span class="headerName">{{profile.business.Name}}</span>
  </div>

  <mat-tab-group (selectedTabChange)="callTabChange($event)">
    <mat-tab label="Create Job">
      <div style="display: flex;margin-top: 10px; position: relative;width:100%" class="row" *ngIf="promoData; else error">
      <div class="row limit">
        <span class="col-md-3">Per day Limit: {{promoData.bu.dailyLimit}}</span>
        <span class="col-md-3">Used: {{promoData.bu.dailyUsedLimit}}</span>
        <span class="col-md-3">Pending: {{promoData.bu.dailyRemainingLimit}}</span>
        <span class="col-md-3">
          <span>Selected Count: {{selectedCount}}</span>
          <span class="selectAllCheckbox"><input [checked]="allChecked" style="margin-top: 3px;
            margin-right: 4px;" type="checkbox" (click)="callSelectAll($event)">
            <span style="position:absolute">Select All</span>
          </span>
        </span>
      </div>  
      <div class="col-md-8 promo-carousel center-align">
        <mdb-carousel [isControls]="true" [keyboard]="true" [allowSwipe]="true" [interval]="0" [animation]="'slide'" (activeSlideChange)="activeSlideChange($event)">
          <ng-container *ngFor="let banner of promoData.Banner">
            <mdb-carousel-item *ngIf="banner.url && banner.url !== ''">
              <img
                [src]= banner.url
                class="d-block w-80"
                alt="..."
              />
            </mdb-carousel-item>
          </ng-container>
        </mdb-carousel>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="search">
              <div class="form-group">
                <input type="text" class="form-control search-field" placeholder="Search Here" [(ngModel)]="searchText">
              </div>
            </div>
            <div class="contact-content">
              <ng-container *ngFor="let customer of contactList | search:searchText">
                <label style="display:flex">
                <input type="checkbox" [checked]="customer.checked" class="option-input checkbox" (change)="checkboxClicked($event,customer)"
                  [ngStyle]="{
                    'background-color': customer.checked ? buttonColor  : ''
                  }"
                />
                <div class="contact-detail">
                  <div><span class="bold-heading">Name:  </span><span class="detail-content">{{customer.name}}</span></div>
                  <div *ngIf="customer.mobile"><span class="bold-heading">Mobile:  </span><span class="detail-content">{{customer.mobile}}</span></div>
                  <div *ngIf="customer.email"><span class="bold-heading">Email:  </span><span class="detail-content">{{customer.email}}</span></div>
                </div>
              </label>
              </ng-container>
            </div>
           
        </div>
      </div>
      </div>
      <div class="row bottomDropdowns">
        <div class="col-md-8">
          <select [(ngModel)] = 'selectedMessage' class="form-control input-type dropdown-input" style=" width: 92%;">
            <option *ngFor="let option of finalDropDownMessage" [ngValue]="option">{{option}}</option>
        </select>
         
        </div>
        <div class="col-md-4" style="display:inline-flex">
          <select [(ngModel)]="defaultSender" class="form-control input-type dropdown-input" (change)="onRequestSenderDropdownValChange($event)"
          [compareWith]="compareFn"
          >
            <option *ngFor="let option of requestSender" [ngValue]="option">{{option.Text}}</option>
          </select>

          <button  class="mat-raised-button mat-primary createJob" [disabled]="defaultSender.Value == 0 || this.selectedCount == 0"
        [ngStyle]="{
          'background-color' : buttonColor ? buttonColor : '#673ab7',
          'opacity' : defaultSender.Value == 0 || this.selectedCount == 0 ? '0.5' : '1'
        }" (click)="openDialog()">Create Job</button>
        </div>
      </div>
    </div>
  <ng-template #error>
    <div class="headerName mt-160-center" *ngIf="errorMessage !== ''">{{errorMessage}}</div>
  </ng-template>
    </mat-tab>
    <mat-tab label="Existing Jobs">
      <div class="main-container" style="width:99%" *ngIf="(viewDataAvailable && !tableRowClicked) && showJobs; else showMessageDashboard"
        [ngStyle]="{
          'background-color' : page_background_clr ? page_background_clr : '#FFF'
        }"
        >
  
        <div class="table-top">
          <div class="dropdown-content" *ngIf="viewList && viewList.length>0">
            <select class="dropdown-toggle custom-select" (change)="getTableForSelectedView($event)" [ngStyle]="{
              'color' : view_dropdown_text_clr ? view_dropdown_text_clr : '#000'
            }" [(ngModel)]="viewChoose.Name">
              <option class="custom-option" *ngFor="let elem of viewList"  value="{{elem.Name}}">{{elem.Name}}</option>
            </select>

            <span class="refreshButton"  (click)="performSelectedTask()"><img *ngIf="showButtons.Icon!=''" class="button-icon" [src]="showButtons.Icon">
              <span class="button-text"  [ngStyle]="{
                 'color' : button_text_clr ? button_text_clr : '#000'
              }">{{showButtons.Label}}</span>
            </span>
            </div>

        </div>
  
          <div *ngIf="tableDataAvailable; else showMessageDashboard">
            <app-angular-table *ngIf="rowData"
              [initialDisplayedColumns] = "layout"
              [displayedColumnForMobilesPhones] = "mobileLayout"
              [rowData]="rowData"
              [theme]="theme"
              [viewList]="viewList"
              [sideNav]="true"
              [selectedView]="selectedView"
              [tableAllDataFetched] = 'tableAllDataFetched'
              [checkBoxNeeded] = 'false'
              (emitSelectedView) ="getTableForSelectedView($event)"
            ></app-angular-table>
          </div>
  
      </div>
    </mat-tab>
  </mat-tab-group>
  
</div>

<ng-template #showMessageDashboard>
  <!-- <app-loader *ngIf="showLoader" [marginTop]="'200px'"></app-loader> -->
  <div class="headerName mt-160" *ngIf="errorMessageTable && errorMessageTable !=''">
    {{this.errorMessageTable}}
  </div>
</ng-template>

<app-loader *ngIf="showLoader" [icon]='loaderIcon' [width]="'100px'"></app-loader>