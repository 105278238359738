import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { END_SESSION, GET_ACTIVE_SESSION, GET_ACTIVE_USER_SESSION, START_SESSION} from '../../app.global';
import { EnvService } from '../env.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFqYWluQGFtYXlyYXRlY2guY29tIiwibmJmIjoxNjUyMTYzNTM5LCJleHAiOjE2ODM2OTk1MzksImlhdCI6MTY1MjE2MzUzOX0.iFjVbuSYAybzp_kp5aBe2FGidtOfNLtV630p9kYmjYk"
  }),
  withCredential: true
}
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient,private envService: EnvService) { }

  startSession(userId : string,date: string,location: string,token: string){
    let endpointUrl = this.envService.apiUrl+START_SESSION
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    let payload = {
        "cre57_User@odata.bind": "/cre57_at_users("+userId+")",
        "cre57_location": location,
        "cre57_star": date,
        "cre57_tokent":token
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  endSession(sessionId : string,date: string,token: string){
    let endpointUrl = this.envService.apiUrl+END_SESSION
    endpointUrl += "("+sessionId+")"
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    let payload = {
        "cre57_end": date,
        "statuscode":1
    }
    return this.http.patch(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  getUserActiveSessions(userId : string, token : string){
    let endpointUrl = this.envService.apiUrl+GET_ACTIVE_USER_SESSION+"/"+userId+GET_ACTIVE_USER_SESSION
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    return this.http.get(endpointUrl,options).pipe(map(res=>res as any))
  }

  getActiveSessions(sessionId : string, token : string){
    let endpointUrl = this.envService.apiUrl+GET_ACTIVE_SESSION+"/"+sessionId+GET_ACTIVE_SESSION
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    return this.http.get(endpointUrl,options).pipe(map(res=>res as any))
  }

}
