import { compileComponentFromMetadata } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class StudentFormCaptureService {

  constructor(private commonService: CommonService) {

   }

   onLoad(formData : any){
    this.commonService.logging("-- Student Form Loaded --")
    this.generateFullName(formData)
    this.calculateAge(formData)

  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Student')
  }
  onFieldChange(tableId : string, formId : string,formData: any){
    this.commonService.logging('On field Change for Student')
    this.generateFullName(formData)
    this.calculateAge(formData)
  }
  onSubgridRefresh(tableId : string, formId : string,subgrid : any,formData: any,headerData: any){
    this.commonService.logging('On Subgrid Refresh for Student')
    this.getActiveStudent(subgrid,formData,headerData)
    return false
  }

  getActiveStudent(subgrid: any,formData: any,headerData : any){
    if(subgrid.tableData){
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.calculated && comp.id == "eng_activestudent"){
              comp.value = subgrid.tableData.length
            }
          }
        }
      }
      for(let i of headerData){
        if(i.field == 'eng_activestudent'){
          i.value = subgrid.tableData.length
        }
      }
    }
  }

  generateFullName(formData: any){
    let fname = '', lname = ''
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id == "eng_firstname"){
            fname = comp.value ? comp.value : ''
          }
          if(comp.id == "eng_lastname"){
            lname = comp.value ? comp.value : ''
          }
        }
      }
    }
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id == "eng_name"){
            comp.value = fname + " " + lname
          }
        }
      }
    }
  }

  calculateAge(formData: any) {
    let errorExist = false
    let dob = new Date()
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id && comp.id.includes("eng_dateofbirth")){
            dob = comp.value ? new Date(comp.value) : new Date()
            comp.error = false
            comp.errorMessage = ""
            let UserDate = dob;
            let ToDate = new Date();

            if (new Date(UserDate).getTime() > ToDate.getTime()) {
              comp.error = true
              comp.errorMessage = "Entered date should be less than today's date"
              errorExist = true
            }
          }
        }
      }
    }

    // var diff_ms = Date.now() - dob.getTime();
    // var age_dt = new Date(diff_ms);
    if(!errorExist){
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id && comp.id.includes("eng_age")){
              // comp.value = Math.abs(age_dt.getUTCFullYear() - 1970);

              var today = new Date();
              var birthDate = new Date(dob);
              var age = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                  age--;
              }
              comp.value = age;
            }
          }
        }
      }
    }
}
}
