import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { AUTH_TOKEN, GET_USER_BY_USERNAME_PASSOWRD, SETTINGS, USER_APPS, USER_ROLES} from '../../app.global';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFqYWluQGFtYXlyYXRlY2guY29tIiwibmJmIjoxNjUyMTYzNTM5LCJleHAiOjE2ODM2OTk1MzksImlhdCI6MTY1MjE2MzUzOX0.iFjVbuSYAybzp_kp5aBe2FGidtOfNLtV630p9kYmjYk"
  }),
  withCredential: true
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient,private envService: EnvService) { }

  getAuthToken():Observable<any>{
    let endpointUrl = this.envService.apiUrl+AUTH_TOKEN
    let requestBody = {
      "username":"ajain@amayratech.com",
      "password":"Yj&8feb1992"
    }
    return this.http.post(endpointUrl, requestBody).pipe(map(res=>res as any));
  }

  getApplicationSetting(token : string,url: string):Observable<any>{
    let endpointUrl = this.envService.apiUrl+SETTINGS
    endpointUrl+=url+SETTINGS
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  getUser(username: string, password: string, token: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+GET_USER_BY_USERNAME_PASSOWRD
    endpointUrl += "/"+username+"/"+password
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  getBusinessContacts(id: string,api:string,token: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+"/"+id+"/"+api+"/na/na"
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }
  
  isTabletOrDesktop(): boolean {
    const screenWidth = window.innerWidth;
    return screenWidth >= 768; // Assuming tablet size starts at 768 pixels
  }

  getDataForPromoApp(action: string, params: any,token: string){
    let endpointUrl = this.envService.apiUrl+"/a-"+action
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.post(endpointUrl,params,options).pipe(map(res=>res as any));
  
  }

  // getUserApplications(uid:string,token: string): Observable<any>{
  //   let endpointUrl = this.envService.apiUrl+USER_APPS+"/"+uid+USER_APPS
  //   var options = {
  //     'method': 'GET',
  //     'url': endpointUrl,
  //     'headers': {
  //       'Authorization': 'Bearer '+token
  //     }
  //   }
  //   return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  // }

  // getUserRoles(uid:string,token: string): Observable<any>{
  //   let endpointUrl = this.envService.apiUrl+USER_ROLES+"/"+uid+USER_ROLES
  //   var options = {
  //     'method': 'GET',
  //     'url': endpointUrl,
  //     'headers': {
  //       'Authorization': 'Bearer '+token
  //     }
  //   }
  //   return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  // }

}
