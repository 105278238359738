import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  @Input()
  tableData : any

  @Input()
  dashboardData : any

  @Input()
  theme !: any

  @Output()
  emitSelectedSideNav = new EventEmitter<any>()

  @Output()
  emitsideNavState = new EventEmitter<any>()

  isDesktop = false

  constructor(private cdr : ChangeDetectorRef,private applicationService: ApplicationService,public commonService: CommonService) { }
  ngAfterViewInit(): void {
    if(this.theme){
      const tds = document.getElementsByClassName('all-lists');
      for (let i = 0; i < tds.length; i++) {
        (<HTMLElement>tds[i]).style.setProperty('--list-background-color', this.theme["nav-hover_clr"]);
      }
    }
  }

  displayContent : any
  open = true
  iconImage=""

  nav_header_clr !: any
  nav_text_clr !: any
  nav_background_clr !: any
  iconColor !: any
  menuColor !: any
  ngOnInit(): void {
    this.isDesktop = this.applicationService.isTabletOrDesktop()
    if(this.isDesktop){
      this.openSideNav()
    }
    else{
      this.closeSideNav()
    }
    this.displayContent =  [
      {
        name: "Dashboard",
        open: true,
        list: this.dashboardData,
      },
      {
        name: "Table",
        open: true,
        list: this.tableData
      }
    ]
    if(this.theme){
      this.nav_header_clr = this.theme["nav-headings_clr"]
      this.nav_text_clr = this.theme["nav-text_clr"]
      this.nav_background_clr = this.theme["nav-background_clr"]
      this.iconColor = this.theme['nav-expend_close_icon_clr']
      this.menuColor = this.theme['main-header_clr']
    }

    let storage = localStorage.getItem('applicationSettings')
    if(storage){
      let setting = JSON.parse(storage)
      this.iconImage = setting.default_table_icon
    }
  }

  openSideNav(){
    const elem = document.getElementById("sidebar")
    if(elem){
      // elem.classList.toggle("active");
      elem.style.width = "210px";
      this.emitsideNavState.emit(true)
      this.open = true
    }
    if(this.theme){
      const tds = document.getElementsByClassName('all-lists');
      for (let i = 0; i < tds.length; i++) {
        (<HTMLElement>tds[i]).style.setProperty('--list-background-color', this.theme["nav-hover_clr"]);
      }
    }

  }
  closeSideNav(){
    const elem = document.getElementById("sidebar")
    if(elem){
      // elem.classList.toggle("active");
      elem.style.width = "0px";
      this.emitsideNavState.emit(false)
      this.open = false
    }
  }

  sideElementClicked(val : any){
    for(const i of this.displayContent){
      if(i.name == val.name){
        i.open = !i.open
      }
    }
  }

  sideElement(list : any){
    for(let i of this.dashboardData){
        i.clicked = false
    }
    for(let i of this.tableData){
      i.clicked = false
    }

    let Guid

    let listOf = ""
    for(let i of this.dashboardData){
      if(list.Name == i.Name){
        Guid = i.Guid
        i.clicked = true
        listOf = "dashboard"
      }
    }

    for(let i of this.tableData){
      if(list.Name == i.Name){
        Guid = i.Guid
        i.clicked = true
        listOf = "table"
      }
    }
    this.cdr.detectChanges()
    if(!this.isDesktop){
      this.closeSideNav()
    }
    this.emitSelectedSideNav.emit({name: list.Name,tableId:Guid, listOf: listOf})

  }
}
