<div class="dialog-header">Reset Password</div>


<input type="password" placeholder="Enter new password" style="margin:20px 0px;font-size:12px" [(ngModel)] = "password" class="form-control"
title="Password must be alphanumeric"
(input)="strengthChecker(this.password)">

<input type="text" placeholder="Re-enter new password" style="margin:20px 0px;font-size:12px" [(ngModel)] = "repeatPassword" class="form-control">

<div class="text-danger" style="font-size:12px" *ngIf="errorMessage !== ''">
  {{errorMessage}}
</div>

<div id="message">
  <div class="content-message">Password must contain the following:</div>
  <ul>
    <li>Minimum 8 charters required</li>
    <li>Minimum one upper case required</li>
    <li>Minimum one lower case required</li>
    <li>@,!,$ allowed as special chater</li>
  </ul>
</div>


<mat-dialog-actions align="center">
	<button  class="btn mat-raised-button mat-primary myButtonReset" style="margin-right:20px;color:white;"
    [ngStyle]="{
      'background-color' : buttonColor ? buttonColor : '#673ab7'
    }"
  (click)="onConfirmClick()" tabindex="1" [disabled]="!password || !repeatPassword || errorMessage!=''">{{confirmButtonText}}</button>
	<button class="btn mat-raised-button myButtonReset" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
