import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { GET_APP_TABLE,GET_APP_DASHBOARD, GET_DASHBOARD_KPI, GET_VIEW_BY_TABLE, CREATE_DASHBOARD} from '../../app.global';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFqYWluQGFtYXlyYXRlY2guY29tIiwibmJmIjoxNjUyMTYzNTM5LCJleHAiOjE2ODM2OTk1MzksImlhdCI6MTY1MjE2MzUzOX0.iFjVbuSYAybzp_kp5aBe2FGidtOfNLtV630p9kYmjYk"
  }),
  withCredential: true
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationDetailService {

  user !: any
  userId !: string
  constructor(private http: HttpClient,private envService: EnvService) { 
    this.user = localStorage.getItem('user')
    if(this.user){
      this.userId = JSON.parse(this.user).cre57_at_userid
    }
  }

  // getApplicationTables(aid:string,token: string): Observable<any>{
  //   let endpointUrl = this.envService.apiUrl+GET_APP_TABLE+"/"+aid+GET_APP_TABLE
  //   var options = {
  //     'method': 'GET',
  //     'url': endpointUrl,
  //     'headers': {
  //       'Authorization': 'Bearer '+token
  //     }
  //   }
  //   return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  // }

  // getApplicationDashboard(aid:string,token: string): Observable<any>{
  //   let endpointUrl = this.envService.apiUrl+GET_APP_DASHBOARD+"/"+aid+GET_APP_DASHBOARD
  //   var options = {
  //     'method': 'GET',
  //     'url': endpointUrl,
  //     'headers': {
  //       'Authorization': 'Bearer '+token
  //     }
  //   }
  //   return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  // }

  // getDashboardKPI(did:string,token: string): Observable<any>{
  //   let endpointUrl = this.envService.apiUrl+GET_DASHBOARD_KPI+"/"+did+GET_DASHBOARD_KPI
  //   var options = {
  //     'method': 'GET',
  //     'url': endpointUrl,
  //     'headers': {
  //       'Authorization': 'Bearer '+token
  //     }
  //   }
  //   return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  // }

  getDashboardSummaryKPI(applicationId: string, dataAPI :string,token: string,userId: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+'/'+applicationId+"/"+dataAPI+"/"+userId+"/"+dataAPI
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  createDashboardFromKPI(kpiId: string,payload:any, token: string){
    let endpointUrl = this.envService.apiUrl+CREATE_DASHBOARD
    endpointUrl += "("+kpiId+")"
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  getViewByTableId(tableId: string, token: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+GET_VIEW_BY_TABLE+"/"+tableId+GET_VIEW_BY_TABLE
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  getTableData(applicationId: string, dataAPI :string,token: string,userId: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+'/'+applicationId+"/"+dataAPI+"/"+userId+"/"+dataAPI+":"+this.userId
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  getTableDataById(applicationId: string, dataAPI :string,token: string,primaryKeypayload: string): Observable<any>{
    let endpointUrl = this.envService.apiUrl+'/'+applicationId+"/"+dataAPI+"/"+primaryKeypayload+"/"+dataAPI
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

}
