
  <div class="dialog-header">Select User</div>

  <!-- <input style="font-size: 12px;" list="user-contatct" class="form-control" id="contacts"
   name="contacts" [(ngModel)]="selectedOptionLabel" autocomplete="off" placeholder="Select User"
   (change)="onContactChanged(selectedOptionLabel)"> -->

  <!-- <datalist id="user-contatct">
      <option *ngFor="let option of options" [ngValue]="option" >
        {{ option.label }}
      </option>

  </datalist> -->

    <ng-select [(ngModel)]="selectedOptionLabel" placeholder="Select User"  (change)="onContactChanged(selectedOptionLabel)">
        <ng-option *ngFor="let option of options" [value]="option.label">{{option.label}}</ng-option>
     </ng-select>

  <div style="padding-top: 10px; font-size: 12px;" *ngIf="selectedOption && confirmButtonText!='Assign'">
    <div *ngIf="selectedOption.mobile">
      <img class="dialogIcon" src="../../assets/Images/whatsapp.png">
      <mat-slide-toggle [(ngModel)]="selectedOption.isMobileChecked" (change)="toggle($event,'mobile')">{{selectedOption.mobile}}</mat-slide-toggle>
    </div>
    <div *ngIf="selectedOption.email">
      <img class="dialogIcon" src="../../assets/Images/mail.png">
      <mat-slide-toggle [(ngModel)]="selectedOption.isEmailChecked" (change)="toggle($event,'email')">{{selectedOption.email}}</mat-slide-toggle>
    </div>
  </div>



<mat-dialog-actions align="center">
	<button  class="mat-raised-button mat-primary" style="margin-right:20px"
    [ngStyle]="{
      'opacity' : toggleNotSelected ? 0.5 : 1,
      'background-color' : buttonColor ? buttonColor : '#673ab7'
    }"
  (click)="onConfirmClick()" tabindex="1" [disabled]="toggleNotSelected">{{confirmButtonText}}</button>
	<button class="mat-raised-button" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
