<div class="mat-elevation-z8" [ngStyle]="{
  'margin-top': showSearch ? '50px' : '0px',
  'height' : subgridTable ? '50vh' : 'calc(92vh - 100px)'
}">

<div class="table-top" *ngIf="showSearch">

    <div class="search">
      <!-- <mat-icon matPrefix class="divImage2">sentiment_very_satisfied</mat-icon> -->
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search Columns"
          #input
        />
      </mat-form-field>
    </div>
</div>


  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="styled-table">

      <!-- Checkbox Column -->
  <ng-container matColumnDef="select"  class="styled-table">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="{
      'background-color': table_header_back_clr ? table_header_back_clr : '',
      'color' : table_header_text_clr ? table_header_text_clr : ''
      }">
      <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox> -->
      <mat-checkbox *ngIf="checkBoxNeeded" class="header-checkbox" (change)="$event ? masterToggle($event) : null"
        [checked]="selection && selection.hasValue() && isEntirePageSelected()"
        [indeterminate]="selection && selection.hasValue() && !isEntirePageSelected()"
        ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
                    (change)= "checkBoxClicked($event,row,selection.isSelected(row))"
                    [checked]="selection.isSelected(row)"
                    [ngStyle]="{
                      'background-color': iconBackgroundColor ? iconBackgroundColor : '#FFF'
                    }"
                    >
                    <span class="initial-checkbox"
                    [ngStyle]="{
                      'color' : iconColor ? iconColor : '',
                      'display' : selection.isSelected(row) ? 'none' : 'block',
                      'top' : '4px'
                    }"
                     *ngIf="row.checkBoxIconType == 'initial'">{{row.checkboxIcon}}</span>

                     <span class="initial-checkbox"
                     [ngStyle]="{
                       'color' : iconColor ? iconColor : '',
                       'display' : selection.isSelected(row) ? 'none' : 'block',
                       'top' : row.checkBoxIconType == 'image' ? '10px' : '8px'
                     }"
                      *ngIf="row.checkBoxIconType == 'image'"><img class="checkbox-image" [src] = "row.checkboxIcon"></span>
                     
                      <span class="initial-checkbox"
                      [ngStyle]="{
                        'color' : iconColor ? iconColor : '',
                        'display' : selection.isSelected(row) ? 'none' : 'block',
                        'top' : row.checkBoxIconType == 'icon_image' ? '10px' : '8px'
                      }"
                       *ngIf="row.checkBoxIconType == 'icon_image'"><img style="width: 20px;
                       height: 20px;
                       position: absolute;
                       left: 7px;top:-5px" [src] = "row.checkboxIcon"></span>
      </mat-checkbox>            
    </td>
  </ng-container>
   <ng-container  *ngFor="let col of columnHeader">
    <ng-container matColumnDef="{{col.id}}" class="styled-table">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{
        'background-color': table_header_back_clr ? table_header_back_clr : '',
        'color' : table_header_text_clr ? table_header_text_clr : ''
        }"> {{col.label}} </th>
      <td mat-cell *matCellDef="let element"  (click)="onMatCellClick(element,element[col.id],col.id);"> {{element[col.id]}} </td>
    </ng-container>

   </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr class="element-row" mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd" (click)="getTableRecord(row)"
    [ngStyle]="{
      'color' : table_row_text_clr ? table_row_text_clr : '',
      'background-color': odd ? table_row_even_back_clr  : table_row_odd_back_clr
      }"
    ></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[30, 60, 100]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
</div>

