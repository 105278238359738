<div  class="loader-div">
  <img [src]="icon" [ngStyle]="{
    'width' : width,
    'left' : left
  }" class="loader">

</div>

<!-- [ngStyle]="{
  'margin-top' : marginTop
}" -->
