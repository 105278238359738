<div class="wrapper">
  <div id="sidebar" class="sidenav" [ngStyle]="{
    'background-color' : nav_background_clr ? nav_background_clr : '#ffe7e0'
  }">
    <button *ngIf="open" style="margin-left:7px" type="button" id="sidebarCollapse" class="btn navbar-btn" (click)="closeSideNav()">
      <i class="fa fa-close menu-icon-close" [ngStyle]="{
        'color' : iconColor? iconColor : '#000'
      }"></i>
    </button>
      <div *ngFor="let val of displayContent" style="margin-bottom: 15px;" >
        <div class="nav-elem" (click)="sideElementClicked(val)" [ngStyle]="{
          'color' : nav_header_clr ? nav_header_clr : '#000'
        }">
          <span class="title header" [ngStyle]="{
            'width' : val.list ? '84%'  : '100%',
            'color' : nav_header_clr ? nav_header_clr : '#000'
          }">{{val.name}}</span>
          <span *ngIf="val.list" class="arrow"><i class="fa menu-arrow" [ngStyle]="{
            'color' : iconColor? iconColor : '#000'
          }"
           [ngClass]="val.open ? 'fa-minus': 'fa fa-caret-down'"
            ></i></span>
        </div>
        <div *ngIf="val.open" class="all-lists">
          <ng-container *ngFor="let list of val.list" ><span  *ngIf="list.Showonsitemap && commonService.checkAccess(list.Id,'show')" class="title listTitle" style="width:100%;padding-left:8px" (click)="sideElement(list)"
            [ngStyle]="{

               'color' : nav_text_clr ? nav_text_clr : '#818181'
           }">
           <!-- 'background-color' : list.clicked ? 'rgb(255, 179, 158)' : '', -->
            <img class="imageIcon" [src]="list.Icon ? list.Icon : iconImage">{{list.Name}}
          </span></ng-container>
        </div>
      </div>
    </div>

  <!-- Page Content Holder -->
  <div id="content">
          <button style="margin-left:7px"  *ngIf="!open" type="button" id="sidebarCollapse" class="btn navbar-btn" (click)="openSideNav()">
                                <span [ngStyle]="{
                                  color : menuColor ? menuColor : '#000'
                                }" class="fa fa-bars menu-icon-open"></span>
                            </button>
  </div>

  <!-- <div id="myTable">Show Table</div>

  <div id="myDashboard">Show Dashboard</div> -->




