import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {


  password !: string
  repeatPassword !: string
  errorMessage = ""
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  buttonColor !: string

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,private dialogRef: MatDialogRef<ResetPasswordComponent>) {
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      this.buttonColor = data.buttonColor

    }
  }

  onConfirmClick(): void {
    this.errorMessage = ""

    if(this.password == this.repeatPassword){
       this.dialogRef.close(this.password);
    }
    else{
      this.errorMessage = "Password does not match"
    }
  }


  strengthChecker(password : string){
    this.errorMessage = ""
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    if(!password){
      this.errorMessage = ""
    }
    else{
      let weak = 0
      if (!password.match(/[a-z]+/)) {
        weak += 1;
        this.errorMessage = "Password should have small letters"
      }
      else if (!password.match(/[A-Z]+/)) {
        weak += 1;
        this.errorMessage = "Password should have capital letters"
      }
      else if (!password.match(/[0-9]+/)) {
        weak += 1;
        this.errorMessage = "Password should have numbers"
      }
      else if (password.length < 8) {
        weak += 1;
        this.errorMessage = "Password length should not be less than 8";
      }

      else if (password.length > 15) {
        weak += 1;
        this.errorMessage = "Password length should not be more than 15";
      }
      if (password.match(/[#&]+/)) {
        // strength += 1;
        this.errorMessage = "Password allows only $,! and @ special charcters";
      }
    }

  }
}

