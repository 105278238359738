import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {

  confirmButtonText !: string
  cancelButtonText !: string
  buttonColor !: string
  tncPoints : string[] = []

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,private dialogRef: MatDialogRef<TermsAndConditionComponent>) {
    if(data){
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      this.buttonColor = data.buttonColor
      if(data.dataInfo && data.dataInfo.length>0){
        for(let i of data.dataInfo){
          if(i!==''){
            this.tncPoints.push(i)
          }
        }
      }
    }
  }

  ngOnInit(): void {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
