import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input()
  width  !: string

  @Input()
  left !: string

  @Input()
  icon : string = '../../assets/Images/AT1.gif'
  constructor() { }

  ngOnInit(): void {
  }

}
