import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApplicationDetailService } from 'src/app/services/application-detail-service/application-detail-service';
import { CommonService } from 'src/app/services/common.service';
import { FormCaptureService } from 'src/app/services/form-capture/form-capture.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';

@Component({
  selector: 'app-standard-form',
  templateUrl: './standard-form.component.html',
  styleUrls: ['./standard-form.component.scss']
})
export class StandardFormComponent implements OnInit, AfterViewInit {
  @Input()
  formDetails !: any

  @Input()
  rowData !: any

  @Input()
  newButton !: boolean

  @Input()
  dbTableName !: string

  @Input()
  primaryColumn !: string

  @Input()
  userId !: string

  @Input()
  userName !: string

  @Input()
  tableId !: string

  @Input()
  theme !: any

  @Input()
  formTemplate !: string

  @Input()
  allTables !: any

  @Input()
  tableLogicalName !: string

  @Input()
  businessId !: string

  @Input()
  selectedView !: any

  @Input()
  newWindow !: boolean

  @Input()
   lookupTableName !: string

  @Input()
  comp !: any

  @Input()
  lookups : {[key: string]: any} = {}

  @Output()
  emitCloseForm = new EventEmitter<boolean>();

  windowOpen  = 0
  showQuickCreateForm = false
  showQuickAddForm = false
  clickedComp !: any
  newLookup : boolean =  false

  formData !: any
  formIcon !: any
  tableIcon  !: any
  headerData : any = []
  headerFields !: any

  formButton : any[] =  []
  showButtons :  any[] = []
  visibleButton : any = []
  dropdownButton : any = []
  saveButtonAction !: any

  createBuField !: any
  createStatus !: any
  createStatusReason !: any
  showGoogleMapSearch = false
  storeKeysLabel : {[key : string] : string} = {}
  showLoader = false
  myForm !: any
  buttonClicked = false
  errorMessage = ''

  ownerId !: any
  ownerName !: any
  tableDisplayName !: string
  formLoaded = false

  closeIcon = "../../../assets/Images/close.png"
  closeIconColor = "#000"
  form_section_text_clr !: string
  form_section_header_line_clr !: string
  form_section_header_back_clr !: string
  form_section_border_clr !: string
  header_image_name_clr !: string
  form_back_clr !: string
  form_text_clr !: string
  loaderIcon !: string
  plusIconColor !: string
  fileComp !: any
  fileStored !: any
  callDeleteFile = false
  formStatus = 'Active'
  formStatusReason = 'This record status is Active'
  showFormMessage = true

  @ViewChild('lookUpComp') myLookUp !: any
  storeAllLookUps : any = []

  constructor(private commonService : CommonService,
    private tableOperationService : TableOperationService,
    private detailService : ApplicationDetailService,
    private dialog: MatDialog,
    private cdr : ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    private formCaptureSerive : FormCaptureService,
    private toastr : ToastrService) { }

  ngAfterViewInit(): void {
    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(this.rowData && this.formStatus == 'Inactive'){
            comp.lock = true
          }
          this.storeKeysLabel[comp.label] = this.tableId+comp.id
          if(comp.required){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.required = true
            }
          }
          if(comp.calculated){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.readOnly = true
              elem.classList.add("readonly-class")
            }
          }
          if(comp.lock){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.disabled = true
              elem.style.backgroundColor = this.theme ? this.theme["form-section_text_disabled_clr"] : ''
            }
          }
          if(comp.datatype == "address_auto_complete"){
            if(this.showGoogleMapSearch){
              let elem = document.getElementById(this.tableId+comp.id) as HTMLInputElement;
              const val = new google.maps.places.SearchBox(elem)
              comp.googleMapValue = val
              // const options = {
              //   fields: ["formatted_address", "geometry", "name"],
              //   strictBounds: false,
              //   types: ["establishment"],
              // };
              var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(26.8765702,75.7521331), new google.maps.LatLng(-33.8474, 151.2631));

              var options = {
               bounds: defaultBounds,
               types: ['geocode']
              };


              const autocomplete = new google.maps.places.Autocomplete(elem, options);
              google.maps.event.addListener(autocomplete, 'place_changed',  () => {
                  var place = autocomplete.getPlace();
                  let storeData : {[key: string]: string} = {
                    'route' : '',
                    'neighborhood' : '',
                    'sublocality_level_2' : '',
                    'sublocality_level_1' : '',
                    'locality'  : '',
                    'administrative_area_level_1' : '',
                    'country' : '',
                    'postal_code' : ''
                  }
                  let add = elem.value.split(',')
                  let initialAdd = add[0].split(' ')
                  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    storeData[component.types[0]] = component.long_name
                  }
                  this.setCompleteAddressFields(storeData,initialAdd[0])
              });
            }
            else{
              comp.visible = false
            }
          }
          if(comp.datatype == "multilinetext"){
            let elem = <HTMLTextAreaElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.rows = comp['min-row'] ? comp['min-row'] : 4
            }
          }
        }
      }
    }

    if(this.theme){
      this.changeActiveCss()
      let elem3 = document.getElementsByClassName('form-control')
      if(elem3){
        for(let i=0;i< elem3.length;i++){
          (<HTMLElement>elem3[i]).style.borderColor = this.theme["form-section_text_border_clr"]
        }
      }
      let elem4 = document.getElementsByClassName('ng-select-container')
      if(elem4){
        for(let i=0;i< elem4.length;i++){
          (<HTMLElement>elem4[i]).style.borderColor = this.theme["form-section_text_border_clr"];
          (<HTMLElement>elem4[i]).style.color = this.form_section_header_back_clr;
          (<HTMLElement>elem4[i]).style.setProperty('--disablebackgroundColorlookup',this.theme["form-section_text_disabled_clr"] );
        }
      }
      let elem5 = document.getElementsByClassName('head-label')
      if(elem5){
        (<HTMLElement>elem5[0]).style.fontWeight = '600';
        (<HTMLElement>elem5[0]).style.color = this.form_section_header_back_clr
      }
      this.applyMultiSelectCss()
      this.cdr.detectChanges()
      // let elem5 = document.getElementsByClassName('ng-input');
      // if(elem5[0]){
      //   (<HTMLElement>elem5[0]).addEventListener('click', (evt : any) => {

      //    for(let lookup of this.storeAllLookUps){
      //      if(lookup.selectedId == this.myLookUp.element.id){
      //       this.openFormForLookup(this.myLookUp.element.id,lookup.dataapi,lookup.lookUpTablePrimaryColumn,lookup)
      //      }
      //    }
      // }, false);
      // }

    }
  }

  setCompleteAddressFields(storeData : any,initialAdd : string){
    let address1 = document.getElementById(this.storeKeysLabel['Address Line 1']) as HTMLInputElement
    address1.value = initialAdd + " " + storeData['route']

    let address2 = document.getElementById(this.storeKeysLabel['Address Line 2']) as HTMLInputElement
    address2.value = storeData['neighborhood'] +" "+ storeData['sublocality_level_2'] + " "+storeData['sublocality_level_1']

    let city = document.getElementById(this.storeKeysLabel['City']) as HTMLInputElement
    city.value = storeData['locality']

    let state = document.getElementById(this.storeKeysLabel['State']) as HTMLInputElement
    state.value = storeData['administrative_area_level_1']

    let country = document.getElementById(this.storeKeysLabel['Country']) as HTMLInputElement
    country.value = storeData['country']

    let postalCode = document.getElementById(this.storeKeysLabel['Zipcode']) as HTMLInputElement
    postalCode.value = storeData['postal_code']

    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          switch(comp.label){
            case "Address Line 1" :
              comp.value = initialAdd + " " + storeData['route']
              break;
            case "Address Line 2" :
              comp.value = storeData['neighborhood'] +" "+ storeData['sublocality_level_2'] + " "+storeData['sublocality_level_1']
              break;
            case "City" :
              comp.value = storeData['locality']
              break;
            case "State" :
              comp.value = storeData['administrative_area_level_1']
              break;
            case "Country" :
              comp.value = storeData['country']
              break;
            case "Zipcode" :
              comp.value = storeData['postal_code']
              break;
          }
        }
      }
    }
  }

  applyMultiSelectCss(){

    let elem5 = document.getElementsByClassName('selected-item')

    if(elem5){
      for(let i=0;i< elem5.length;i++){
        (<HTMLElement>elem5[i]).style.borderColor = this.theme["form-section_header_back_clr"];
        (<HTMLElement>elem5[i]).style.backgroundColor = this.theme["form-section_header_back_clr"]
      }
    }

    let elem7 = document.getElementsByClassName('dropdown-btn');
    if(elem7){
      for(let i=0;i< elem7.length;i++){
        (<HTMLElement>elem7[i]).style.borderColor = this.theme["form-section_text_border_clr"];
        (<HTMLElement>elem7[i]).style.setProperty('--disablebackgroundColor',this.theme["form-section_text_disabled_clr"] );
      }
    }

    let elem6 = document.querySelectorAll('input[type=checkbox] + div');
    if(elem6){
      for(let i=0;i< elem6.length;i++){
        (<HTMLElement>elem6[i]).style.setProperty('--borderColor',this.theme["form-section_header_back_clr"]);
      }
    }

    let elem8 = document.querySelectorAll('input:checked + div');
    if(elem8){
      for(let i=0;i< elem8.length;i++){
        (<HTMLElement>elem8[i]).style.setProperty('--backgroundColor',this.theme["form-section_header_back_clr"] );
      }
    }

  
    this.cdr.detectChanges()
  }

  selectedLookup(option : any,comp: any){
    if(option){
      comp.selectedOptionLabel = option
      comp.selectedId = option.id
    }
    else{
      this.resetCalculations(comp)
    }
    this.valuechange(option,comp)
  }

  resetCalculations(comp: any){
    comp.selectedOptionLabel = {
      value: undefined,
      id : undefined
    }
    comp.options = JSON.parse(JSON.stringify(comp.savedOptions))
  }

  closeMessage(){
    this.showFormMessage = false
  }

  openFormForLookup(id: string,api:string,primary_column:string,lookup: any){
    this.fetchRecordInEditMode(id,api,primary_column,true,lookup)
  }
  lookUpClicked(comp: any, newLookUp : boolean){
    this.showQuickCreateForm= false
    this.showQuickAddForm = false
    this.newLookup = true
    if(!newLookUp){
      for(let i of comp.allTableData){
        if(i[comp.lookUpTablePrimaryColumn] == comp.selectedId)
        {
          comp.rowData = i
          comp.clickedRowData = i
        }
      }
    }
  
    comp.formGeneration = this.commonService.getLookupFormFirst(comp.formGeneration)
    this.clickedComp = comp
    if(comp.formGeneration[0].Type == 'Quick Create'){
      comp.copyPrimaryColumn = comp.primary_column
      this.clickedComp['dbTableName'] = comp.lookupTableName
      this.clickedComp['primary_column'] = comp.lookUpTablePrimaryColumn
      this.showQuickCreateForm = true
    }
    else if(comp.formGeneration[0].Type == 'Quick Add'){
      comp.copyPrimaryColumn = comp.primary_column
      this.clickedComp['dbTableName'] = comp.lookupTableName
      this.clickedComp['primary_column'] = comp.lookUpTablePrimaryColumn
      this.showQuickAddForm = true
    }
    else{
      this.passObject(comp)
    }
  }

  ngOnInit(): void {
    this.setFormStatusAndReason()
    this.commonService.logging(this.theme)
    this.commonService.logging(this.formDetails)
    this.commonService.logging(JSON.parse(this.formDetails[0].Layout))
    this.formData = JSON.parse(this.formDetails[0].Layout).tab
    let formLayout = JSON.parse(this.formDetails[0].Layout)
    if(Object.keys(formLayout).includes('icon')){
      this.formIcon = JSON.parse(this.formDetails[0].Layout).icon
    }
    else{
      this.formIcon = false
    }
    this.headerFields = JSON.parse(this.formDetails[0].Layout).header

    let flag = false
    if(typeof (this.formIcon) == "string"){
      for(let i in this.rowData){
        if(i == this.formIcon){
          this.formIcon = this.rowData[i] ? this.rowData[i] : true
          flag = true
        }
      }
      if(!flag){
        this.formIcon = true
      }
    }
    this.commonService.logging(this.rowData)

    for(let table of this.allTables){
      if(this.tableId == table.uid){
        this.ownerId = table.OwnerId
        this.ownerName = table.OwnerName
        this.tableDisplayName = table.Name
        this.tableIcon = table.Icon
      }
    }

    let userProfile = localStorage.getItem('userProfile')
    if(userProfile){
      let profile = JSON.parse(userProfile)
      this.showGoogleMapSearch = profile.business.EnableGoogleAddressSearchAPI
      this.createBuField =JSON.parse(profile.business.BuPrimaryColumn)["primary_field"]
      this.createStatus =JSON.parse(profile.business.BuPrimaryColumn)["status_field"]
      this.createStatusReason =JSON.parse(profile.business.BuPrimaryColumn)["status_reason_field"]
    }

   this.assignValuesToForm()
   this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)
   if(this.newButton){
    this.formCaptureSerive.openNewForm(this.tableId,this.formDetails[0].uid,this.formData)
   }

    if(this.theme){
      this.closeIconColor = this.theme['form-back_icon_clr']
      this.form_section_text_clr = this.theme["form-section_header_text_clr"]
      this.form_section_header_line_clr = this.theme["form-section_header_line_clr"]
      this.form_section_header_back_clr = this.theme["form-section_header_back_clr"]
      this.form_back_clr = this.theme["form-section_back_clr"]
      this.form_text_clr = this.theme["form-section_text_clr"]
      this.form_section_border_clr = this.theme["form-section_border-clr"]
      this.header_image_name_clr = this.theme["main-header_clr"]
      this.loaderIcon = this.theme['progress-bar_icon']
      this.plusIconColor = this.theme['form-lookup_add_icon']
    }
    this.cdr.detectChanges()
  }

  setFormStatusAndReason(){
    if(this.rowData){
      let myTable
      for(let table of this.allTables){
        if(table.uid == this.tableId){
          myTable = table
        }
      }
      let statusOption = JSON.parse(myTable.StatusOptions)
      let activeStatus, inactiveStatus
      for(let status of statusOption){
        if((status.Text).toLowerCase() == 'active'){
          activeStatus = status.Value
        }
        else{
          inactiveStatus = status.Value
        }
      }
      if(this.rowData[myTable.Status] == null || this.rowData[myTable.Status] == activeStatus){
        this.formStatus = 'Active'
        this.formStatusReason = this.rowData[myTable.Status] ? 'This form status is '+this.rowData[myTable.Status+'@OData.Community.Display.V1.FormattedValue'] +' Reason : ' + this.rowData[myTable.ActiveStatus+'@OData.Community.Display.V1.FormattedValue']: 'This form status is Active'
      }
      else if(this.rowData[myTable.Status] == inactiveStatus){
        this.formStatus = 'Inactive'
        this.formStatusReason = this.rowData[myTable.Status] ? 'This form status is '+this.rowData[myTable.Status+'@OData.Community.Display.V1.FormattedValue'] +' Reason : ' + this.rowData[myTable.InActiveStatus+'@OData.Community.Display.V1.FormattedValue']: 'This form status is Inactive'
      }
    }
  }

  getSubgridTableForView(comp : any, buttonevent ?: boolean){
    for(let table of this.allTables){
      if(comp.tableid == table.uid){
        let subgridTableIcon = table.Icon
        if(!table.readNotAllowed){
          comp.formGeneration = this.commonService.getDefaultFormFirst(table.forms)
          comp.quickAddFormExist =  this.commonService.checkQuickFormExist(table.forms)
          comp.primary_column = table.Primary_Column
          for(let view of table.views){
            if(view.uid == comp.viewid){
              let app_name = this.businessId.replace(/-/g,'_')
              comp.selectedView = view
              comp.tableData = []
              comp.tableTableLogicalName = table.Table_Logical_Name
              comp.dbTableName = table.Data_Entity_Set
              if(buttonevent){
                this.showLoader = true
              }
              this.detailService.getTableData(app_name,view.DataApi,this.commonService.getAuthToken(),this.userId).subscribe((res)=>{
                comp.layout = []
                comp.mobileLayout = []
                this.showLoader = false
                if(res && res.value && res.value.length>0){
                  if(res["@odata.context"]){
                    let str = res["@odata.context"]
                    comp.dbTableName = str.substring(
                      str.indexOf("#") + 1,
                      str.indexOf("(")
                    );
                  }
                  comp.layout =  view.Layout ? JSON.parse(view.Layout.toString()).values : []
                  comp.mobileLayout = comp.layout.slice(0,2)
                  for(let data of res.value){
                    //To filter table data as per selected table
                    if(this.rowData && data[comp.relatedid] == this.rowData[this.primaryColumn]){
                      comp.tableData.push(data)
                    }
                  }
                  let dateId = ""
                  for(const i of comp.layout){
                    if((i.type).toLowerCase()=="date"){
                      dateId = i.id
                      for(const j of comp.tableData){
                        if(j[dateId] && j[dateId]!=""){
                           var d = new Date(j[dateId])
                           j[dateId] = moment(d).format('DD-MMM-YYYY');
                        }
                      }
                    }
                  }
                  let icon = JSON.parse(view.Layout.toString()).icon
                  // let header_primary = JSON.parse(this.formGeneration[0].Layout).header.primery_field
                  for(const row of comp.tableData){
                    for(let j in row){
                      this.assignRowIconForEachSubgridRows(row,comp.primary_column,icon,subgridTableIcon)
                      if(j == "createdon"){
                        let utcDate = row[j]; 
                        let localDate = (new Date(utcDate)).toString();
                        row['createdon@OData.Community.Display.V1.FormattedValue'] = localDate.substring(0,localDate.indexOf('G'))
                      }
                    }
                  }
                }
                this.createSubChildTableData(comp.tableData)
                this.formCaptureSerive.onSubgridRefresh(comp.tableid,comp.formid,comp,this.formData,this.headerData,this.rowData)
                // let updated = this.formCaptureSerive.onSubgridRefresh(comp.tableid,comp.formid,comp,this.formData,this.headerData,this.rowData)
                // if(updated){
                //   this.updateParentTableData(updated)
                // }
                if(comp.tableData.length==0){
                  this.errorMessage = "No Data Available"
                }
              })
            }
          }
          comp.headerButton = this.commonService.sortDataBySequence(table.tableRibbons)
          if(table.TableTemplate ==""){
            comp.headerButton = this.tableOperationService.removeShareButton(comp.headerButton)
          }
          this.componentResetButton(comp)
        }
        else{
          comp.visible = false
        }
      }
    }
  }

  updateParentTableData(payload: any){
    this.tableOperationService.updateTableRecords(this.rowData[this.primaryColumn],this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
          if(this.selectedView){
            this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)
          }
        
      }
    })
  }

  assignRowIconForEachSubgridRows(row : any,header_primary : string,icon: any,subgridTableIcon: any){
    let flag = false
    if(typeof (icon) == "string"){
      for(let i in row){
        if(i == icon && row[i]){
          row.checkboxIcon = 'data:image/jpg;base64,'+row[i]
          row.checkBoxIconType = "image"
          row.checkboxIconColor = "#FFF"
          flag = true
        }
      }
      if(!flag){
        row.checkboxIcon = subgridTableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
    else{
      if(!icon){
        this.setInitials(row,header_primary)
      }
      else{
        row.checkboxIcon = subgridTableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
  }

  setInitials(row: any, header_primary: string){
    if(row[header_primary]){
      let str = ((row[header_primary]).trim()).toString().split(" ")
      let initials = ""
      if(str.length == 1){
        initials = str[0].charAt(0)+(str[0].charAt(1) ? str[0].charAt(1) : str[0].charAt(0))
      }
      else{
        initials =  (str[str.length-1].charAt(0) == '(' || str[str.length-1].charAt(0) == ')') ? str[str.length-2].charAt(0) : str[str.length-1].charAt(0)
        initials = str[0].charAt(0) + initials
      }
      row.checkboxIcon = initials.toUpperCase()
      row.checkBoxIconType = "initial"
    }
  }

  createSubChildTableData(tableData : any){
    for(let i of  tableData){
      this.addSubChildToTableData(i)
    }
  }

  addSubChildToTableData(i: any){
    for(let elem in i){
      if(i[elem] instanceof Object){
        this.addSubChildToTableData(i[elem])
        for(let j in i[elem]){
          let str = elem+"."+ j
          i[str] = i[elem][j]
       }
      }
    }
  }

  closeTableForm(comp: any){
    comp.tableRowClicked = false
  }

  changeActiveCss(){
    if(this.theme){
      let elem = document.getElementsByClassName('mat-tab-label')
      for(let i=0;i<elem.length;i++){
        if(elem[i]){
          (<HTMLElement>elem[i]).style.color = this.theme["form-tab_header_noselect_clr"]
        }
      }

      let elem1 = <HTMLElement>document.getElementsByClassName('mat-tab-label mat-tab-label-active')[0]
      if(elem1){
        elem1.style.color = this.theme["form-tab_header_onselect_clr"]
      }
      let elem2 = <HTMLElement>document.getElementsByClassName('mat-ink-bar')[0]
      if(elem2){
        elem2.style.backgroundColor = this.theme["form-tab_header_onselect_clr"]
      }
    }
  }

  changeToggleCss(comp : any){
    let elem6 = document.getElementsByClassName('mat-slide-toggle-thumb')
    if(elem6){
      for(let i=0;i< elem6.length;i++){
        (<HTMLElement>elem6[i]).style.backgroundColor = comp.value ? this.form_section_header_back_clr : '#FFFFFF'
      }
    }

    let elem7 = document.getElementsByClassName('mat-slide-toggle-bar')
    if(elem7){
      for(let i=0;i< elem7.length;i++){
        (<HTMLElement>elem7[i]).style.backgroundColor = comp.value ? this.form_section_header_back_clr : 'rgba(0,0,0,.38)'
      }
    }
  }

  componentResetButton(comp: any){
    comp.showButtons = []
    comp.visibleButton = []
    comp.dropdownButton = []
    for(let b of comp.headerButton){
      b.show=true
      if(b.scope == "No Selection "){
        comp.showButtons.push(b)
      }
    }
    let ownerName = ''
    for(let table of this.allTables){
      if(this.tableId == table.uid){
        ownerName = table.OwnerName
      }
    }
    this.checkButtonVisibilityByRole(comp,ownerName)
    if(comp.showButtons.length>6){
      comp.visibleButton = comp.showButtons.slice(0,6)
      comp.dropdownButton = comp.showButtons.slice(6)
    }
  }

  checkComponentTableRows(selection : any,comp: any){
    comp.showButtons = []
    comp.visibleButton = []
    comp.dropdownButton = []
    for(let b of comp.headerButton){
      if(b.scope == "No Selection "){
        comp.showButtons.push(b)
      }
    }
    if(selection.selected && selection.selected.length>1){
      comp.checkedRows = selection._selected
      for(let i=0;i<comp.headerButton.length;i++){
        if(comp.headerButton[i].scope=="Multiple Selection"){
          comp.showButtons.push(comp.headerButton[i])
        }
      }
    }
    else{
      if(selection.selected.length==1){
        comp.checkedRows = selection._selected
        for(let i=0;i<comp.headerButton.length;i++){
          if(comp.headerButton[i].scope=="Single Selection" || comp.headerButton[i].scope=="Multiple Selection"){
            comp.showButtons.push(comp.headerButton[i])
          }
        }
      }
    }
    let ownerName = ''
    for(let table of this.allTables){
      if(this.tableId == table.uid){
        ownerName = table.OwnerName
      }
    }
    this.checkButtonVisibilityByRole(comp,ownerName)
    comp.showButtons = this.commonService.sortDataBySequence(comp.showButtons)
    if(comp.showButtons.length>6){
      comp.visibleButton = comp.showButtons.slice(0,6)
      comp.dropdownButton = comp.showButtons.slice(6)
    }
  }

  checkButtonVisibilityByRole(comp : any, ownerString : string){
    for(let i of comp.showButtons){
      if(i.hide){
        i.show = false
      }
      else if(i.hideNonUser){
        let count = 0
        comp.checkedRows.forEach((elem: any)=>{
          if(elem[ownerString] !== this.userName){
           count++;
          }
        })
        i.show = count==0 ? true : false
      }
      else{
        i.show = true
      }
    }
  }

  checkButtonVisibilityByFormStatus(formButtons: any){
    for(let i of formButtons){
      i.show = false
      if(i.showoninactive){
        i.show = true
      }
    }
  }

  checkRowClicked(row : any, comp: any){
    this.showQuickCreateForm = false
    comp.clickedRowData = row
    comp.tableRowClicked = true
    comp.triggeredNewButton = false
    this.newLookup = false
    comp.storeLookups = this.lookups
    if(comp && comp.formGeneration && comp.formGeneration.length>0){
      comp.formGeneration = this.commonService.getDefaultFormFirst(comp.formGeneration)
      if(comp.formGeneration[0].Type == 'Quick Create'){
        this.showQuickCreateForm = true
        this.clickedComp = comp
      }
      else if(comp.formGeneration[0].Type == 'Quick Add'){
        this.showQuickAddForm = true
        this.clickedComp = comp
      }
      else{
        this.showQuickCreateForm = false
        this.showQuickAddForm = false
        this.passObject(comp)
      }
    }
    //
    // let elem = <HTMLElement>document.getElementsByClassName('form-top')[0]
    // if(elem){
    //   elem.style.zIndex = "1"
    // }

    // let elem2 = <HTMLElement>document.getElementsByClassName('mat-tab-header')[0]
    // if(elem2){
    //   elem2.style.zIndex = "1"
    // }
    this.callDateCoversion(comp)
  }

  onFileSelected(event: any,comp: any){
    this.fileComp = JSON.parse(JSON.stringify(comp))
    let file = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result ? (reader.result).toString().replace('data:', '').replace(/^.+,/, '') : ''
      this.fileComp.value = base64String
      this.fileComp.fileName = file.name
    };
  }

  callSaveFile(id?:any,close?:boolean){
    this.fileComp.payload = {
      "type":"upload",
      "tableLogicalName":this.tableLogicalName,
      "recordId":this.rowData ? this.rowData[this.primaryColumn] : id,
      "fieldLogicalName":this.fileComp.id,
      "fileName":this.fileComp.fileName,
      "fileBytes":this.fileComp.value
    }
    this.tableOperationService.fileOperationOnRecord(this.fileComp.payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(close){
        this.toastr.success("Details updated successfully")
        this.closeForm()
        this.showLoader = false
      }
    })
  }

  callDeleteFileService(close ?: boolean){
   let payload = {
      "type":"delete",
      "fileid": this.fileStored
    }
    this.tableOperationService.fileOperationOnRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(close){
        this.toastr.success("Details updated successfully")
        this.closeForm()
        this.showLoader = false
      }
    })
  }

  downloadFile(comp: any){
    let payload = {
      "type":"download",
      "tableLogicalName":this.tableLogicalName,
      "recordId":this.rowData ? this.rowData[this.primaryColumn] : '',
      "fieldLogicalName":comp.id,
    }
    this.tableOperationService.fileOperationOnRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(res.result){
        let elem = 'data:application/octet-stream;base64,' + res.result
        const downloadLink = document.createElement('a');
        downloadLink.href = elem;
        downloadLink.download = comp.fileName;
        downloadLink.click();
      }
      else{
        this.toastr.error("Failed to download file")
      }
    })
  }

  removeFile(comp : any){
    if(comp.value){
      this.fileStored = JSON.parse(JSON.stringify(comp.value))
      comp.value = null
      this.fileComp = null
      this.callDeleteFile = true
    }
  }


  closeQuickCreate(elem : any,comp: any){
    if(elem.clicked && !this.newLookup){
      this.getSubgridTableForView(comp)
      if(comp.refresh){
        this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn,)
      }
    }
    if(elem.close){
      comp.tableRowClicked = false
      comp.triggeredNewButton = false
      comp.clickedRowData = undefined
      this.showQuickCreateForm ? this.showQuickCreateForm = false : '' 
      this.showQuickAddForm ? this.showQuickAddForm = false : '' 
    }
    if(this.newLookup){
      elem.clicked ? this.assignValuesToForm(elem.payload):  this.assignValuesToForm()
    }
  }

  callDateCoversion(comp: any){
    let dateId
      for(const i of comp.layout){
        if(i.type=="date"){
          dateId = i.id
          if(comp.clickedRowData[dateId] && comp.clickedRowData[dateId]!=""){
              var d = new Date(comp.clickedRowData[dateId])
              comp.clickedRowData[dateId] = moment(d).format('YYYY-MM-DD');
            }
        }
      }
  }

  closeForm(){
    let elem = <HTMLElement>document.getElementsByClassName('form-top')[0]
    if(this.newWindow){
      window.close()
    }
    this.emitCloseForm.emit(this.buttonClicked)
  }

  performFormEvent(button : any){
      this.buttonClicked = true

      if(button.actionApi == "Save"){
        this.saveFormData()
        this.formCaptureSerive.onSave(this.tableId,this.formDetails[0].uid)
      }
      else if(button.actionApi == "Delete"){
        let rowId = this.rowData[this.primaryColumn]
        this.openDialog(rowId)
      }
      else if(button.actionApi == "Active"){
        let rowId = this.rowData[this.primaryColumn]
        this.openDialogForActiveInactive(rowId,'Are you sure you want to activate?','Active')
  
      }
      else if(button.actionApi == "Inactive"){
        let rowId = this.rowData[this.primaryColumn]
        this.openDialogForActiveInactive(rowId,'Are you sure you want to inactivate?','Inactive')
      }
      else if(button.actionApi == "Share"){
        let info = localStorage.getItem("primaryContactInfo")
        let primarInfo = ""
        if(info){
          primarInfo = JSON.parse(info)
        }
        this.openShareDialog(primarInfo)
      }
      else if(button.actionApi == "Assign"){
        let rowId = this.rowData[this.primaryColumn]
        let info = localStorage.getItem("primaryUserInfo")
        let primarInfo = ""
        if(info){
          primarInfo = JSON.parse(info)
        }
        this.openAssignDialog(primarInfo,rowId)
      }
      else if(button.actionApi == "SaveClose"){
        this.saveFormData(true,true)
        this.formCaptureSerive.onSave(this.tableId,this.formDetails[0].uid)
      }
      else if(button.actionApi == "New"){
        if(this.comp){
          this.comp["storeLookups"] = this.lookups
          this.comp["clickedRowData"] = undefined
          this.comp["triggeredNewButton"] = true
          this.comp["dbTableName"] = this.dbTableName
          this.comp["selectedView"] = this.selectedView
          this.comp["lookupTableName"] = this.lookupTableName
          this.passObject(this.comp)
        }
      }
      else if(button.actionApi == "Refresh"){
        if(this.rowData){
          this.showLoader = true
          this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)
        }
      }
      else if(button.actionApi == "RibbonAction"){
        this.showLoader = true
        let rowId = this.rowData[this.primaryColumn]
        if(button.config){
          let config = JSON.parse(button.config)

          if(config.ask_for_confirmation){
            this.openConfirmationDialogWithConfirmation(rowId,config,button)
          }
        }
      }
  }

  //If subgriRefresh is false, do not refresh the subgrid default always refresh
  saveFormData(subgriRefresh:boolean = true,close?:boolean){
    this.showLoader = true
    let emptyField = 0
    let invalidField = 0
    let emptyFieldLabels = ''
    let invalidFieldLables = ''
    let payload : {[key: string]: any} = {}
    let rowId =  this.rowData ? this.rowData[this.primaryColumn] : null
    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.visible && comp.datatype !== 'address_auto_complete'){
            comp.error = false
            if(comp.type == "field" && !(comp.id).includes('assignedon') &&  !(comp.id).includes('createdon') && !(comp.id).includes("FormattedValue")){
              if(comp.id == this.ownerName){
                comp.value = comp.value ? comp.value : this.userName
              }
              if(comp.datatype == "number"){
                comp.value = comp.value ? comp.value.toString() : null
              }
              if(comp.datatype == "mobile"){
                comp.value = comp.value ? comp.value.toString() : null
              }
              if(comp.datatype == "amount"){
                  if(comp.value == null){
                    comp.value = Number("0.00")
                  }
                  else{
                    comp.value = Number(comp.value)
                  }
              }
              if(comp.datatype == "file"){
                comp.value = comp.value ? comp.value : null
              }
              if(comp.datatype == "optionset"){
                comp.value = comp.selectedOptionLabel.value ? Number(comp.selectedOptionLabel.value) : null
              }
              if(comp.datatype == "multioptionset"){
                comp.value=''
                for(let i of comp.selectedItem){
                  comp.value += i.item_id + ','
                }
                comp.value = comp.value=='' ? comp.value.substring(0,comp.value.length-1) : null
              }
              if(comp.datatype == "lookup"){
                comp.value = comp.selectedOptionLabel
                payload[comp.id+"@odata.bind"] = comp.selectedOptionLabel.id ? "/"+comp.lookupTableName+"("+comp.selectedOptionLabel.id+")" : null
                if((comp.value == null || !comp.value.value) && comp.required && comp.visible){
                  comp.error = true
                  emptyField++
                  emptyFieldLabels += comp.label + ','
               }
              }
              else{
                payload[comp.id] = comp.value != "" ? comp.value : null
              }
            }
            if(comp.id && (comp.id).includes("FormattedValue")){
              if(comp.datatype == "optionset"){
                let id = comp.id.split('@')[0]
                comp.value = comp.selectedOptionLabel.value
                payload[id] = comp.selectedOptionLabel.value ? Number(comp.selectedOptionLabel.value) : null
              }
              else{
                let id = comp.id.split('@')[0]
                payload[id] = comp.value
              }
            }
            if(comp.datatype == "multioptionset" && (comp.id).includes("FormattedValue")){
              let id = comp.id.split('@')[0]
              comp.value=''
              for(let i of comp.selectedItem){
                comp.value += i.item_id + ','
              }
              payload[id] = comp.value ? comp.value.substring(0,comp.value.length-1) : null
            }

            if(comp.datatype == "coupon"){
              let id = comp.id
              comp.value=''
              for(let i of comp.selectedItem){
                comp.value = i.item_id
              }
              payload[id] = comp.value
            }

            if(comp.id && (comp.id).includes("assignedon") && this.newButton){
              let date = new Date();
             payload[comp.id.split('@')[0]] = ( moment(date).format('MM-DD-YYYY')).toString()
            }

            if(comp.required && (comp.value == "" ||comp.value == null) && comp.value!==0 && comp.visible){
              comp.error = true
              emptyField++
              emptyFieldLabels += comp.label + ','
            }
            if(comp.errorMessage){
              invalidField++
              invalidFieldLables += comp.label + ','
            }
          }
        }
      }
    }
    if(emptyField == 0 && invalidField == 0){
      if(this.saveButtonAction == "create"){
        if(this.ownerId!==''){
          payload[this.ownerId] = this.userId
        }
        if(this.ownerName!==''){
          payload[this.ownerName] = this.userName
        }
        payload[this.createBuField.key] = this.createBuField.value
        payload[this.createStatus.key] = this.createStatus.value
        payload[this.createStatusReason.key] = this.createStatusReason.value
        this.tableOperationService.createTableRecords(this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True'){
            this.toastr.success("Details saved successfully")
            if(this.callDeleteFile){
              this.callDeleteFileService(close)
            }
            else if(this.fileComp){
              this.callSaveFile(res.id,close)
            }
            else if(close){
              this.closeForm()
            }
            else{
              if(this.selectedView){
                this.fetchRecordInEditMode(res.id,this.selectedView.DataApi,this.primaryColumn)
              }
            }
          }
          else{
            this.toastr.error("Failed to save details")
          }
          this.showLoader = false
      })
     }
    else if(this.saveButtonAction == "update"){
      this.commonService.logging(payload)
      this.tableOperationService.updateTableRecords(rowId,this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            if(this.callDeleteFile){
              this.callDeleteFileService(close)
            }
            else if(this.fileComp){
              this.callSaveFile('',close)
            }
            else if(close){
              this.toastr.success("Details updated successfully")
              this.closeForm()
              this.showLoader = false
            }
            else{
              if(this.selectedView){
                this.toastr.success("Details updated successfully")
                this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn,subgriRefresh)
                this.showLoader = false
              }
            }
          }
          else{
            this.toastr.error("Failed to update details")
          }
          this.showLoader = false
        })
      }
    }
    else{
      if(emptyField > 0){
        this.showLoader = false
        emptyFieldLabels.substring(0,emptyFieldLabels.length-1)
        this.toastr.warning(emptyField + " Required fields are missing" + " "+emptyFieldLabels, '', { timeOut: 5000 })
      }
      else if(invalidField > 0){
        this.showLoader = false
        invalidFieldLables.substring(0,invalidFieldLables.length-1)
        this.toastr.warning(invalidField + " Invalid fields are present" + " "+invalidFieldLables, '', { timeOut: 5000 })
      }
    }
  }

  fetchRecordInEditMode(rowId: string,api: string,primary_column:string,subgridRefresh?:boolean,newForm?:boolean,comp?:any){
    let primaryPayload = primary_column+"="+rowId

    let fetchApi = ''
    for(let table of this.allTables){
      if(this.tableId == table.uid){
        fetchApi = table.DataSetApi
      }
    }
    this.detailService.getTableDataById(this.businessId.replace(/-/g,'_'),fetchApi,this.commonService.getAuthToken(),primaryPayload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        if(newForm){
          comp.clickedRowData = res.value[0]
          comp.tableRowClicked = true
          // comp.lookupOpen = true
        }
        else{
          this.rowData = res.value[0]
          this.newButton = false
          this.setFormStatusAndReason()
          this.assignValuesToForm(subgridRefresh)
        }
      }
      else{
        this.showLoader = false
        this.toastr.error('Failed to refresh form')
      }
    })
  }

  setFormButtons(){
    this.visibleButton = []
    this.formButton = []
    this.dropdownButton = []
    if(this.rowData){
      this.saveButtonAction = "update"
    }
    else{
      this.saveButtonAction = "create"
    }

    this.formButton = this.commonService.sortDataBySequence(this.formDetails[0].formRibbon)
    // this.commonService.logging(this.formButton)
    for(let b of this.formButton){
      if(b.hide){
        b.show = false
      }
      else if(b.hideNonUser){
        if(this.rowData && (this.rowData[this.ownerName] !== this.userName)){
          b.show = false
        }
        else{
          b.show = true
        }
      }
      else{
        b.show = true
      }
    }

    if(this.formTemplate == ""){
      this.formButton = this.tableOperationService.removeShareButton(this.formButton)
    }

    if(this.newButton){
      for(let b of this.formButton){
        if(b.actionApi == "Save" || b.actionApi == "SaveClose"){
          b.show = true
        }
        else{
          b.show = false
        }
      }
    }
    if(this.formStatus == 'Inactive'){
      this.checkButtonVisibilityByFormStatus(this.formButton)
    }
    if(this.formButton.length>6){
      this.visibleButton = this.formButton.slice(0,6)
      this.dropdownButton = this.formButton.slice(6)
    }
  }

   assignValuesToForm(subgriRefresh: boolean = true,createNewLookupElementPayload ?: any){
   
    this.setFormButtons()
    this.headerData = []
    for(let i in this.headerFields){
      let primaryField = false, label = ""
      if(i == 'primery_field'){
        primaryField = true
        label = this.tableDisplayName
      }
      this.headerData.push({field : this.headerFields[i], primaryField : primaryField,value: undefined,label: label})
    }

    for(let i of this.formData){
      for(let sec of i.section){
        if(sec.visible){
          sec.grid = sec.grid ? sec.grid : 1
          sec.chunkedArray = this.splitIntoChunks(sec.components.filter((comp: any)=>comp.visible||comp.type=='blank'),sec.grid)
          sec.colSize = 12/sec.grid
          if(sec.grid == 1){
            sec.eachColSize = 12/2
          }
          sec.size = sec.size ? sec.size : 12
        }
        for(let comp of sec.components){
          comp.lock = false
          if(this.formStatus == 'Inactive'){
            comp.lock = true
          }
          comp.value = this.rowData ? this.rowData[comp.id] : null
          comp.tableRowClicked = false
          if(comp.calculated){
            comp.icon = this.theme["form-calculated_field_icon"]
          }
          if(this.newButton){
            if(comp.id == this.ownerName){
              comp.value = this.userName
            }
          }
          if(comp.datatype == "boolean"){
             comp.value = comp.value ? comp.value : false
             this.changeToggleCss(comp)
          }
          if(comp.datatype == "datetime"){
            let data = this.rowData ? this.rowData[(comp.id.split('@'))[0]].split('Z')[0] : null
            comp.value = data
          }
          if(comp.datatype == "file"){
            if(comp.value){
              comp.fileName = this.rowData ? this.rowData[comp.id+"_name"] ? this.rowData[comp.id+"_name"] :  '' : ''
            }
          }
          if(comp.datatype=="lookup"){
            let api = comp.dataapi
            comp.options = []
            comp.amountsList = []
            comp.primary_column = comp.copyPrimaryColumn ? comp.copyPrimaryColumn : comp.primary_column
            let payload = 'top=100'
            this.detailService.getTableData(this.businessId.replace(/-/g,'_'),api,this.commonService.getAuthToken(),payload).subscribe((res)=>{
              this.getAllTableData(this.businessId.replace(/-/g,'_'),api,comp)
              this.setLookUpData(res,comp)
            })
            if(this.rowData){
              comp.selectedOptionLabel = {
                value: this.rowData[comp.relatedid+"@OData.Community.Display.V1.FormattedValue"],
                id : this.rowData[comp.relatedid]
              }
              if(this.lookups){
                this.lookups[comp.label] = comp.selectedOptionLabel
              }
            }
            else{
              comp.selectedOptionLabel = {
                value: undefined,
                id : undefined
              }
              if(this.newButton){
                comp.selectedOptionLabel = Object.keys(this.lookups).length>0 && comp.calculated ? this.lookups[comp.label] : {
                  value: undefined,
                  id : undefined
                }
                if(!comp.selectedOptionLabel){
                  comp.selectedOptionLabel = {
                    value: undefined,
                    id : undefined
                  }
                }
                if(createNewLookupElementPayload){
                  comp.selectedOptionLabel = {
                    value: createNewLookupElementPayload[comp.primary_column],
                    id : createNewLookupElementPayload['id']
                  }
                  if(this.lookups){
                    this.lookups[comp.label] = comp.selectedOptionLabel
                  }
                }
              }
            }
          }
          if(comp.datatype=="decimal"){
            comp.min = 0.00
            comp.value = this.rowData ? Number(this.rowData[comp.id]) : 0
          }
          if(comp.datatype=="amount"){

            let val = this.rowData ? this.rowData[comp.id+"@OData.Community.Display.V1.FormattedValue"] : null
            if(val){
              comp.currency = val.charAt(0)
              let num = this.rowData ? this.rowData[comp.id] : null
              comp.value = (Math.round(num * 100) / 100).toFixed(2);
            }
          }
          if(comp.datatype=="optionset"){
            comp.optionset = comp.optionset.replaceAll('\'','\"')
            let storeOption = JSON.parse(comp.optionset)
            comp.options = [{
              key: "--select--",
              value: null
            }]
            for(let i of storeOption){
              comp.options.push({
                key:i.Text,
                value: i.Value
              })
            }
            if(this.rowData){
              let valueId = (comp.id).split('@')[0]
              if(comp.id == valueId){
               comp.id = comp.oldId ? comp.oldId : comp.id
              }
              comp.selectedOptionLabel = {
                key: this.rowData[comp.id] ? this.rowData[comp.id] : comp.options[0].key ,
                value : this.rowData[valueId] ? this.rowData[valueId] : comp.options[0].value
              }
            }
            else{
              comp.selectedOptionLabel = {
                key: comp.options[0].key,
                value : comp.options[0].value
              }
            }
          }
          if(comp.datatype=="multioptionset"){
            this.formLoaded = true
            comp.multioptionset = comp.multioptionset.replaceAll('\'','\"')
            let storeOption = JSON.parse(comp.multioptionset)
            comp.dropdownSettings = {
              idField: 'item_id',
              textField: 'item_text',
            };
            comp.options = []
            for(let i of storeOption){
              comp.options.push({
                item_text:i.Text,
                item_id: i.Value
              })
            }
            comp.selectedItem = []
            if(this.rowData){
              let valueId = (comp.id).split('@')[0]
              if(comp.id == valueId){
                comp.id = comp.oldId ? comp.oldId : comp.id
              }
              let item_ids = this.rowData[valueId] ? this.rowData[valueId].split(',') : null
              let item_texts = this.rowData[comp.id] ? this.rowData[comp.id].split(';') : null
              if(item_ids){
                for(let i=0;i<item_ids.length;i++){
                  comp.selectedItem.push({
                    item_text : item_texts[i],
                    item_id : Number(item_ids[i])
                  })
                }
              }
            }
            if (!comp.dropDownForm) {
              comp.dropDownForm = this.fb.group({
                myItems: [comp.selectedItem]
              });
            }
          }
          if(comp.datatype=='coupon'){
            debugger;
            this.formLoaded = false
            let val = comp['target_field']
            let label = comp['target_label']
            let storeOption = []
            this.detailService.getTableData(this.businessId.replace(/-/g,'_'),comp.dataapi,this.commonService.getAuthToken(),this.userId).subscribe((res)=>{
              storeOption = res.value
              this.formLoaded = true

              comp.dropdownSettings = {
                idField: 'item_id',
                textField: 'item_text',
                singleSelection: comp.allomutiple ? false : true
              };
              comp.options = []
              for(let i of storeOption){
                comp.options.push({
                  item_text: i[label],
                  item_id: i[label],
                  item_val: i[val]
                })
              }
              comp.selectedItem = []
              if(this.rowData && this.rowData[comp.id]){

                for(let i of comp.options){
                  if(i['item_id'] == this.rowData[comp.id]){
                    comp.selectedItem.push({
                      item_text :  i['item_text'],
                      item_id : i['item_id']
                    })
                  }
                }
              }
              else{
                comp.dropDownForm = undefined
              }
              if (!comp.dropDownForm) {
                comp.dropDownForm = this.fb.group({
                  myItems: [comp.selectedItem]
                });
              }
              this.cdr.detectChanges()
              this.applyMultiSelectCss()
            })
          }
          if(comp.datatype =="date" && this.rowData && this.rowData[comp.id]){
            comp.value = moment(this.rowData[comp.id].substring(0,10)).format('YYYY-MM-DD');
          }
          if(comp.datatype == 'mobile'){
            comp.minLength = "10"
            comp.maxLength = "12"
            if(comp.value){
              let val = (comp.value).split('-')
              let newVal = ""
              for(let g=0;g<val.length;g++){
                newVal += val[g]
              }
              comp.value = Number(newVal)
            }
          }
          if(comp.type == "subgrid"){
            if(subgriRefresh){
              this.getSubgridTableForView(comp)
              if(this.newButton){
                comp.triggeredNewButton = true
                sec.formType = "NewForm"
              }
              else{
                comp.triggeredNewButton = false
                sec.formType = ""
              }
            }
            else{
              this.showLoader = false
            }
          }
          else{
            this.showLoader = false
          }
          for(let h of this.headerData){
            if(h.field == comp.id){
              h.value = comp.value
              if(!h.primaryField){
                h.label = comp.label
              }
              else{
                if(typeof (this.formIcon) == "string"){
                  h.initials = 'data:image/jpg;base64,'+this.formIcon
                  h.icon = 'image'
                }
                else{
                  if(!this.formIcon){
                    if(comp.value){
                      let str = ((comp.value).trim()).toString().split(" ")
                      let initials = ""
                      if(str.length == 1){
                        h.initials = str[0].charAt(0)+(str[0].charAt(1) ? str[0].charAt(1) : str[0].charAt(0))
                      }
                      else{
                        initials =  (str[str.length-1].charAt(0) == '(' || str[str.length-1].charAt(0) == ')') ? str[str.length-2].charAt(0) : str[str.length-1].charAt(0)
                        h.initials = str[0].charAt(0) + initials
                      }
                    }
                    h.icon = 'initials'
                  }
                  else{
                    h.initials = this.tableIcon
                    h.icon = 'image'
                  }
                }
              }
            }
          }
        }
      }
    }
    setTimeout(()=>{this.disableOrEnableFields()},500)
    
  }

  disableOrEnableFields(){
    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.lock){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.disabled = true
              elem.style.backgroundColor = this.theme ? this.theme["form-section_text_disabled_clr"] : ''
            }
          }
          else{
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.disabled = false
              elem.style.backgroundColor = '#fff'
            }
          }
        }
      }
    }
  }

  splitIntoChunks(comps: any[], grid: number): any[][] {
    const chunkedArray: any[][] = [];
    const totalChunks = Math.ceil(comps.length / grid);
  
    for (let i = 0; i < totalChunks; i++) {
      const start = i * grid;
      let end = start + grid;
      let chunk = comps.slice(start, end);
  
      // If the chunk is smaller than the grid size, fill it up with 'Blank'
      while (chunk.length < grid) {
        chunk.push('Blank');
      }
  
      chunkedArray.push(chunk);
    }
    return chunkedArray
  }

  setLookUpData(res : any,comp: any){
    let str = res["@odata.context"]
    comp.amountsList = []
    comp.options = []
    comp.lookupTableName = str.substring(
      str.indexOf("#") + 1,
      str.indexOf("(")
    );
    for(let tab of this.allTables){
      if(tab.uid == comp.tableid){
        comp.lookUpTablePrimaryColumn = tab["Primary_Column"]
        comp.formGeneration = this.commonService.getLookupFormFirst(tab.forms)
      }
    }
    if(res && res.value && res.value.length>0){
      comp.allTableData = res.value
      for(let i of res.value){
        comp.options.push({value: i[comp.primary_column],id: i[comp.lookUpTablePrimaryColumn],hint: i[comp.hint_column]})
        comp.amountsList.push({value:i[comp.primary_column],amount:i["eng_amount"]})
      }
    }
    comp.savedOptions = JSON.parse(JSON.stringify(comp.options))

    comp.selectedId = this.rowData ? this.rowData[comp.relatedid] : ''
    comp.icon = comp.calculated ? this.theme["form-calculated_field_icon"] : this.theme["form-lookup_field_icon"]
    this.storeAllLookUps.push(comp)
  }

  getAllTableData(app_name : string,api : string,comp: any){
    let payload = this.userId

    this.detailService.getTableData(app_name,api,this.commonService.getAuthToken(),payload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        this.setLookUpData(res,comp)
      }
    })
  }

  newPageLookUpData(comp : any){
    if(comp.formGeneration && comp.formGeneration.length>0){

      let data;
      if(comp.formGeneration[0].Type  == 'Quick Create' || comp.formGeneration[0].Type == 'Quick Add'){
        data = [JSON.parse(comp.formGeneration[0].Layout)]
      }
      else{
        data = JSON.parse(comp.formGeneration[0].Layout).tab
      }
      let allLookUpComp = []
      for(let i of data){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.datatype == "lookup"){
              allLookUpComp.push(comp)
            }
          }
        }
      }
      for(let i of allLookUpComp){
        if(!this.lookups[i.label]){
          this.lookups[i.label] = {
            value: this.rowData ? this.rowData[i.primary_column] : undefined,
            id : this.rowData ? this.rowData[this.primaryColumn] : undefined
          }
        }
      }
    }
  }

  performTableEvent(button : any, innerComp ?:  any){
    if(button.actionApi == 'New'){
      this.newLookup = false
     this.closeQuickCreate({clicked:false,close:true},innerComp)
      innerComp.tableNewClicked = true
      innerComp.tableRowClicked = true
      innerComp.triggeredNewButton = true
      innerComp.clickedRowData = undefined
      this.newPageLookUpData(innerComp)
      innerComp["storeLookups"] = this.lookups
      if(innerComp && innerComp.formGeneration && innerComp.formGeneration.length>0){
        innerComp.formGeneration = this.commonService.getDefaultFormFirst(innerComp.formGeneration)
        if(innerComp.formGeneration[0].Type == 'Quick Create'){
          this.showQuickCreateForm = true
          this.clickedComp = innerComp
        }
        else if(innerComp.formGeneration[0].Type == 'Quick Add'){
          this.showQuickAddForm = true
          this.clickedComp = innerComp
        }
        else{
          this.showQuickCreateForm = false
          this.showQuickAddForm = false
          this.passObject(innerComp)
        }
      }
      // this.passObject(innerComp)

      // let elem = <HTMLElement>document.getElementsByClassName('form-top')[0]
      // if(elem){
      //   elem.style.zIndex = "1"
      // }

      // let elem2 = <HTMLElement>document.getElementsByClassName('mat-tab-header')[0]
      // if(elem2){
      //   elem2.style.zIndex = "1"
      // }
    }
    else if(button.actionApi == 'Edit'){
      this.newLookup = false
      innerComp.storeLookups = this.lookups
      this.closeQuickCreate({clicked:false,close:true},innerComp)
      innerComp.tableRowClicked = true
      innerComp.clickedRowData =  innerComp.checkedRows.length>0 ? innerComp.checkedRows[0] : []
      this.callDateCoversion(innerComp)
      if(innerComp && innerComp.formGeneration && innerComp.formGeneration.length>0){
        innerComp.formGeneration = this.commonService.getDefaultFormFirst(innerComp.formGeneration)
        if(innerComp.formGeneration[0].Type == 'Quick Create'){
          this.showQuickCreateForm = true
          this.clickedComp = innerComp
        }
        else if(innerComp.formGeneration[0].Type == 'Quick Add'){
          this.showQuickAddForm = true
          this.clickedComp = innerComp
        }
        else{
          this.showQuickAddForm = false
          this.showQuickCreateForm = false
          this.passObject(innerComp)
        }
      }
    }
    else if(button.actionApi == 'Delete'){
      let rowId = this.rowData[this.primaryColumn]
      this.openDialog(rowId,true,innerComp)
    }
    else if(button.actionApi == 'Active'){
      let rowId = this.rowData[this.primaryColumn]
      this.openDialogForActiveInactive(rowId,'Are you sure you want to activate?','Active',true,innerComp)
    }
    else if(button.actionApi == 'Inactive'){
      let rowId = this.rowData[this.primaryColumn]
      this.openDialogForActiveInactive(rowId,'Are you sure you want to inactivate?','Inactive',true,innerComp)
    }
    else if(button.actionApi == 'Assign'){
      let rowId = this.rowData[this.primaryColumn]
      let info = localStorage.getItem("primaryUserInfo")
      let primarInfo = ""
      if(info){
        primarInfo = JSON.parse(info)
      }
      this.openAssignDialog(primarInfo,rowId,true,innerComp)
    }
    else if(button.actionApi == 'Refresh'){
      this.getSubgridTableForView(innerComp, true)
    }
    else if(button.actionApi == 'ExportCSV'){
      // this.showLoader = true
      this.downloadCsv(innerComp)
    }
  }

  downloadCsv(comp :any){
    let columnHeader: any[] = []
    let displayCols = []
    for(const i of comp.layout){
      columnHeader.push(i.id)
      displayCols.push(i.label)
    }
    let  data = JSON.parse(JSON.stringify(comp.checkedRows))
    for(let i of data){
      for(let j in i){
        if( typeof(i[j]) == "string" && i[j].includes('₹')){
          i[j] = Number(i[j].replace(/[^0-9\.-]+/g,""));
        }
      }
    }
    const replacer = (key : any, value : any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = displayCols;
    let csv = data.map((row :any) => 
      columnHeader.map((fieldName : any) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, comp.selectedView.Name+".csv");
  }

  callExportCSV(comp: any){
    let allCheckedId = ''
    for(let row of comp.checkedRows){
      allCheckedId += row[comp.primary_column] + ','
    }
    allCheckedId = allCheckedId.substring(0,allCheckedId.length-1)
    let id = comp.tableTableLogicalName+','+comp.primary_column+','+comp.selectedView.Name
    this.tableOperationService.exportTableRecord(id,comp.selectedView.Layout,allCheckedId,this.commonService.getAuthToken()).subscribe((res)=>{
      if(res.base64){
        let elem = 'data:application/octet-stream;base64,' + res.base64
        const downloadLink = document.createElement('a');
        downloadLink.href = elem;
        downloadLink.download = res.filename ? res.filename : 'sample.csv';
        downloadLink.click();
      }
      this.showLoader = false
    })
  }

  openShareDialog(primaryInfo : any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Share',
        cancel: 'Cancel'
      },
      buttonColor: this.form_section_header_back_clr
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {
        this.showLoader = true
        let whatsapp = "", email = "", to_email = "", to_mobile = ""

        if(selectedContact.isEmailChecked && selectedContact.email){
          email = "true"
          to_email = selectedContact.email
        }

        if(selectedContact.isMobileChecked && selectedContact.mobile){
          whatsapp = "true"
          to_mobile = selectedContact.mobile
        }

        let data_id = ""
        if(this.myForm){
          for(let i of this.myForm){
            if(i.id == this.primaryColumn){
              data_id = i.value
            }
          }
        }

        let payload = {
          "loggedin_user_guid": this.userId,
          "whatsapp": whatsapp,
          "email": email,
          "to_mobile_no": to_mobile,
          "to_email": to_email,
          "table_id": this.tableId,
          "data_ids": data_id,
          "type": "form"
        }

        this.commonService.logging(payload)

        this.tableOperationService.shareTableRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Record shared successfully")
          }
          else{
            this.toastr.error("Failed to share record")
          }
          this.showLoader = false
        });
      })
  }

  openAssignDialog(primaryInfo : any, rowId : string, subgridButton?: boolean,comp?: any ) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'
    dialogConfig.height = '320px'

    dialogConfig.data = {
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Assign',
        cancel: 'Cancel'
      },
      buttonColor: this.form_section_header_back_clr
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {

      if(selectedContact && selectedContact !==""){
        let payload : {[key: string]: string} = {}

        payload[this.ownerId] = selectedContact.userId
        payload[this.ownerName] = selectedContact.label
        let date = new Date();
        // payload['eng_assignedon'] = ( moment(date).format('MM-DD-YYYY')).toString()

        this.showLoader = true
        if(subgridButton){
          let i=0;
          for(i=0;i<comp.checkedRows.length;i++){
            this.tableOperationService.assignTableRecord(payload,comp.checkedRows[i][comp.primary_column],comp.dbTableName,this.commonService.getAuthToken()).subscribe((res)=>{
              if(res && res.status && res.status == 'True' ){
                this.toastr.success("Record assigned successfully")
              }
              else{
                this.toastr.error("Failed to assign record")
              }
            });
          }
          if(i==comp.checkedRows.length){
            setTimeout(()=>{
              this.getSubgridTableForView(comp,true)
              if(comp.refresh){
                this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn,)
              }
            },2000);
            }
        }
        else{
          this.tableOperationService.assignTableRecord(payload,rowId,this.dbTableName,this.commonService.getAuthToken()).subscribe((res)=>{
            if(res && res.status && res.status == 'True' ){
              this.toastr.success("Record assigned successfully")
              for(let i = 0;i<this.headerData.length;i++){
                if(this.headerData[i].field == this.ownerName){
                  this.headerData[i].value = payload[this.ownerName]
                }
              }
              this.userId = payload[this.ownerId]
              this.userName = payload[this.ownerName]
              for(let i of this.formData){
                for(let sec of i.section){
                  for(let comp of sec.components){
                    if(comp.id == this.ownerId){
                      comp.value = payload[this.ownerId]
                    }
                    if(comp.id == this.ownerName){
                      comp.value = payload[this.ownerName]
                    }
                  }
                }
              }
            }
            else{
              this.toastr.error("Failed to assign record")
            }
            this.showLoader = false
          });
          }
        }

      })
  }

  openDialog(rowId : string,subgridButton ?: boolean,comp?:any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.form_section_header_back_clr
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.callDeleteService(rowId,subgridButton,comp)
      }
    });
  }

  openConfirmationDialogWithConfirmation(rowId : string,config: any,button:any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: config.confirmation_msg,
      buttonText: {
        ok: config.success_button,
        cancel: config.Cancel_button
      },
      buttonColor: this.form_section_header_back_clr
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if(confirmed){
        this.callRibbonAction(rowId,button,config.refresh_form_on_success)
      }
      else{
        this.showLoader = false
      }
    });
  }

  openConfirmationDialog(rowId : string,res:any,refreshNeeded: boolean) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: res.result,
      buttonText: {
        ok: 'OK'
      },
      buttonColor: this.form_section_header_back_clr
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)
    });
  }

  openDialogForActiveInactive(rowId : string,message : string,buttonAction: string,subgridButton ?: boolean,comp?:any) {
    let myTable : {[key: string]: string} = {}
    if(subgridButton){
      for(let table of this.allTables){
        if(table.uid == comp.tableId){
           myTable = table
         }
       }
    }
    else{
      for(let table of this.allTables){
        if(table.uid == this.tableId){
         myTable = table
       }
     }
    }
    let statusOption = JSON.parse(myTable.StatusOptions)
    let setStatus: string , setStatusOption: any, setStatusValue: string, reverseStatus: string
    for(let status of statusOption){
      if((status.Text).toLowerCase() == buttonAction.toLowerCase()){
        setStatus = status.Value
      }
    }
    if(buttonAction.toLowerCase() == 'active'){
      setStatusOption = JSON.parse(myTable.ActiveStatusOptions)
      setStatusValue = myTable.ActiveStatus
      reverseStatus = myTable.InActiveStatus
    }
    else{
      setStatusOption = JSON.parse(myTable.InActiveStatusOptions)
      setStatusValue = myTable.InActiveStatus
      reverseStatus = myTable.ActiveStatus
    }
    let payload: {[key: string]: string|null} = {}
   
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: message,
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.form_section_header_back_clr,
      dataInfo: setStatusOption
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        payload[myTable.Status] = setStatus
        payload[setStatusValue] = confirmed
        payload[reverseStatus] = null
        this.callUpdateService(rowId,buttonAction,payload,subgridButton,comp)
      }
    });
  }

  callDeleteService(rowId: string,subgridButton?: boolean,comp?: any){
    this.showLoader = true
    if(subgridButton){
      let i = 0;
      for(i=0;i<comp.checkedRows.length;i++){

        this.tableOperationService.deleteTableRecord(comp.checkedRows[i][comp.primary_column],this.commonService.getAuthToken(),comp.dbTableName).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Record deleted successfully")
          }
          else{
            this.toastr.error("Failed to delete record")
          }
        })
      }
      if(i==comp.checkedRows.length){
        setTimeout(()=>{
          this.getSubgridTableForView(comp,true)
          if(comp.refresh){
            this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn,)
          }
        },2000);
         
      }
    }
    else{
      this.tableOperationService.deleteTableRecord(rowId,this.commonService.getAuthToken(),this.dbTableName).subscribe((res)=>{
        if(res && res.status && res.status == 'True' ){
          this.toastr.success("Record deleted successfully")
          this.closeForm()
        }
        else{
          this.toastr.error("Failed to delete record")
        }
        this.showLoader = false
      })
    }
  }

  callUpdateService(rowId :  string,buttonAction: string,payload: any,subgridButton?: boolean,comp?: any){
    this.showLoader = true
    if(subgridButton){
      let i = 0;
     
  
      for(i=0;i<comp.checkedRows.length;i++){
        this.tableOperationService.updateTableRecords(comp.checkedRows[i][comp.primary_column],this.commonService.getAuthToken(),comp.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            buttonAction.toLowerCase() == 'active' ? this.toastr.success("Record activated successfully") : this.toastr.success("Record inactivated successfully")
            this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)  
          }
          else{
            buttonAction.toLowerCase() == 'active' ?  this.toastr.error("Failed to activate record") :  this.toastr.error("Failed to inactivate record")
          }
        })
      }
      if(i==comp.checkedRows.length){
        setTimeout(()=>{
          this.getSubgridTableForView(comp,true)
          if(comp.refresh){
            this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)
          }
        },2000);
      }
    }
    else{
      this.tableOperationService.updateTableRecords(rowId,this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
        if(res && res.status && res.status == 'True' ){
          buttonAction.toLowerCase() == 'active' ? this.toastr.success("Record activated successfully") : this.toastr.success("Record inactivated successfully")
          this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn)
        }
        else{
          buttonAction.toLowerCase() == 'active' ?  this.toastr.error("Failed to activate record") :  this.toastr.error("Failed to inactivate record")
        }
        this.showLoader = false
      })
    }
   
   
   
    // let myTable
    // for(let table of this.tableData){
    //   if(table.uid == this.tableId){
    //     myTable = table
    //   }
    // }
    // let statusOption = JSON.parse(myTable.StatusOptions)
    // let setStatus 
    // for(let status of statusOption){
    //   if((status.Text).toLowerCase() == buttonAction.toLowerCase()){
    //     setStatus = status.Value
    //   }
    // }
    // let payload: {[key: string]: string} = {}
    // payload[myTable.Status] = setStatus
    // this.tableOperationService.updateTableRecords(rowId,this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
    //   if(res && res.status && res.status == 'True' ){
    //     buttonAction.toLowerCase() == 'active' ? this.toastr.success("Record activated successfully") : this.toastr.success("Record inactivated successfully")
    //   }
    //   else{
    //     buttonAction.toLowerCase() == 'active' ?  this.toastr.error("Failed to activate record") :  this.toastr.error("Failed to inactivate record")
    //   }
    // })
  }

  callRibbonAction(rowId:string,button: any,refreshNeeded: boolean){
    this.tableOperationService.ribbonActionOnRecord(this.commonService.getAuthToken(),this.tableLogicalName,rowId,button.uid).subscribe((res)=>{
      this.openConfirmationDialog(rowId,res,refreshNeeded) 
      this.showLoader = false
    })
  }

  validateAmount(comp : any){
    comp.error  = false
    comp.errorMessage = undefined
    let myValue = comp.value
    comp.value = (Math.round(myValue * 100) / 100).toFixed(2);
    if(myValue  < 0){
      comp.error  = true
      comp.errorMessage = "Amount cannot be negative"
    }
  }

  closeTableModal(){
    let modal = document.getElementById("myTableModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementById("app-container");
    if (container) {
      container.classList.remove("blur");
    }
  }

  passObject(comp : any){
    this.windowOpen++;
    localStorage.setItem('comp'+this.windowOpen, JSON.stringify(comp));
    this.router.navigate([]).then(result => {  window.open(`/showDetails/${this.windowOpen}/comp`, '_blank'); });
  }
  onItemSelect(comp: any,item: any){
    if(!comp.allomutiple){
      comp.selectedItem = []
    }
    comp.selectedItem.push(item)
    this.valuechange(item,comp)
    this.applyMultiSelectCss()
  }
  onSelectAll(comp:any,items: any){
    comp.selectedItem = items
    this.applyMultiSelectCss()
  }
  onItemDeSelect(comp:any,item: any){
    for(let i=0;i< comp.selectedItem.length;i++){
      if(comp.selectedItem[i].item_id == item.item_id){
        comp.selectedItem.splice(i,1)
      }
    }
    this.valuechange(item,comp)
    this.applyMultiSelectCss()
  }
  onUnSelectAll(comp: any){
    this.applyMultiSelectCss()
    comp.selectedItem = []
  }
  callMethod(e: any, comp: any){
    this.applyMultiSelectCss()
    // this.valuechange(e,comp)
  }
  compareFn(a: any,b: any){
    return a && b && a.key == b.key;
  }
  compareFnLookup(a : any, b: any){
    return a && b && a.value == b.value;
  }
  valuechange(e :  any,comp ?: any){
    let val = this.formCaptureSerive.onFieldChange(this.tableId,this.formDetails[0].uid, e,comp,this.formData)
    if(comp.refresh){
      this.saveFormData(false)
      // this.formCaptureSerive.onSave(this.tableId,this.formDetails[0].uid)
    }
    // if(val && val == 'reset'){
    //   this.formLoaded = false
    //   for(let i of this.formData){
    //     for(let sec of i.section){
    //       for(let com of sec.components){
    //         if(com.id && com.id == "new_couponcode"){
    //           com.selectedItem = []
    //           com.dropDownForm = this.fb.group({
    //             myItems: [com.selectedItem]
    //           });
    //           this.formLoaded = true
    //         }
    //       }
    //     }
    //   }
    // }
  }
  validatePhoneNumber(e: any, comp?: any){
    if(comp.value){
      let elem = (comp.value).toString()
      if(elem.length< comp.minLength){
        comp.errorMessage = "Minimum 10 digits allowed"
      }
      else if(elem.length>comp.maxLength){
        elem = elem.slice(0,comp.maxLength)
        comp.value = Number(elem)
        e.target.value = comp.value
      }
      else{
        comp.errorMessage = ''
      }
    }
  }
}
