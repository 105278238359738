import { Injectable } from '@angular/core';
import { CourseFormCaptureService } from './course-form-capture.service';
import { EnrolledCourseFormCaptureService } from './enrolled-course-form-capture.service';
import { EnrollmentFormCaptureService } from './enrollment-form-capture.service';
import { LeadsFormCaptureService } from './leads-form-capture.service';
import { PointOfContactsFormCaptureService } from './point-of-contacts-form-capture.service';
import { SchoolFormCaptureService } from './school-form-capture.service';
import { StudentFormCaptureService } from './student-form-capture.service';
import { TaskFormCaptureService } from './task-form-capture.service';
import { TreatmentFormCaptureService } from './treatment-form-capture.service';
import { WorkitemFormCaptureService } from './workitem-form-capture.service';
import { AttendanceFormCaptureService } from './attendance-form-capture.service';

@Injectable({
  providedIn: 'root'
})
export class FormCaptureService {

  treatmentTableId = ['AT-TBL-1089:AT-APPD-1009:AT-BU-1000','AT-TBL-1119:AT-APPD-1031:AT-BU-1007']
  treatmentFormId = [
    'AT-FORM-1090:AT-TBL-1089:AT-APPD-1009:AT-BU-1000', 
    'AT-FORM-1095:AT-TBL-1089:AT-APPD-1009:AT-BU-1000',
    'AT-FORM-1140:AT-TBL-1119:AT-APPD-1031:AT-BU-1007']

  workItemTableId =['AT-TBL-1090:AT-APPD-1009:AT-BU-1000' , 'AT-TBL-1121:AT-APPD-1031:AT-BU-1007']
  workItemFormId = [
    'AT-FORM-1094:AT-TBL-1090:AT-APPD-1009:AT-BU-1000',
    'AT-FORM-1091:AT-TBL-1090:AT-APPD-1009:AT-BU-1000',
    'AT-FORM-1144:AT-TBL-1121:AT-APPD-1031:AT-BU-1007',
    'AT-FORM-1143:AT-TBL-1121:AT-APPD-1031:AT-BU-1007',
    'AT-FORM-1136:AT-TBL-1090:AT-APPD-1009:AT-BU-1000']
                 
  constructor(
    private schoolService : SchoolFormCaptureService,
    private studentService : StudentFormCaptureService,
    private leadService : LeadsFormCaptureService,
    private taskService: TaskFormCaptureService,
    private enrollmentService : EnrollmentFormCaptureService,
    private enrolledCourseService : EnrolledCourseFormCaptureService,
    private courseService : CourseFormCaptureService,
    private pointOfContactService : PointOfContactsFormCaptureService,
    private treatmentService : TreatmentFormCaptureService,
    private workitemService :  WorkitemFormCaptureService,
    private attendanceService : AttendanceFormCaptureService
  ) { }

  onLoad(tableId: string,formId: string,formData?: any){
    if(tableId == 'Schools:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_School:Schools:Students_Management_System:AT-BU-1002'){
        this.schoolService.onLoad()
      }
    }
    else if(tableId == 'Students:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Students:Students:Students_Management_System:AT-BU-1002'){
        this.studentService.onLoad(formData)
      }
    }
    else if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002'  || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        this.leadService.onLoad(formData)
      }
    }
    else if(tableId == 'Tasks:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Task:Tasks:Students_Management_System:AT-BU-1002'){
        this.taskService.onLoad()
      }
    }
    else if(tableId == 'Enrollments:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrollment:Enrollments:Students_Management_System:AT-BU-1002'){
       this.enrollmentService.onLoad()
      }
    }
    else if(tableId == 'Enrolled_Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrolled_Courses:Enrolled_Courses:Students_Management_System:AT-BU-1002'|| formId == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
        this.enrolledCourseService.onLoad(formData)
      }
    }
    else if(tableId == 'Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Courses:Courses:Students_Management_System:AT-BU-1002'){
        this.courseService.onLoad()
      }
    }
    else if(tableId == 'Point_of_Contact:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Point_of_Contact:Point_of_Contact:Students_Management_System:AT-BU-1002'){
        this.pointOfContactService.onLoad()
      }
    }
    else if(this.treatmentTableId.includes(tableId)){
      if(this.treatmentFormId.includes(formId)){
        return this.treatmentService.onLoad(formData)
      }
    }
    else if(this.workItemTableId.includes(tableId)){
      if(this.workItemFormId.includes(formId)){
       this.workitemService.onLoad(formData)
      }
    }
    else if(tableId == 'AT-TBL-1093:AT-APPD-1009:AT-BU-1000'){
      if(formId == 'AT-FORM-1108:AT-TBL-1093:AT-APPD-1009:AT-BU-1000'){
        this.attendanceService.onLoad(formData)
      }
    }
  }

  onSave(tableId: string,formId: string){
    if(tableId == 'Schools:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_School:Schools:Students_Management_System:AT-BU-1002'){
        this.schoolService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Students:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Students:Students:Students_Management_System:AT-BU-1002'){
        this.studentService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002' || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        this.leadService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Tasks:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Task:Tasks:Students_Management_System:AT-BU-1002'){
        this.taskService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Enrollments:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrollment:Enrollments:Students_Management_System:AT-BU-1002'){
        this.enrollmentService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Enrolled_Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrolled_Courses:Enrolled_Courses:Students_Management_System:AT-BU-1002' || formId == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
        this.enrolledCourseService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Courses:Courses:Students_Management_System:AT-BU-1002'){
        this.courseService.onSave(tableId,formId)
      }
    }
    else if(tableId == 'Point_of_Contact:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Point_of_Contact:Point_of_Contact:Students_Management_System:AT-BU-1002'){
        this.pointOfContactService.onSave(tableId,formId)
      }
    }
  }

  onFieldChange(tableId: string,formId: string, e : any, comp?:any,formData?: any){
    if(tableId == 'Schools:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_School:Schools:Students_Management_System:AT-BU-1002'){
        this.schoolService.onFieldChange(tableId,formId,comp,formData)
      }
    }
    else if(tableId == 'Students:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Students:Students:Students_Management_System:AT-BU-1002'){
        this.studentService.onFieldChange(tableId,formId,formData)
      }
    }
    else if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002' || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        this.leadService.onFieldChange(tableId,formId,formData)
      }
    }
    else if(tableId == 'Tasks:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Task:Tasks:Students_Management_System:AT-BU-1002'){
        this.taskService.onFieldChange(tableId,formId)
      }
    }
    else if(tableId == 'Enrollments:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrollment:Enrollments:Students_Management_System:AT-BU-1002'){
        this.enrollmentService.onFieldChange(tableId,formId)
      }
    }
    else if(tableId == 'Enrolled_Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrolled_Courses:Enrolled_Courses:Students_Management_System:AT-BU-1002' || formId == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
        this.enrolledCourseService.onFieldChange(tableId,formId,formData,comp)
      }
    }
    else if(tableId == 'Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Courses:Courses:Students_Management_System:AT-BU-1002'){
        this.courseService.onFieldChange(tableId,formId,formData)
      }
    }
    else if(tableId == 'Point_of_Contact:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Point_of_Contact:Point_of_Contact:Students_Management_System:AT-BU-1002'){
        this.pointOfContactService.onFieldChange(tableId,formId)
      }
    }
    else if(this.workItemTableId.includes(tableId)){
      if(this.workItemFormId.includes(formId)){
        this.workitemService.onFieldChange(tableId, formId,formData,comp)
      }
    }
    else if(this.treatmentTableId.includes(tableId)){
      if(this.treatmentFormId.includes(formId)){
        return this.treatmentService.onFieldChange(tableId, formId,formData,comp)
      }
    }
    return 
  }

  onSubgridRefresh(tableId: string,formId: string,subgrid?: any, formData?: any,headerData?: any,rowData?: any){
    if(tableId == 'Schools:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_School:Schools:Students_Management_System:AT-BU-1002'){
        return this.schoolService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(tableId == 'Students:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Students:Students:Students_Management_System:AT-BU-1002'){
        return this.studentService.onSubgridRefresh(tableId,formId,subgrid,formData,headerData)
      }
    }
    else if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002' || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        return this.leadService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(tableId == 'Tasks:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Task:Tasks:Students_Management_System:AT-BU-1002'){
        return this.taskService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(tableId == 'Enrollments:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrollment:Enrollments:Students_Management_System:AT-BU-1002'){
        return this.enrollmentService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(tableId == 'Enrolled_Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Enrolled_Courses:Enrolled_Courses:Students_Management_System:AT-BU-1002' || formId == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
        return this.enrolledCourseService.onSubgridRefresh(tableId,formId,subgrid,formData,headerData)
      }
    }
    else if(tableId == 'Courses:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Courses:Courses:Students_Management_System:AT-BU-1002'){
        return this.courseService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(tableId == 'Point_of_Contact:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Point_of_Contact:Point_of_Contact:Students_Management_System:AT-BU-1002'){
        return this.pointOfContactService.onSubgridRefresh(tableId,formId)
      }
    }
    else if(this.workItemTableId.includes(tableId)){
      if(this.workItemFormId.includes(formId)){   
        return this.workitemService.onSubgridRefresh(formData,subgrid,headerData,rowData)
      }
    }
  }

  setFormData(tableId: string,formId: string,formData?: any,headerData?: any){
    if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002' || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        this.leadService.setFormData(tableId,formId,formData,headerData)
      }
    }
  }
  createTableInsights(tableId: string,formId: string,rowData?: any){
    // if(tableId == 'Schools:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_School:Schools:Students_Management_System:AT-BU-1002'){
    //     this.schoolService.onSubgridRefresh(tableId,formId)
    //   }
    // }
    // else if(tableId == 'Students:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Students:Students:Students_Management_System:AT-BU-1002'){
    //     this.studentService.onSubgridRefresh(tableId,formId,subgrid,formData,headerData)
    //   }
    // }
    if(tableId == 'Leads:Students_Management_System:AT-BU-1002'){
      if(formId == 'Add_Lead:Leads:Students_Management_System:AT-BU-1002' || formId=='Lead_Details:Leads:Students_Management_System:AT-BU-1002'){
        this.leadService.createTableInsights(tableId,formId,rowData)
      }
    }
    // else if(tableId == 'Tasks:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Task:Tasks:Students_Management_System:AT-BU-1002'){
    //     this.taskService.onSubgridRefresh(tableId,formId)
    //   }
    // }
    // else if(tableId == 'Enrollments:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Enrollment:Enrollments:Students_Management_System:AT-BU-1002'){
    //     this.enrollmentService.onSubgridRefresh(tableId,formId)
    //   }
    // }
    // else if(tableId == 'Enrolled_Courses:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Enrolled_Courses:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
    //     this.enrolledCourseService.onSubgridRefresh(tableId,formId,subgrid,formData,headerData)
    //   }
    // }
    // else if(tableId == 'Courses:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Courses:Courses:Students_Management_System:AT-BU-1002'){
    //     this.courseService.onSubgridRefresh(tableId,formId)
    //   }
    // }
    // else if(tableId == 'Point_of_Contact:Students_Management_System:AT-BU-1002'){
    //   if(formId == 'Add_Point_of_Contact:Point_of_Contact:Students_Management_System:AT-BU-1002'){
    //     this.pointOfContactService.onSubgridRefresh(tableId,formId)
    //   }
    // }
  }

  openNewForm(tableId: string,formId: string,formData?: any){
    if(this.treatmentTableId.includes(tableId)){
      if(this.treatmentFormId.includes(formId)){
        this.treatmentService.openNewForm(tableId, formId,formData)
      }
    }
  }

}
