<div class="allButtons" *ngIf="formButton && formButton.length>0" [ngClass]="{
    'display-allButtons' : formButton.length>6
  }">
    <div  *ngFor="let b of formButton">
      <div class="headButton" *ngIf="b.show" (click)="performFormEvent(b)">
        <span ><img *ngIf="b.Icon!=''" class="button-icon" [src]="b.Icon">{{b.Label}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="visibleButton.length>0" class="display-buttonDropdown">
    <div class="allButtons">
      <div class="headButton" *ngFor="let b of visibleButton" (click)="performFormEvent(b)">
        <span *ngIf="b.show"><img *ngIf="b.Icon!=''" class="button-icon" [src]="b.Icon">{{b.Label}}</span>
      </div>
    </div>
    <div class="dropdown" dropdown>
      <div class="headButton" style="padding: 2px;" data-toggle="dropdown" dropdownToggle>
        <img src="../../../assets/Images/dotMenu.png" class="dotMenu" >
      </div>
      <div class="dropdown-menu" style="margin-top: 15px;margin-left:-100px">
          <div *ngFor="let item of dropdownButton" class="dropdown-item" style="padding:10px 13px;font-size:14px" (click)="performFormEvent(item)">
            <span *ngIf="item.show"><img *ngIf="item.Icon!=''" class="button-icon" [src]="item.Icon">{{item.Label}}</span>
          </div>
      </div>
    </div>

  </div>
