import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import * as moment from 'moment';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TreatmentFormCaptureService {

  constructor(private commonService: CommonService,private fb: FormBuilder) {
  }

  onLoad(formData : any){
   this.commonService.logging("-- Treatment Form Loaded --")
  //  this.calculateTotalAmount(formData)
   return this.setDefaultDiscountValue(formData)
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Treatment')
  }

  onFieldChange(tableId : string, formId : string,formData: any,component: any){
    this.commonService.logging('On field Change for Treatment')
    return
    // return this.calculateTotalAmount(formData,component)
  }

  onSubgridRefresh(formData: any, comp: any, headerData: any, rowData: any){
    this.commonService.logging('On Subgrid Refresh for Treatment')
    return false
  }

  openNewForm(tableId : string, formId : string,formData : any){
    this.setCurrentDate(tableId, formId,formData)
  }

  setDefaultDiscountValue(formData :  any){
    let payload : {[key: string]: number} = {}
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id && comp.id.includes("new_discount")){
            comp.value = comp.value ? comp.value : 0
            comp.minValue = 0
            comp.maxValue = 100
          }
          if(comp.id && comp.id.includes("new_tax")){
            comp.value = comp.value ? comp.value : 0
          }
        }
      }
    }
  }

  setCurrentDate(tableId : string, formId : string,formData : any){
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id){
            if(comp.id.includes("cr400_treatmentdate")){
              comp.value = moment(new Date()).format('YYYY-MM-DD')
            }
            if(comp.id.includes("new_tax")){
              comp.value = 0
            }
            if(comp.id.includes("new_discount")){
              comp.value = 0
            }
          }
        }
      }
    }
  }

  calculateTotalAmount(formData : any,component ?: any){
    let discount = 0
    let totalAmount = 0 
    let taxPercent = 0
    let couponPercent = 0
    let rupeeSymbol = ''
    let discountAmount = 0
    if(component && component.id && component.id == "new_discountedamount"){
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id && comp.id == "new_discountedamount"){
              comp.value = Number(component.value).toFixed(2)
              discountAmount = comp.value
            }
            if(comp.id && comp.id == 'new_tax'){
              taxPercent = comp.value
            }
            if(comp.id && comp.id == 'cr400_totalamount'){
              totalAmount = comp.value
            }
          }
        }
      }
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            let totalTaxedAmount = Number(totalAmount) + ((Number(totalAmount) * Number(taxPercent)) / 100)
            if(comp.id && comp.id == 'new_finalamount'){
              comp.value = totalTaxedAmount - discountAmount
            }
            if(comp.id && comp.id == 'new_finalamount@OData.Community.Display.V1.FormattedValue'){
              comp.value = rupeeSymbol + Number(totalTaxedAmount - discountAmount)
            }
            if(comp.id && comp.id == 'cr400_totalamount'){
              comp.value = totalTaxedAmount
            }
            if(comp.id && comp.id == 'new_discount'){
              comp.value = 0
            }
            
            if(comp.id && comp.id == "new_couponcode"){
              comp.selectedItem = []
            }
          }
        }
      }
      return 'reset'
    }
    else{
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id && comp.id.includes('cr400_totalamount@OData.Community.Display.V1.FormattedValue')){
              rupeeSymbol = comp.value ? comp.value.charAt(0) : ''
            }
            if(comp.id && comp.id == 'cr400_totalamount'){
              totalAmount = comp.value
            }
            if(comp.type == 'subgrid' && comp.tableid == 'AT-TBL-1121:AT-APPD-1031:AT-BU-1007' &&  comp.tableData &&  comp.tableData.length>0){
              let price  = 0
              for(const row of comp.tableData){
                price += row['cr400_price']
              }
              totalAmount = price
            }
            if(comp.id && comp.id == 'new_discount'){
              discount = comp.value
            }
            if(comp.id && comp.id == 'new_tax'){
              taxPercent = comp.value
            }
            if(comp.id && comp.id == "new_couponcode"){
              if(comp.selectedItem && comp.selectedItem.length>0){
                let val = comp.selectedItem[0].item_text
                for(let i of comp.options){
                  if(i.item_text == val && i.item_val){
                    couponPercent = i.item_val
                  }
                }
              }
            }
          }
        }
      }
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id && comp.id == 'new_finalamount'){
              let totalTaxedAmount = Number(totalAmount) + ((Number(totalAmount) * Number(taxPercent)) / 100)
              let totalDiscountPercent = Number(discount) + Number(couponPercent)
              comp.value = Number(totalTaxedAmount) - ((Number(totalTaxedAmount) * Number(totalDiscountPercent)) / 100)
            }
            if(comp.id && comp.id == 'new_finalamount@OData.Community.Display.V1.FormattedValue'){
              let totalTaxedAmount = Number(totalAmount) + ((Number(totalAmount) * Number(taxPercent)) / 100)
              let totalDiscountPercent = Number(discount) + Number(couponPercent)
              comp.value = rupeeSymbol + (Number(totalTaxedAmount) - ((Number(totalTaxedAmount) * Number(totalDiscountPercent)) / 100))
            }
            if(comp.id && comp.id == 'new_discountedamount'){
              let totalTaxedAmount = Number(totalAmount) + ((Number(totalAmount) * Number(taxPercent)) / 100)
              let totalDiscountPercent = Number(discount) + Number(couponPercent)
              comp.value = ((Number(totalTaxedAmount) * Number(totalDiscountPercent)) / 100).toFixed(2)
            }
            if(comp.id && comp.id == 'cr400_totalamount'){
              let totalTaxedAmount = Number(totalAmount) + ((Number(totalAmount) * Number(taxPercent)) / 100)
             
              comp.value = totalTaxedAmount
            }
          }
        }
      }
      return ''
    }
  }
}
