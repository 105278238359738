<app-loader *ngIf="showLoader" [icon]='loaderIcon' [width]="'100px'"></app-loader>
<div *ngIf="!showLoader" class="myapps"> 
  <span class="headerName">{{profile.business.Name}}</span>
  <div *ngIf="aboutToExpire" class="expireMessage">
  <span style="margin-left:10px;vertical-align: middle;">{{subscriptionMessage}}</span>
    <span class="close" (click)="closeMessage()">X</span>
  </div>
  <div class="businessHeader">
    <span class="businessName">Your Apps</span>
    <!-- <span><img class="businessImage" [src]="profile.business.Icon"
      width="{{iconWidth}}"  height="{{iconHeight}}"
      ></span>
    <span class="businessName">{{profile.business.Name}}</span> -->
  </div>
  <div class="row" style="height: 73vh;
  overflow-y: scroll;
">
  <ng-container *ngFor="let userA of userApplications">
    <ng-container *ngIf="commonService.checkAccess(userA.Id,'show')">
      <div class="card col-md-3" (click)="applicationClicked(userA)">
        <div>
          <img [src]="userA.buIcon" class="bu-icon">
          <div class="tooltip fa fa-info-circle">
            <span class="tooltiptext">{{userA.Descrption}}</span>
          </div>
        </div>
        <img class="card-img-top" [src]="userA.Icon" alt="Card image cap">
        <div class="card-body">
          <h4 class="card-title" style="margin-top:10px"><a>{{userA.Name}}</a></h4>
          <!-- <p class="card-text">{{userA.Descrption}}</p> -->
        </div>
      </div>
    </ng-container>
   

  </ng-container>
  <div class="default" *ngIf="message">
    <h1>{{message}}</h1>
  </div>
  </div>
</div>
