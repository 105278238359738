<div class="dialog-header">Terms and Condition</div>

<div class="dialog-content">
  <ul>
    <li *ngFor="let i of tncPoints">{{i}}</li>
  </ul>

</div>

<mat-dialog-actions align="center">
	<button *ngIf="confirmButtonText"  class="btn mat-raised-button mat-primary myButtonReset" style="margin-right:20px;color:white;"
    [ngStyle]="{
      'background-color' : buttonColor ? buttonColor : '#673ab7'
    }"
  (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button  *ngIf="cancelButtonText" class="btn mat-raised-button mat-primary myButtonReset" mat-dialog-close tabindex="-1"  [ngStyle]="{
    'background-color' : buttonColor ? buttonColor : '#673ab7'
  }">{{cancelButtonText}}</button>
</mat-dialog-actions>
