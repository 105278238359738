import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit , AfterViewInit{

  @Input()
  copyright !: string;

  @Input()
  whatsappSupport !: string;

  @Input()
  emailSupport !: string;

  @Input()
  masterSetting !: any

  themeColor !: string

  constructor() { }

  ngAfterViewInit(): void {
    let elem = document.getElementsByClassName('footer')
    if(elem && elem.length>0){
      for(let i=0; i<elem.length;i++){
        (<HTMLElement>elem[0]).style.backgroundColor = this.masterSetting.theme_clr,
        (<HTMLElement>elem[0]).style.color = this.masterSetting.footer_text_Clr
      }
    }
  }

  ngOnInit(): void {
  }

}
