<section>
  <span  (click)="closeProfile()" class="fa fa-times-circle quick-close"></span>
  <div class="container">
    <div class="row profile-row">
      <div class="col-lg-4">
        <div class="card mb-4">
          <div class="card-body text-center">
            <img [src]="userImage" alt="avatar"
              class="rounded-circle img-fluid" style="width: 150px;">
            <h5 class="my-3 fs-14">{{fullName}}</h5>
            <p class="mb-1">( {{role}} )</p>
            <p class="text-muted mb-1">{{company}}</p>
            <!-- <p class="text-muted mb-4">Bay Area, San Francisco, CA</p> -->
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Full Name</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{fullName}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Email</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{email}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Mobile</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{mobile}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Dob</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{dob}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Gender</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{gender}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Id</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{idNumber}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Address</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{address}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Subscription</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{subscription}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Start Date</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{startDate}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">End Date</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{endDate}}</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0">Remaining days</p>
              </div>
              <div class="col-sm-9">
                <p class="mb-0">{{subscriptionDays}}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
