<div>
    <camera-component (pictureTaken)="handleImage($event)" (buttonEmit)="buttonClicked($event)"
    [checkin]="true"
    [checkout]="true"
    ></camera-component>
</div>


<!-- <div class="snapshot" *ngIf="webcamImage">
	<img [src]="webcamImage.imageAsDataUrl" />
</div> -->

<div class="modal" id="userNameModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content" *ngIf="!emailVerified" style="height:260px;width:390px">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeUserNameModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="card py-5 px-3" *ngIf="modalSuccessMessage == ''; else sentEmail">
            <h4 class="m-0">Enter your user name</h4>
            <input type="email" style="margin:20px 0px" [(ngModel)] = "emailAddress" class="form-control"  #Email="ngModel">
            <div class="text-danger" style="font-size:12px" *ngIf="modalErrorMessage !== ''">
              {{modalErrorMessage}}
           </div>
            <button type="button" class="btn btn-primary  submit-btn" [disabled]="!emailAddress" (click)="verifyEmail()">Send password reset email</button>
          </div>
          <ng-template #sentEmail>
            <img src="../../assets/Images/savedSuccess.gif" class="saveSuccess">
            <div class="verify">{{modalSuccessMessage}}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <app-loader [icon]="progressBarIcon" [left]="'46%'" [width]="'100px'" *ngIf="showLoader"></app-loader>

  