import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class PointOfContactsFormCaptureService {

  constructor(private commonService : CommonService) {
    this.onLoad()
  }

  onLoad(){
   this.commonService.logging("-- Point of Cotact Form Loaded --")
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Point of Contacts')
  }

  onFieldChange(tableId : string, formId : string){
    this.commonService.logging('On field Change for Point of Contacts')
  }
  onSubgridRefresh(tableId : string, formId : string){
    this.commonService.logging('On Subgrid Refresh for Ppoint of Contacts')
    return false
   }
}
