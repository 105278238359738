<div *ngIf="!applicationLoaded;else homeScreen" class="home-default" id="home-background">
  <div *ngIf="pageNotFound; else logo">
    <app-page-not-found></app-page-not-found>
  </div>
  <ng-template #logo>
    <img src="../../../assets/Images/AT-full-logo.gif" class="img_black">
  </ng-template>
</div>

<ng-template #homeScreen>
  <ng-container *ngIf="website">
    <app-nav *ngIf="commonService.currentUser$ | async"
  [user]="commonService.currentUser$"
  (emitLogout)="stopPolling()"
  ></app-nav>

  <app-login-component *ngIf="(commonService.currentUser$ | async) === null"
  [logo_details] = "storeSettings"
  (emitNewSession)="newSessionCreated()"
  ></app-login-component>

  <!-- <div *ngIf="(commonService.currentUser$ | async) === null&& version" class="app-version">
    V-{{version}}
  </div> -->
  <!-- <app-profile *ngIf="showProfile && (commonService.currentUser$ | async)" [user]="commonService.currentUser$"></app-profile> -->

  <app-footer class="hidden-xs" *ngIf="storeSettings && (commonService.currentUser$ | async) === null"
  [copyright] = "storeSettings.Copyright"
  [whatsappSupport] = "storeSettings.WhatsApp_support"
  [emailSupport] = "storeSettings.email_support"
  [masterSetting] = "storeSettings"
  ></app-footer>

  <router-outlet *ngIf="(commonService.currentUser$ | async) && authToken"></router-outlet>



  </ng-container>

  <ng-container *ngIf="!website">
    <app-woods-kitchen ></app-woods-kitchen>
  </ng-container>
</ng-template>
