<div class="timeline-div">
    <span>Timeline</span>
    <span><img class="icon-add" src="../../../assets/Images/nav-menu-close.png" (click)="showCreateNote()"></span>
</div>
<div class="search-notes">
    <img *ngIf="searchIcon" [src]="searchIcon" class="divImage">
    <input class="form-control input-type" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Seach timeline">
  </div>
<div *ngIf="createNotes" class="create-note">
    <div>{{editDesp}}</div>
    <input class="form-control note-type" type="text" name="title" [(ngModel)]="notesTitle" autocomplete="off" placeholder="Title">
    <textarea style="margin-top: 10px" class="form-control note-type" name="description" rows="4" [(ngModel)]="notesDesp" placeholder="Enter text..."></textarea>
    <div class="allButtons">
        <button class="mat-raised-button mat-primary" style="margin-right:20px" (click)="onAddorUpdateNote()"
            [ngStyle]="{
              'background-color' : buttonColor ? buttonColor : '#673ab7'
            }">{{action}}</button>
        <button class="mat-raised-button" (click)="closeCreateNote()">Cancel</button>
    </div>
</div>
<div [ngStyle]="{
    'padding-top' : createNotes ? '270px' : '40px' ,
    'font-size': quick ? '13px' : '14px'
}">
    <div *ngFor="let note of notesList | filter:searchText" class="notes-content row">
        <ng-container *ngIf="note.editMode; else normalMode">
            <div>{{editDesp}}</div>
            <input class="form-control note-type" type="text" name="title" [(ngModel)]="notesTitle" autocomplete="off" placeholder="Title">
            <textarea style="margin-top: 10px" class="form-control note-type" name="description" rows="4" [(ngModel)]="notesDesp" placeholder="Enter text..."></textarea>
            <div class="allButtons">
                <button class="mat-raised-button"  style="float:right;margin-right:20px;" (click)="closeEditNote(note)">Cancel</button>

                <button class="mat-raised-button mat-primary" style="float:right;margin-right:20px" (click)="onAddorUpdateNote()"
                    [ngStyle]="{
                      'background-color' : buttonColor ? buttonColor : '#673ab7'
                    }">{{action}}</button>
            </div>
        </ng-container>
        <ng-template #normalMode>
            <div class="imageName-class col-md-3"
            [ngStyle]="{'background-color': header_image_name_clr ? header_image_name_clr : '#FF7851'}"
                ><span class="fa fa-pencil pencil-icon"  [ngStyle]="{'color': timeline_logo_color ? timeline_logo_color : '#FFF'}" ></span></div>
            <div class="col-md-9">
                <div class="notes-header">{{note.header}}</div>
                <div class="notes-desp">{{note.description}}</div>
            </div>
            <div class="row">
                <div class="notes-footer">
                    <div class="notes-time">Last Modified: {{note.time}} ({{note.ownerName}})</div>
                    <div class="notes-icon" [ngStyle]="{'color': timeline_icon_color ? timeline_icon_color : '#000'}">
                        <i class="fa fa-edit edit-icon" (click)="onEditNote(note)"></i>
                        <i class="fa fa-trash delete-icon" (click)="onDeleteNote(note)"></i>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<app-loader *ngIf="showLoader" [icon]='loaderIcon' [width]="'100px'"></app-loader>