import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent  implements AfterViewInit   {

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = undefined
  buttonColor !: string
  radioButtonData : any = []
  displayContacts : any = []
  selectedData !: any
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,private dialogRef: MatDialogRef<ConfirmationDialogComponent>,private cdr: ChangeDetectorRef) {
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      this.buttonColor = data.buttonColor
      if(data.promo){
        this.displayContacts = data.dataInfo
      }
      else{
        if(data.dataInfo){
          this.radioButtonData = data.dataInfo
        }
      }
    }
  }
  ngAfterViewInit(): void {
    for(let i of this.radioButtonData){
      this.selectedData = i.Value
      i.checked = true
      this.changeCSS()
      this.cdr.detectChanges()
      break;
    }
  }

  changeCSS(){
    const tds = document.getElementsByClassName('mat-radio-inner-circle');
    for (let i = 0; i < tds.length; i++) {
      (<HTMLElement>tds[i]).style.setProperty('--radio-button-color', this.buttonColor);
    }
    const td2 = document.getElementsByClassName('mat-radio-outer-circle');
    for (let i = 0; i < td2.length; i++) {
      (<HTMLElement>td2[i]).style.setProperty('--radio-button-color', this.buttonColor);
    }
  }

  onConfirmClick(): void {
    this.radioButtonData.length>0 ? this.dialogRef.close(this.selectedData) : this.dialogRef.close(true);
  }

}
