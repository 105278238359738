import { Injectable } from "@angular/core"
import { CommonService } from "./common.service"

export enum Environment {
  Prod = 'prod',
  Staging = 'qa',
  Dev = 'dev',
  Local = 'local',
}
@Injectable({ providedIn: 'root' })
export class EnvService {
  private _env!: Environment
  private _apiUrl!: string
  get env(): Environment {
    return this._env
  }
  get apiUrl(): string {
    return this._apiUrl
  }
  constructor() {}
  init(): Promise<void> {
    return new Promise((resolve) => {
      this.setEnvVariables()
      resolve()
    })
  }
  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname
    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local
      this._apiUrl = 'https://app-amayra-portal-dev-api.azurewebsites.net/api/main'
    } else if (/^app-amayra-portal-dev-ui.azurewebsites.net*/.test(hostname)) {
      this._env = Environment.Dev
      this._apiUrl = 'https://app-amayra-portal-dev-api.azurewebsites.net/api/main'
    } else if (/^app-amayra-portal-qa-ui.azurewebsites.net*/.test(hostname)) {
      this._env = Environment.Staging
      this._apiUrl = 'https://app-amayra-portal-qa-api.azurewebsites.net/api/main'
    } else if (/^app-amayra-portal-ui.azurewebsites.net*/.test(hostname)  || /^amayraportal.com*/.test(hostname)) {
      this._env = Environment.Prod
      this._apiUrl = 'https://app-amayra-portal-api.azurewebsites.net/api/main'
    } else {
      console.log(`Cannot find environment for host name ${hostname}`)
    }
  }
}
