import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { EMPTY_API } from 'src/app/app.global';
import { ApplicationDetailService } from 'src/app/services/application-detail-service/application-detail-service';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionComponent implements OnInit, AfterViewInit {


  @Input()
  application !: any
  @Input()
  theme !: any

  applicationSettings !: any
  promoData !: any
  errorMessage = ""
  finalDropDownMessage !: any
  selectedMessage !: any
  currentBanner !: any
  requestSender : any = [{Value:0,Text:'Choose send medium'}]
  defaultSender = {Value:0,Text:'Choose send medium'}
  parentContactList !: any
  contactList !: any
  searchText !: string
  allChecked = false
  tempId !: string

  viewDataAvailable = false
  tableRowClicked = false
  myTable !: any
  viewList !:  any
  selectedView !: any
  viewChoose !: any
  tableDataAvailable = false
  displayRule !: any
  layout !: any
  iconValue !: any
  tableIcon !: any
  mobileLayout !: any
  businessId !: string
  ownerId !: string
  tableAllDataFetched = false
  dbTableName = ""
  rowData !: any;
  filterTableData = false
  formGeneration !: any
  errorMessageTable !: string

  user !: any
  userId !: string
  userName !: string
  profile !: any
  homeIconColor = "#FFF"
  homeIcon = ''
  showJobs = false

  showButtons = {Label: "Refresh", actionApi: "Refresh",Icon:  "https://i.postimg.cc/brkpf9ZD/Refresh.png",show: true}
  selectedCount = 0
  iconWidth : any = 90
  iconHeight : any = 90
  loaderIcon !:string
  buttonColor !:  string
  page_background_clr !: string
  view_dropdown_text_clr !: any
  button_text_clr !: any
  // theme : {[key: string]: string} = {}
  showLoader : boolean = true
  constructor(
    private commonService: CommonService,
    private router: Router,
    private applicationService : ApplicationService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private detailService: ApplicationDetailService,
    private tableOperationService : TableOperationService,
    ) { }
  ngAfterViewInit(): void {
    if(this.theme){
      let elem1 = <HTMLElement>document.getElementsByClassName("businessName")[0]
      if(elem1 && this.theme["myapps-bu_name_text_clr"]){
        elem1.style.color = this.theme["myapps-bu_name_text_clr"]
        elem1.style.borderColor = this.theme['main-header_clr']
      }
      let elem2 = document.getElementsByClassName("card")
      for (let i = 0; i < elem2.length; i++) {
        if(elem2[i]){
          (<HTMLElement>elem2[i]).style.backgroundColor = this.theme["myapps-app_cards_clr"];
          (<HTMLElement>elem2[i]).style.borderColor = this.theme["myapps-app_cards_borderline_clr"];
          (<HTMLElement>elem2[i]).style.color = this.theme["myapps-app_cards_text_clr"];
        }
      }
      let elem3 = <HTMLElement>document.getElementsByClassName("myapps")[0]
      if(elem3){
        elem3.style.backgroundColor = this.theme["myapps-background_clr"]
      }

      this.setCSSForMatTab()

      // let elem4 = <HTMLElement>document.getElementsByClassName("headerName")[0]
      // if(elem4 && this.theme["main_header_text_clr"]){
      //   elem4.style.color = this.theme["main_header_text_clr"]
      // }
    }
  }

  ngOnInit(): void {
    this.showLoader = true
    /**Get User*/
    this.commonService.currentUser$.subscribe((u : any)=>{
      this.user = u
    
      this.userId = u.cre57_at_userid
      this.userName = u.cre57_name
      if(this.user.cre57_userprofile){
        this.profile = JSON.parse(this.user.cre57_userprofile)
      }
    })

    if(this.theme){
      this.iconHeight =  this.theme["main-home_icon_h"] ?  this.theme["main-home_icon_h"] : '90'
      this.iconWidth =  this.theme["main-home_icon_w"] ? this.theme["main-home_icon_w"] : '90'
      this.loaderIcon = this.theme['progress-bar_icon']
      this.page_background_clr = this.theme["dashboard-home_background_clr"]
      this.view_dropdown_text_clr = this.theme["app-home_views_text_clr"]
      this.button_text_clr = this.theme["dashboard-home_buttons_text_clr"];
      if(this.theme["main-home_icon_clr"]){
        this.homeIconColor = this.theme["main-home_icon_clr"]
      }
      this.buttonColor = this.theme['main-header_clr']
      this.homeIcon = this.profile.business.Icon
      this.setCSSForMatTab()
    }
    this.businessId = this.application.buId
    this.applicationSettings = JSON.parse(this.application.appSettings)
    let payload : {[key: string]: string}= {}
    for(let i of this.applicationSettings.onLoad.params){
      payload[i.name] = i.value
    }
   console.log(this.applicationSettings)
    this.tempId = this.applicationSettings.wp_template_id
    this.applicationService.getDataForPromoApp(this.applicationSettings.onLoad.action,payload, this.commonService.getAuthToken()).subscribe((res)=>{
      this.showLoader = false
      if(res && res.result){
        this.promoData = JSON.parse(res.result)
        this.parentContactList = this.promoData.contacts
        for(let i of this.parentContactList){
          if(!i.email || i.email == ''){
            i.email = 'NA'
          }
          if(!i.mobile || i.mobile == ''){
            i.mobile = 'NA'
          }
        }
        this.contactList = Object.assign([],this.parentContactList)
        for(let i of this.promoData['Banner']){
          let dropDownMessage = i.msg.split(';\n')
          i.dropDownMessages = dropDownMessage
        }
        this.finalDropDownMessage = this.promoData['Banner'][0].dropDownMessages
        this.selectedMessage = this.finalDropDownMessage[0]
        this.currentBanner = this.promoData['Banner'][0]
      }
      else{
        this.errorMessage = "Failed to load promotion data"
      }
    })

    let tableForm
    for(let app of this.profile.apps){
      if(app.Id == this.application.Id){
        for(let table of app.tables){
          if(table.uid  == this.applicationSettings.marketing_list_table_uid){
            this.myTable = table
            tableForm = JSON.parse(table.forms[0].Layout)
          }
        }
      }
    }
    let component
    for(let sec of tableForm.tab[0].section){
      for(let comp  of sec.components){
        if(comp.id.includes('cre57_type')){
          component = comp
        }
      }
    }
    
    component.optionset = component.optionset.replaceAll('\'','\"')   
    for(let i of JSON.parse(component.optionset)){
      this.requestSender.push(i)
    }

    this.ownerId = this.myTable.OwnerId
    this.dbTableName = this.myTable.Data_Entity_Set
    this.filterTableData = this.myTable.filterUserRecords
    this.tableIcon = this.myTable.Icon
    this.formGeneration = this.commonService.getDefaultFormFirst(this.myTable.forms)
    this.viewList =   this.commonService.sortDataBySequence(this.myTable.views)
    this.selectedView = this.viewList ? this.viewList.length>0 ? this.viewList[0] : undefined : undefined
    if(this.selectedView){
      this.getTableForView(this.selectedView)
    }
    else{
      this.tableDataAvailable = false
      this.errorMessageTable = "No view available"
      this.showLoader = false
    }
    
    
    this.cdr.detectChanges()
    let elem6 = document.querySelectorAll('input[type=checkbox]');
    if(elem6){
      for(let i=0;i< elem6.length;i++){
        (<HTMLElement>elem6[i]).style.setProperty('--borderColor',this.theme["main-header_clr"] );
      }
    }
    this.cdr.detectChanges()

  }

  callTabChange(e: any){
    this.showJobs = false
    if(e.tab.textLabel  == 'Existing Jobs'){
      this.showJobs = true
    }
  }

  activeSlideChange(action:any){
    for(let i=0;i<this.promoData['Banner'].length;i++){
      if(action.relatedTarget == i){
        this.finalDropDownMessage = this.promoData['Banner'][i].dropDownMessages
        this.currentBanner = this.promoData['Banner'][i]
      }
    }
    this.selectedMessage = this.finalDropDownMessage[0]
  }

  checkboxClicked(event: any,customer: any){
    this.selectedCount = 0
    this.allChecked = false
    for(let i of this.contactList){
      if(i.name == customer.name && i.mobile == customer.mobile){
        i.checked = event.target.checked
      }
      if(i.checked){
        this.selectedCount++
      }
    }
    for(let i of this.parentContactList){
      if(i.name == customer.name && i.mobile == customer.mobile){
        i.checked = event.target.checked
      }
    }
  }

  callSelectAll(event: any){
    this.selectedCount = 0
    this.allChecked = event.target.checked 
    for(let i of this.contactList){
      i.checked = event.target.checked
      if(i.checked){
        this.selectedCount++
      }
    }
    for(let i of this.parentContactList){
      i.checked = event.target.checked
    }
  }

  onRequestSenderDropdownValChange(event : any){
    this.selectedCount = 0
   switch(this.defaultSender.Value){
    case 647510000:
      this.filterContactsByMobileNumber();
      break;
    case 647510001:
      this.filterContactsByEmailId();
      break;
    case 647510002:
      this.filterContactsByMobileNumber();
      break;
    default:
      for(let i of this.parentContactList){
        i.checked = false
      }
      this.contactList = Object.assign([],this.parentContactList)  
   }
  }

  filterContactsByMobileNumber(){
    let newContactList = []
    for(let i of this.parentContactList){
      if(i.mobile && i.mobile!=='NA'){
        let val = {name:i.name,mobile:i.mobile,checked:false}
        newContactList.push(val)
      }
    }
    this.contactList = newContactList
  }

  filterContactsByEmailId(){
    let newContactList = []
    for(let i of this.parentContactList){
      if(i.email && i.email!=='NA'){
        let val = {name:i.name,email:i.email,checked:false}
        newContactList.push(val)
      }
    }
    this.contactList = newContactList
  }

  createJob(){
    console.log(this.parentContactList)
    console.log(this.defaultSender)
    console.log(this.selectedMessage)
    console.log(this.currentBanner)

    let myContacts = []
    for(let i of this.parentContactList){
      if(i.checked){
        myContacts.push({i})
      }
    }

    let param = this.promoData.bu.num + ";" + this.currentBanner.num + ';' + this.tempId + ';' + this.defaultSender.Value + ';' + this.selectedMessage + ';'

    let payload = {
      "name":"CreateNotificationJobForMarketing",
      "param":"AT-BU-1000;AT-BAN-10004;AT-Temp-WP-10000;647510000;*Limited Time Bumper Offer*;{\"contacts\":[{\"name\":\"Mayank Jain\",\"email\":\"\",\"mobile\":\"9829427471\"},{\"name\":\"Arihant Jain\",\"email\":\"\",\"mobile\":\"9550937640\"},{\"name\":\"Manku Jain\",\"email\":\"\",\"mobile\":\"9079331527\"}]}"
    }
    this.applicationService.getDataForPromoApp(this.applicationSettings.onLoad.action,payload, this.commonService.getAuthToken()).subscribe((res)=>{
      console.log(res)
    })
  }

  getTableForSelectedView(event : any){
  
    for(let view of this.viewList){
      if(view.Name == event.target.value){
        this.selectedView = view
        this.getTableForView(view)
      }
    }
  }

  getTableForView(view : any){
    this.viewChoose = JSON.parse(JSON.stringify(this.selectedView))
    this.showLoader = true
    this.tableDataAvailable = false
    if(EMPTY_API.includes(view.DataApi) || view.DataApi == ""){
      this.tableDataAvailable = false
      this.errorMessageTable= "No table data available in this view"
      this.showLoader = false
      this.viewDataAvailable = true
    }
    else{
      this.errorMessageTable = ""
      this.displayRule = view.Displayrule ? JSON.parse(view.Displayrule.toString()) : []
      this.layout =  view.Layout ? JSON.parse(view.Layout.toString()).values : []
      this.iconValue = JSON.parse(view.Layout.toString()).icon ? JSON.parse(view.Layout.toString()).icon : false 
      this.mobileLayout = this.layout.slice(0,2)
      let app_name = this.businessId.replace(/-/g,'_')
      let payload = 'top=100'
      if((view.Name.toLowerCase().trim()).startsWith('my')){
        payload += "="+this.ownerId+"="+this.userId
      }
      this.tableAllDataFetched = false
      this.detailService.getTableData(app_name,view.DataApi,this.commonService.getAuthToken(),payload).subscribe((res)=>{
        if(res && res.value && res.value.length>0){
          this.setTableData(res,false)
          this.getAllTableData(app_name,view)
          // this.showLoader = true
        }
        else{
          this.tableDataAvailable = false
          this.errorMessageTable = "No table data available in this view"
          this.showLoader = false
          this.viewDataAvailable = true
        }
      })
    }
  }

  getAllTableData(app_name : string,view:any){
    let payload = this.userId
    if((view.Name.toLowerCase().trim()).startsWith('my')){
      payload = this.ownerId+"="+this.userId
    }

    this.detailService.getTableData(app_name,view.DataApi,this.commonService.getAuthToken(),payload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        // if(res.value.length !== this.rowData.length){
          this.setTableData(res,true)
        // }
      }
    })
  }

  setTableData(res:any,allData: boolean){
    if(res["@odata.context"]){
      let str = res["@odata.context"]
      this.dbTableName = str.substring(
        str.indexOf("#") + 1,
        str.indexOf("(")
      );
    }
    this.rowData = JSON.parse(JSON.stringify(res.value))
    if(this.filterTableData){
      this.filterTableDataByLoggeInUser(this.myTable.OwnerName)
    }
    this.createSubChildTableData()
    let dateId = ""
    for(const i of this.layout){
      if(i.type && (i.type).toLowerCase()=="date"){
        dateId = i.id
        for(const j of this.rowData){
          if(j[dateId] && j[dateId]!=""){
             var d = new Date(j[dateId])
            j[dateId] = moment(d).format('DD-MMM-YYYY');
          }
        }
      }
    }
   
    let header_primary = JSON.parse(this.formGeneration[0].Layout).header.primery_field
    for(const row of this.rowData){
      this.assignIconToEachRow(row,header_primary)
      for(let j in row){
        if(j == "createdon"){
          let utcDate = row[j]; 
          let localDate = (new Date(utcDate)).toString();
          row['createdon@OData.Community.Display.V1.FormattedValue'] = localDate.substring(0,localDate.indexOf('G'))
        }
      }
    }
    this.tableDataAvailable =  true
    this.showLoader = false
    this.viewDataAvailable = true
    this.tableAllDataFetched = allData
  }

  assignIconToEachRow(row : any,header_primary : string){
    let flag = false
    if(typeof (this.iconValue) == "string"){
      for(let i in row){
        if(i == this.iconValue && row[i]){
          row.checkboxIcon = 'data:image/jpg;base64,'+row[i]
          row.checkBoxIconType = "image"
          row.checkboxIconColor = "#FFF"
          flag = true
        }
      }
      if(!flag){
        row.checkboxIcon = this.tableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
    else{
      if(!this.iconValue){
        this.setInitials(row,header_primary)
      }
      else{
        row.checkboxIcon = this.tableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
  }

  setInitials(row: any, header_primary: string){
    if(row[header_primary]){
      let str = ((row[header_primary]).trim()).toString().split(" ")
      let initials = ""
      if(str.length == 1){
        initials = str[0].charAt(0)+(str[0].charAt(1) ? str[0].charAt(1) : str[0].charAt(0))
      }
      else{
        initials =  (str[str.length-1].charAt(0) == '(' || str[str.length-1].charAt(0) == ')') ? str[str.length-2].charAt(0) : str[str.length-1].charAt(0)
        initials = str[0].charAt(0) + initials
      }
      row.checkboxIcon = initials.toUpperCase()
      row.checkBoxIconType = "initial"
    }
  }

  filterTableDataByLoggeInUser(ownerString : string){
    let data = []
    for(let i of  this.rowData){
      if(i[ownerString] == this.userName){
        data.push(i)
      }
    }
    this.rowData = data
  }

  createSubChildTableData(){
    for(let i of  this.rowData){
      this.addSubChildToTableData(i)
    }
  }

  addSubChildToTableData(i: any){
    for(let elem in i){
      if(i[elem] instanceof Object){
        this.addSubChildToTableData(i[elem])
        for(let j in i[elem]){
          let str = elem+"."+ j
          i[str] = i[elem][j]
       }
      }
    }
  }

 
  openDialog(){
   
    let displayContacts = []
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    for(let i of this.contactList){
      if(i.checked){
        displayContacts.push(i)
      }
    }

    dialogConfig.data = {
      message: 'Are you sure you want to create these jobs and send message',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.theme['main-header_clr'],
      dataInfo: displayContacts,
      promo: true
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.createJob()
      }
    });
  }

  performSelectedTask(){
    this.getTableForView(this.selectedView)
  }

  setCSSForMatTab(){
    let elem = document.getElementsByClassName('mat-tab-label')
    for(let i=0;i<elem.length;i++){
      if(elem[i]){
        (<HTMLElement>elem[i]).style.color = this.theme["form-tab_header_noselect_clr"]
      }
    }

    let elem4 = <HTMLElement>document.getElementsByClassName('mat-tab-label mat-tab-label-active')[0]
    if(elem4){
      elem4.style.color = this.theme["form-tab_header_onselect_clr"]
    }
    let elem5 = <HTMLElement>document.getElementsByClassName('mat-ink-bar')[0]
    if(elem5){
      elem5.style.backgroundColor = this.theme["form-tab_header_onselect_clr"]
    }
  }

  compareFn(a: any,b: any){
    return a && b && a.Value == b.Value;
  }

}
