import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApplicationDetailService } from 'src/app/services/application-detail-service/application-detail-service';
import { CommonService } from 'src/app/services/common.service';
import { FormCaptureService } from 'src/app/services/form-capture/form-capture.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';

@Component({
  selector: 'app-quick-create',
  templateUrl: './quick-create.component.html',
  styleUrls: ['./quick-create.component.scss']
})
export class QuickCreateComponent implements OnInit, OnChanges {

  @Input()
  formDetails !: any

  @Input()
  rowData !: any

  @Input()
  selectedView !: any

  @Input()
  newButton !: boolean

  @Input()
  dbTableName !: string

  @Input()
  primaryColumn !: string

  @Input()
  userId !: string

  @Input()
  userName !: string

  @Input()
  tableId !: string

  @Input()
  businessId !: string

  @Input()
  theme !: any

  @Input()
  allTables !: any

  @Input()
  lookups : {[key: string]: any} = {}

  @Input()
  parentHeaderData : any

  @Input()
  tableLogicalName !: string

  @Input()
  newFormLookup : {[key: string]: any} = {}

  @Output()
  emitFormClose = new EventEmitter<any>()

  @Output()
  emitRowAdd = new EventEmitter<any>()

  @Output()
  emitLookupElement = new EventEmitter<any>()

  formData !: any
  formIcon !: any
  tableIcon :  any
  headerData : any = []
  headerFields !: any
  ownerId !: any
  ownerName !: any
  tableDisplayName !: string
  showGoogleMapSearch = false
  storeKeysLabel : {[key : string] : string} = {}
  storeAllLookUps : any = []
  saveButtonAction !: string
  showLoader = false
  disableButton = false
  fileComp !: any
  fileStored !: any
  callDeleteFile = false
  formLoaded = false

  form_section_border_clr !: string
  form_section_text_clr !: string
  form_section_header_back_clr !: string
  form_section_header_line_clr !: string
  header_image_name_clr !: string
  form_back_clr !: string
  form_text_clr !: string
  buttonColor !: string
  windowOpen  = 0
  selectedTab !:string
  loaderIcon !: string
  newLookup : boolean =  false
  createBuField !: any
  createStatus !: any
  createStatusReason !: any
  formStatus = 'Active'
  formStatusReason = 'This record status is Active'
  showFormMessage = true

  constructor(
    private commonService : CommonService,
    private detailService : ApplicationDetailService,
    private fb: FormBuilder,
    private formCaptureSerive: FormCaptureService,
    private cdr : ChangeDetectorRef,
    private tableOperationService: TableOperationService,
    private toastr : ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.setFormStatusAndReason()
    this.commonService.logging(this.theme)
    this.commonService.logging(JSON.parse(this.formDetails[0].Layout))
    this.selectedTab = 'General'
    this.headerFields = JSON.parse(this.formDetails[0].Layout).header
    let formLayout = JSON.parse(this.formDetails[0].Layout)
    if(Object.keys(formLayout).includes('icon')){
      this.formIcon = JSON.parse(this.formDetails[0].Layout).icon
    }
    else{
      this.formIcon = false
    }

    let flag = false
    if(typeof (this.formIcon) == "string"){
      for(let i in this.rowData){
        if(i == this.formIcon){
          this.formIcon = this.rowData[i] ? this.rowData[i] : true
          flag = true
        }
      }
      if(!flag){
        this.formIcon = true
      }
    }

    this.commonService.logging(this.rowData)
    if(this.rowData){
      this.saveButtonAction = "update"
    }
    else{
      this.saveButtonAction = "create"
    }

    for(let table of this.allTables){
      if(this.tableId == table.uid){
        this.ownerId = table.OwnerId
        this.ownerName = table.OwnerName
        this.tableDisplayName = table.Name
        this.tableIcon = table.Icon
      }
    }

    let userProfile = localStorage.getItem('userProfile')
    if(userProfile){
      let profile = JSON.parse(userProfile)
      this.showGoogleMapSearch = profile.business.EnableGoogleAddressSearchAPI
      this.createBuField =JSON.parse(profile.business.BuPrimaryColumn)["primary_field"]
      this.createStatus =JSON.parse(profile.business.BuPrimaryColumn)["status_field"]
      this.createStatusReason =JSON.parse(profile.business.BuPrimaryColumn)["status_reason_field"]
    }
    this.formData = []
   let sectionList = JSON.parse(this.formDetails[0].Layout)
   this.formData.push({name: 'General', id: 'tab1', visible: true, section: sectionList.section})
   this.formData.push({name: 'Timeline', id: 'tab2', visible: true, section: []})
   this.assignValuesToForm()
   this.formCaptureSerive.setFormData(this.tableId,this.formDetails[0].uid,this.formData,this.parentHeaderData)
   if(this.formDetails[0].uid == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
    setTimeout(()=>{
      this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)},2000);
   }
   else{
    this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)
   }
   if(this.newButton){
    this.formCaptureSerive.openNewForm(this.tableId,this.formDetails[0].uid,this.formData)
   }
   if(this.theme){
    this.form_section_text_clr = this.theme["form-section_header_text_clr"]
    this.form_section_header_line_clr = this.theme["form-section_header_line_clr"]
    this.form_section_header_back_clr = this.theme["form-section_header_back_clr"]
    this.form_back_clr = this.theme["form-section_back_clr"]
    this.form_text_clr = this.theme["form-section_text_clr"]
    this.form_section_border_clr = this.theme["form-section_border-clr"]
    this.header_image_name_clr = this.theme["main-header_clr"]
    this.buttonColor = this.theme['form-section_header_back_clr']
    this.loaderIcon = this.theme['progress-bar_icon']
   }
  }
  setFormStatusAndReason(){
    if(this.rowData){
      let myTable
      for(let table of this.allTables){
        if(table.uid == this.tableId){
          myTable = table
        }
      }
      let statusOption = JSON.parse(myTable.StatusOptions)
      let activeStatus, inactiveStatus
      for(let status of statusOption){
        if((status.Text).toLowerCase() == 'active'){
          activeStatus = status.Value
        }
        else{
          inactiveStatus = status.Value
        }
      }
      if(this.rowData[myTable.Status] == null || this.rowData[myTable.Status] == activeStatus){
        this.formStatus = 'Active'
        this.formStatusReason = this.rowData[myTable.Status] ? 'This form status is '+this.rowData[myTable.Status+'@OData.Community.Display.V1.FormattedValue'] +' Reason : ' + this.rowData[myTable.ActiveStatus+'@OData.Community.Display.V1.FormattedValue']: 'This form status is Active'
      }
      else if(this.rowData[myTable.Status] == inactiveStatus){
        this.formStatus = 'Inactive'
        this.formStatusReason = this.rowData[myTable.Status] ? 'This form status is '+this.rowData[myTable.Status+'@OData.Community.Display.V1.FormattedValue'] +' Reason : ' + this.rowData[myTable.InActiveStatus+'@OData.Community.Display.V1.FormattedValue']: 'This form status is Inactive'
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.rowData && changes.rowData.currentValue){
      this.rowData = changes.rowData.currentValue

      if(changes.formDetails && changes.formDetails.currentValue){
        this.formDetails = changes.formDetails.currentValue
        this.formData = [JSON.parse(this.formDetails[0].Layout)]
      }
      if(changes.dbTableName && changes.dbTableName.currentValue){
        this.dbTableName = changes.dbTableName.currentValue
      }
      if(changes.primaryColumn && changes.primaryColumn.currentValue){
        this.primaryColumn = changes.primaryColumn.currentValue
      }
      if(this.formData){
        if(this.formDetails[0].uid == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
          setTimeout(()=>{
            this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)},2000);
         }
         else{
          this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)
         }
         this.assignValuesToForm()
      }
    }
    else if(changes.newButton && changes.newButton.currentValue){
      if(changes.formDetails && changes.formDetails.currentValue){
        this.formDetails = changes.formDetails.currentValue
        this.formData = [JSON.parse(this.formDetails[0].Layout)]
      }
      if(changes.dbTableName && changes.dbTableName.currentValue){
        this.dbTableName = changes.dbTableName.currentValue
      }
      if(changes.primaryColumn && changes.primaryColumn.currentValue){
        this.primaryColumn = changes.primaryColumn.currentValue
      }
      if(this.formData){
        if(this.formDetails[0].uid == 'Add_Course:Enrolled_Courses:Students_Management_System:AT-BU-1002'){
          setTimeout(()=>{
            this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)},2000);
         }
         else{
          this.formCaptureSerive.onLoad(this.tableId,this.formDetails[0].uid,this.formData)
         }
         this.assignValuesToForm()
      }
    }
  }

  ngAfterViewInit(): void {
    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          this.storeKeysLabel[comp.label] = this.tableId+comp.id
          if(this.rowData && this.formStatus == 'Inactive'){
            comp.lock = true
          }
          if(comp.required){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.required = true
            }
          }
          if(comp.calculated){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.readOnly = true
              elem.classList.add("readonly-class")
            }
          }
          if(comp.lock){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.disabled = true
              elem.style.backgroundColor = this.theme ? this.theme["form-section_text_disabled_clr"] : ''
            }
          }
          if(comp.datatype == "address_auto_complete"){
            if(this.showGoogleMapSearch){
              let elem = document.getElementById(this.tableId+comp.id) as HTMLInputElement;
              const val = new google.maps.places.SearchBox(elem)
              comp.googleMapValue = val
              var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(26.8765702,75.7521331), new google.maps.LatLng(-33.8474, 151.2631));

              var options = {
               bounds: defaultBounds,
               types: ['geocode']
              };


              const autocomplete = new google.maps.places.Autocomplete(elem, options);
              google.maps.event.addListener(autocomplete, 'place_changed',  () => {
                  var place = autocomplete.getPlace();
                  let storeData : {[key: string]: string} = {
                    'route' : '',
                    'neighborhood' : '',
                    'sublocality_level_2' : '',
                    'sublocality_level_1' : '',
                    'locality'  : '',
                    'administrative_area_level_1' : '',
                    'country' : '',
                    'postal_code' : ''
                  }
                  let add = elem.value.split(',')
                  let initialAdd = add[0].split(' ')
                  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    storeData[component.types[0]] = component.long_name
                  }
                  this.setCompleteAddressFields(storeData,initialAdd[0])
              });
            }
            else{
              comp.visible = false
            }
          }
          if(comp.datatype == "multilinetext"){
            let elem = <HTMLTextAreaElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.rows = comp['min-row'] ? comp['min-row'] : 4
            }
          }
        }
      }
    }

   this.applyQuickCreateCss()
  }

  closeMessage(){
    this.showFormMessage = false
  }

  applyQuickCreateCss(){
    if(this.theme){
      this.changeActiveCss()
      let elem3 = document.getElementsByClassName('form-control')
      if(elem3){
        for(let i=0;i< elem3.length;i++){
          (<HTMLElement>elem3[i]).style.borderColor = this.theme["form-section_text_border_clr"]
        }
      }
      let elem4 = document.getElementsByClassName('ng-select-container')
      if(elem4){
        for(let i=0;i< elem4.length;i++){
          (<HTMLElement>elem4[i]).style.borderColor = this.theme["form-section_text_border_clr"];
          (<HTMLElement>elem4[i]).style.color = this.form_section_header_back_clr;
          (<HTMLElement>elem4[i]).style.setProperty('--disablebackgroundColorlookup',this.theme["form-section_text_disabled_clr"] );
        }
      }
      let elem5 = document.getElementsByClassName('head-label')
      if(elem5 && elem5[0]){
        (<HTMLElement>elem5[0]).style.fontWeight = '600';
        (<HTMLElement>elem5[0]).style.color = this.form_section_header_back_clr
      }
      let elem6 = document.getElementsByClassName('quick-class')
      if(elem6){
        for(let i=0;i< elem6.length;i++){
          (<HTMLElement>elem6[i]).style.borderColor = this.buttonColor;
        }
      }
      this.applyMultiSelectCss()
      this.cdr.detectChanges()
    }
  }
  setCompleteAddressFields(storeData : any,initialAdd : string){
    let address1 = document.getElementById(this.storeKeysLabel['Address Line 1']) as HTMLInputElement
    address1.value = initialAdd + " " + storeData['route']

    let address2 = document.getElementById(this.storeKeysLabel['Address Line 2']) as HTMLInputElement
    address2.value = storeData['neighborhood'] +" "+ storeData['sublocality_level_2'] + " "+storeData['sublocality_level_1']

    let city = document.getElementById(this.storeKeysLabel['City']) as HTMLInputElement
    city.value = storeData['locality']

    let state = document.getElementById(this.storeKeysLabel['State']) as HTMLInputElement
    state.value = storeData['administrative_area_level_1']

    let country = document.getElementById(this.storeKeysLabel['Country']) as HTMLInputElement
    country.value = storeData['country']

    let postalCode = document.getElementById(this.storeKeysLabel['Zipcode']) as HTMLInputElement
    postalCode.value = storeData['postal_code']

    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          switch(comp.label){
            case "Address Line 1" :
              comp.value = initialAdd + " " + storeData['route']
              break;
            case "Address Line 2" :
              comp.value = storeData['neighborhood'] +" "+ storeData['sublocality_level_2'] + " "+storeData['sublocality_level_1']
              break;
            case "City" :
              comp.value = storeData['locality']
              break;
            case "State" :
              comp.value = storeData['administrative_area_level_1']
              break;
            case "Country" :
              comp.value = storeData['country']
              break;
            case "Zipcode" :
              comp.value = storeData['postal_code']
              break;
          }
        }
      }
    }
  }

  closeForm(buttonClicked ?: any,formClose ?: boolean,payload ?: any){
    this.emitFormClose.emit({ clicked: buttonClicked, close: formClose,payload: payload})
  }

  lookUpClicked(comp: any,newLookUp: boolean){
    this.newLookup = true
    if(!newLookUp){
      for(let i of comp.allTableData){
        if(i[comp.lookUpTablePrimaryColumn] == comp.selectedId)
        {
          comp.rowData = i
          comp.clickedRowData = i
        }
      }
    }
    comp.formGeneration = this.commonService.getLookupFormFirst(comp.formGeneration)
    let quickForm = comp.formGeneration.filter((filter: any)=> filter.Type.includes('Quick Create'))
    if(quickForm && quickForm.length>0){
      this.emitLookupElement.emit(comp)
    }
    else{
      comp.copyPrimaryColumn = comp.primary_column
      comp['primary_column'] = comp.lookUpTablePrimaryColumn
      comp['dbTableName'] = comp.lookupTableName
      this.passObject(comp)
    }
  }

  assignValuesToForm(){
    this.headerData = []
    for(let i in this.headerFields){
      let primaryField = false, label = ""
      if(i == 'primery_field'){
        primaryField = true
        label = this.tableDisplayName
      }
      this.headerData.push({field : this.headerFields[i], primaryField : primaryField,value: undefined,label: label})
    }
    for(let i of this.formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          comp.lock = false
          if(this.formStatus == 'Inactive'){
            comp.lock = true
          }
          if(comp.lock){
            let elem = <HTMLInputElement>document.getElementById(this.tableId+comp.id)
            if(elem){
              elem.disabled = true
              elem.style.backgroundColor = this.theme ? this.theme["form-section_text_disabled_clr"] : ''
            }
          }
          comp.value = this.rowData ? this.rowData[comp.id] : null
          comp.tableRowClicked = false
          if(comp.calculated){
            comp.icon = this.theme["form-calculated_field_icon"]
          }
          if(this.newButton){
            if(comp.id == this.ownerName){
              comp.value = this.userName
            }
          }
          if(comp.datatype == "datetime"){
            let data = this.rowData ? this.rowData[(comp.id.split('@'))[0]].split('Z')[0] : null
            comp.value = data
          }
          if(comp.datatype == "file"){
            if(comp.value){
              comp.fileName = this.rowData ? this.rowData[comp.id+"_name"] ? this.rowData[comp.id+"_name"] :  '' : ''
            }
          }
          if(comp.datatype == "boolean"){
            comp.value = comp.value ? comp.value : false
            this.changeToggleCss(comp)
          }
          if(comp.datatype=="lookup"){
            let api = comp.dataapi
            comp.options = []
            comp.amountsList = []
            comp.primary_column = comp.copyPrimaryColumn ? comp.copyPrimaryColumn : comp.primary_column
            let payload = 'top=100'
            this.detailService.getTableData(this.businessId.replace(/-/g,'_'),api,this.commonService.getAuthToken(),payload).subscribe((res : any)=>{
              this.getAllTableData(this.businessId.replace(/-/g,'_'),api,comp)
              this.setLookUpData(res,comp)
            })
            if(this.rowData){
              comp.selectedOptionLabel = {
                value: this.rowData[comp.relatedid+"@OData.Community.Display.V1.FormattedValue"],
                id : this.rowData[comp.relatedid]
              }
              if(this.lookups){
                this.lookups[comp.label] = comp.selectedOptionLabel
              }
            }
            else{
              comp.selectedOptionLabel = {
                value: undefined,
                id : undefined
              }
              if(this.newButton){
                comp.selectedOptionLabel = Object.keys(this.lookups).length>0 && comp.calculated ? this.lookups[comp.label] : {
                  value: undefined,
                  id : undefined
                }
                if(!comp.selectedOptionLabel){
                  comp.selectedOptionLabel = {
                    value: undefined,
                    id : undefined
                  }
                }
              }
            }
          }
          if(comp.datatype=="decimal"){
            comp.min = 0.00
            comp.value = this.rowData ? Number(this.rowData[comp.id]) : 0
          }
          if(comp.datatype=="amount"){

            let val = this.rowData ? this.rowData[comp.id+"@OData.Community.Display.V1.FormattedValue"] : null
            if(val){
              comp.currency = val.charAt(0)
              let num = this.rowData ? this.rowData[comp.id] : null
              comp.value = (Math.round(num * 100) / 100).toFixed(2);
            }
          }
          if(comp.datatype=="optionset"){
            comp.optionset = comp.optionset.replaceAll('\'','\"')
            let storeOption = JSON.parse(comp.optionset)
            comp.options = [{
              key: "--select--",
              value: null
            }]
            for(let i of storeOption){
              comp.options.push({
                key:i.Text,
                value: i.Value
              })
            }
            if(this.rowData){
              let valueId = (comp.id).split('@')[0]
              if(comp.id == valueId){
               comp.id = comp.oldId ? comp.oldId : comp.id
              }
              comp.selectedOptionLabel = {
                key: this.rowData[comp.id] ? this.rowData[comp.id] : comp.options[0].key ,
                value : this.rowData[valueId] ? this.rowData[valueId] : comp.options[0].value
              }
            }
            else{
              comp.selectedOptionLabel = {
                key: comp.options[0].key,
                value : comp.options[0].value
              }
            }
          }
          if(comp.datatype=="multioptionset"){
            this.formLoaded = true
            comp.multioptionset = comp.multioptionset.replaceAll('\'','\"')
            let storeOption = JSON.parse(comp.multioptionset)
            comp.dropdownSettings = {
              idField: 'item_id',
              textField: 'item_text',
            };
            comp.options = []
            for(let i of storeOption){
              comp.options.push({
                item_text:i.Text,
                item_id: i.Value
              })
            }
            comp.selectedItem = []
            if(this.rowData){
              let valueId = (comp.id).split('@')[0]
              if(comp.id == valueId){
                comp.id = comp.oldId ? comp.oldId : comp.id
              }
              let item_ids = this.rowData[valueId] ? this.rowData[valueId].split(',') : null
              let item_texts = this.rowData[comp.id] ? this.rowData[comp.id].split(';') : null
              if(item_ids){
                for(let i=0;i<item_ids.length;i++){
                  comp.selectedItem.push({
                    item_text : item_texts[i],
                    item_id : Number(item_ids[i])
                  })
                }
              }
            }
            if (!comp.dropDownForm) {
              comp.dropDownForm = this.fb.group({
                myItems: [comp.selectedItem]
              });
            }
          }
          if(comp.datatype=='coupon'){
            this.formLoaded = false
            let val = comp['target_field']
            let label = comp['target_label']
            let storeOption = []
            this.detailService.getTableData(this.businessId.replace(/-/g,'_'),comp.dataapi,this.commonService.getAuthToken(),this.userId).subscribe((res)=>{
              storeOption = res.value
              this.formLoaded = true

              comp.dropdownSettings = {
                idField: 'item_id',
                textField: 'item_text',
                singleSelection: comp.allomutiple ? false : true
              };
              comp.options = []
              for(let i of storeOption){
                comp.options.push({
                  item_text: i[label],
                  item_id: i[label],
                  item_val: i[val]
                })
              }
              comp.selectedItem = []
              if(this.rowData && this.rowData[comp.id]){

                for(let i of comp.options){
                  if(i['item_id'] == this.rowData[comp.id]){
                    comp.selectedItem.push({
                      item_text :  i['item_text'],
                      item_id : i['item_id']
                    })
                  }
                }
              }
              else{
                comp.dropDownForm = undefined
              }
              if (!comp.dropDownForm) {
                comp.dropDownForm = this.fb.group({
                  myItems: [comp.selectedItem]
                });
              }
              this.cdr.detectChanges()
              this.applyMultiSelectCss()
            })
          }
          if(comp.datatype =="date" && this.rowData && this.rowData[comp.id]){
            comp.value = moment(this.rowData[comp.id].substring(0,10)).format('YYYY-MM-DD');
          }
          if(comp.datatype == 'mobile'){
            comp.minLength = "10"
            comp.maxLength = "12"
            if(comp.value){
              let val = (comp.value).split('-')
              let newVal = ""
              for(let g=0;g<val.length;g++){
                newVal += val[g]
              }
              comp.value = Number(newVal)
            }
          }

          for(let h of this.headerData){
            if(h.field == comp.id){
              h.value = comp.value
              if(!h.primaryField){
                h.label = comp.label
              }
              else{
                if(typeof (this.formIcon) == "string"){
                  h.initials = 'data:image/jpg;base64,'+this.formIcon
                  h.icon = 'image'
                  h.iconColor = "#FFF"
                }
                else{
                  if(!this.formIcon){
                    if(comp.value){
                      let str = ((comp.value).trim()).toString().split(" ")
                      let initials = ""
                      if(str.length == 1){
                        h.initials = str[0].charAt(0)+(str[0].charAt(1) ? str[0].charAt(1) : str[0].charAt(0))
                      }
                      else{
                        initials =  (str[str.length-1].charAt(0) == '(' || str[str.length-1].charAt(0) == ')') ? str[str.length-2].charAt(0) : str[str.length-1].charAt(0)
                        h.initials = str[0].charAt(0) + initials
                      }
                    }
                    h.icon = 'initials'
                  }
                  else{
                    h.initials = this.tableIcon
                    h.icon = 'image'
                  }
                }
              }
            }
          }
        }
      }
    }
    this.cdr.detectChanges()
  }

  getAllTableData(app_name : string,api : string,comp: any){
    let payload = this.userId

    this.detailService.getTableData(app_name,api,this.commonService.getAuthToken(),payload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        this.setLookUpData(res,comp)
      }
    })
  }

  setLookUpData(res : any,comp: any){
    let str = res["@odata.context"]
    comp.options = []
    comp.amountsList = []
    comp.lookupTableName = str.substring(
      str.indexOf("#") + 1,
      str.indexOf("(")
    );
    for(let tab of this.allTables){
      if(tab.uid == comp.tableid){
        comp.lookUpTablePrimaryColumn = tab["Primary_Column"]
        comp.formGeneration = tab.forms
      }
    }
    if(res && res.value && res.value.length>0){
      comp.allTableData = res.value
      for(let i of res.value){
        comp.options.push({value: i[comp.primary_column],id: i[comp.lookUpTablePrimaryColumn],hint: i[comp.hint_column]})
        comp.amountsList.push({value:i[comp.primary_column],amount:i["eng_amount"]})
      }
    }
    comp.savedOptions = JSON.parse(JSON.stringify(comp.options))

    comp.selectedId = this.rowData ? this.rowData[comp.relatedid] : ''
    comp.icon = comp.calculated ? this.theme["form-calculated_field_icon"] : this.theme["form-lookup_field_icon"]
    this.storeAllLookUps.push(comp)
  }

  onFileSelected(event: any,comp: any){
    this.fileComp = JSON.parse(JSON.stringify(comp))
    let file = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result ? (reader.result).toString().replace('data:', '').replace(/^.+,/, '') : ''
      this.fileComp.value = base64String
      this.fileComp.fileName = file.name
    };
  }

  valuechange(e :  any,comp ?: any){
    let val = this.formCaptureSerive.onFieldChange(this.tableId,this.formDetails[0].uid, e,comp,this.formData)
    // if(val && val == 'reset'){
    //   this.formLoaded = false
    //   for(let i of this.formData){
    //     for(let sec of i.section){
    //       for(let com of sec.components){
    //         if(com.id && com.id == "new_couponcode"){
    //           com.selectedItem = []
    //           com.dropDownForm = this.fb.group({
    //             myItems: [com.selectedItem]
    //           });
    //           this.formLoaded = true
    //         }
    //       }
    //     }
    //   }
    // }
  }

  onItemSelect(comp: any,item: any){
    if(!comp.allomutiple){
      comp.selectedItem = []
    }
    comp.selectedItem.push(item)
    this.valuechange(item,comp)
    this.applyMultiSelectCss()
  }
  onSelectAll(comp:any,items: any){
    comp.selectedItem = items
    this.applyMultiSelectCss()
  }
  onItemDeSelect(comp:any,item: any){
    for(let i=0;i< comp.selectedItem.length;i++){
      if(comp.selectedItem[i].item_id == item.item_id){
        comp.selectedItem.splice(i,1)
      }
    }
    this.valuechange(item,comp)
    this.applyMultiSelectCss()
  }
  onUnSelectAll(comp: any){
    this.applyMultiSelectCss()
    comp.selectedItem = []
  }
  callMethod(e: any, comp: any){
    this.applyMultiSelectCss()
    this.valuechange(e,comp)
  }
  compareFn(a: any,b: any){
    return a && b && a.key == b.key;
  }
  compareFnLookup(a : any, b: any){
    return a && b && a.value == b.value;
  }

  applyMultiSelectCss(){

    let elem5 = document.getElementsByClassName('selected-item')

    if(elem5){
      for(let i=0;i< elem5.length;i++){
        (<HTMLElement>elem5[i]).style.borderColor = this.theme["main-header_clr"];
        (<HTMLElement>elem5[i]).style.backgroundColor = this.theme["main-header_clr"]
      }
    }

    let elem7 = document.getElementsByClassName('dropdown-btn');
    if(elem7){
      for(let i=0;i< elem7.length;i++){
        (<HTMLElement>elem7[i]).style.borderColor = this.theme["form-section_text_border_clr"];
        (<HTMLElement>elem7[i]).style.setProperty('--disablebackgroundColor',this.theme["form-section_text_disabled_clr"] );
      }
    }

    let elem6 = document.querySelectorAll('input[type=checkbox] + div');
    if(elem6){
      for(let i=0;i< elem6.length;i++){
        (<HTMLElement>elem6[i]).style.setProperty('--borderColor',this.theme["main-header_clr"] );
      }
    }

    let elem8 = document.querySelectorAll('input:checked + div');
    if(elem8){
      for(let i=0;i< elem8.length;i++){
        (<HTMLElement>elem8[i]).style.setProperty('--backgroundColor',this.theme["main-header_clr"] );
      }
    }
    this.cdr.detectChanges()
  }

  selectedLookup(option : any,comp: any){
    if(option){
      comp.selectedOptionLabel = option
      comp.selectedId = option.id
    }
    else{
      this.resetCalculations(comp)
    }
    this.valuechange(option,comp)
  }

  resetCalculations(comp: any){
    comp.selectedOptionLabel = {
      value: undefined,
      id : undefined
    }
    comp.options = JSON.parse(JSON.stringify(comp.savedOptions))
  }

  validateAmount(comp : any){
    comp.error  = false
    comp.errorMessage = undefined
    let myValue = comp.value
    comp.value = (Math.round(myValue * 100) / 100).toFixed(2);
    if(myValue  < 0){
      comp.error  = true
      comp.errorMessage = "Amount cannot be negative"
    }
  }

  validatePhoneNumber(e: any, comp?: any){
    if(comp.value){
      let elem = (comp.value).toString()
      if(elem.length< comp.minLength){
        comp.errorMessage = "Minimum 10 digits allowed"
      }
      else if(elem.length>comp.maxLength){
        elem = elem.slice(0,comp.maxLength)
        comp.value = Number(elem)
        e.target.value = comp.value
      }
      else{
        comp.errorMessage = ''
      }
    }
  }

  onConfirmClick(formClose: boolean,reset?: boolean){
    this.disableButton = true
    this.saveFormData(formClose, reset)
  }

  saveFormData(formClose : boolean,reset?: boolean){
    this.showLoader = true
    let emptyField = 0
    let invalidField = 0
    let emptyFieldLabels = ''
    let invalidFieldLables = ''
    let payload : {[key: string]: any} = {}
    let rowId =  this.rowData ? this.rowData[this.primaryColumn] : null
    for(let i of this.formData){
      for(let sec of i.section){
      for(let comp of sec.components){
          if(comp.visible && comp.datatype !== 'address_auto_complete'){
            comp.error = false
            if(comp.type == "field" && !(comp.id).includes('assignedon') &&  !(comp.id).includes('createdon') && !(comp.id).includes("FormattedValue")){
              if(comp.id == this.ownerName){
                comp.value = comp.value ? comp.value : this.userName
              }
              if(comp.datatype == "number"){
                comp.value = comp.value ? comp.value.toString() : null
              }
              if(comp.datatype == "mobile"){
                comp.value = comp.value ? comp.value.toString() : null
              }
              if(comp.datatype == "amount"){
                  if(comp.value == null){
                    comp.value = Number("0.00")
                  }
                  else{
                    comp.value = Number(comp.value)
                  }
              }
              if(comp.datatype == "file"){
                comp.value = comp.value ? comp.value : null
              }
              if(comp.datatype == "optionset"){
                comp.value = comp.selectedOptionLabel.value ? Number(comp.selectedOptionLabel.value) : null
              }
              if(comp.datatype == "multioptionset"){
                comp.value=''
                for(let i of comp.selectedItem){
                  comp.value += i.item_id + ','
                }
                comp.value = comp.value=='' ? comp.value.substring(0,comp.value.length-1) : null
              }
              if(comp.datatype == "lookup"){
                comp.value = comp.selectedOptionLabel
                if(comp.selectedOptionLabel.id){
                  payload[comp.id+"@odata.bind"] = "/"+comp.lookupTableName+"("+comp.selectedOptionLabel.id+")"
                }
                else{
                  // payload["_"+comp.id.toLowerCase()+"_value"] = null
                  payload[comp.id+"@odata.bind"] = null
                }
                if((comp.value == null || !comp.value.value) && comp.required && comp.visible){
                  comp.error = true
                  emptyField++
                  emptyFieldLabels += comp.label + ','
               }
              }
              else{
                payload[comp.id] = comp.value != "" ? comp.value : null
              }
            }
            if(comp.id && (comp.id).includes("FormattedValue")){
              if(comp.datatype == "optionset"){
                let id = comp.id.split('@')[0]
                comp.value = comp.selectedOptionLabel.value
                payload[id] = comp.selectedOptionLabel.value ? Number(comp.selectedOptionLabel.value) : null
              }
              else{
                let id = comp.id.split('@')[0]
                payload[id] = comp.value
              }
            }
            
            if(comp.datatype == "multioptionset" && (comp.id).includes("FormattedValue")){
              let id = comp.id.split('@')[0]
              comp.value=''
              for(let i of comp.selectedItem){
                comp.value += i.item_id + ','
              }
              payload[id] = comp.value ? comp.value.substring(0,comp.value.length-1) : null
            }
            if(comp.required && (comp.value == "" ||comp.value == null) && comp.visible){
              comp.error = true
              emptyField++
              emptyFieldLabels += comp.label + ','
            }
            if(comp.errorMessage){
              invalidField++
              invalidFieldLables += comp.label + ','
            }
          }
          if(comp.datatype == "coupon"){
            let id = comp.id
            comp.value=''
            for(let i of comp.selectedItem){
              comp.value = i.item_id
            }
            payload[id] = comp.value
          }
          if(comp.id && (comp.id).includes("assignedon") && this.newButton){
            let date = new Date();
            payload[comp.id.split('@')[0]] = ( moment(date).format('MM-DD-YYYY')).toString()
          }
      }
    }
    }
    if(emptyField == 0 && invalidField == 0){
      if(this.saveButtonAction == "create"){
        if(this.ownerId!==''){
          payload[this.ownerId] = this.userId
        }
        if(this.ownerName!==''){
          payload[this.ownerName] = this.userName
        }
        
        payload[this.createBuField.key] = this.createBuField.value
        payload[this.createStatus.key] = this.createStatus.value
        payload[this.createStatusReason.key] = this.createStatusReason.value
        this.tableOperationService.createTableRecords(this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True'){
            this.toastr.success("Details saved successfully")
            if(this.callDeleteFile){
              this.callDeleteFileService(formClose)
            }
            else if(this.fileComp){
              this.callSaveFile(res.id,formClose)
            }
            else if(formClose){
              this.disableButton = false
              payload['id'] = res.id
              this.closeForm(true,formClose,payload)
            }
            else{
              if(this.selectedView && res.id){
                this.fetchRecordInEditMode(res.id,this.selectedView.DataApi,this.primaryColumn,reset)
              }
            }
          }
          else{
            this.toastr.error("Failed to save details")
            this.disableButton = false
          }
          this.showLoader = false
      })
     }
    else if(this.saveButtonAction == "update"){
      this.commonService.logging(payload)
      this.tableOperationService.updateTableRecords(rowId,this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            if(this.callDeleteFile){
              this.callDeleteFileService(formClose)
            }
            else if(this.fileComp){
              this.callSaveFile(res.id,formClose)
            }
            else if(formClose){
              this.toastr.success("Details updated successfully")
              this.disableButton = false
              this.closeForm(true,formClose)
              this.showLoader = false
            }
            else{
              if(this.selectedView && this.rowData){
                this.toastr.success("Details updated successfully")
                this.fetchRecordInEditMode(this.rowData[this.primaryColumn],this.selectedView.DataApi,this.primaryColumn,reset)
                this.showLoader = false
              }
            }
            if(this.fileComp){
              this.callSaveFile()
            }
          }
          else{
            this.toastr.error("Failed to update details")
            this.disableButton = false
            this.showLoader = false
          }
        })
      }
    }
    else{
      if(emptyField > 0){
        this.showLoader = false
        emptyFieldLabels.substring(0,emptyFieldLabels.length-1)
        this.toastr.warning(emptyField + " Required fields are missing" + " "+emptyFieldLabels, '', { timeOut: 5000 })
      }
      else if(invalidField > 0){
        this.showLoader = false
        invalidFieldLables.substring(0,invalidFieldLables.length-1)
        this.toastr.warning(invalidField + " Invalid fields are present" + " "+invalidFieldLables, '', { timeOut: 5000 })
      }
      this.disableButton = false
    }
  }

  fetchRecordInEditMode(rowId: string,api: string,primary_column:string,newForm?:boolean,comp?:any){
    let primaryPayload = primary_column+"="+rowId
    let fetchApi = ''
    for(let table of this.allTables){
      if(this.tableId == table.uid){
        fetchApi = table.DataSetApi
      }
    }
    if(newForm){
      this.rowData = undefined
      this.newButton = true
      this.disableButton = false
      this.emitRowAdd.emit(true)
      this.assignValuesToForm()
      // comp.lookupOpen = true
    }
    else{
      this.detailService.getTableDataById(this.businessId.replace(/-/g,'_'),fetchApi,this.commonService.getAuthToken(),primaryPayload).subscribe((res)=>{
        if(res && res.value && res.value.length>0){
          if(newForm){
            comp.clickedRowData = res.value[0]
            comp.tableRowClicked = true
            // comp.lookupOpen = true
          }
          else{
            this.rowData = res.value[0]
            this.saveButtonAction = "update"
            this.newButton = false
            this.setFormStatusAndReason()
            this.assignValuesToForm()
          }
          this.disableButton = false
          this.closeForm(true,false)
        }
        else{
          this.showLoader = false
          this.toastr.error('Failed to refresh form')
        }
      })
    }

  }

  callSaveFile(id?:any,close ?: boolean){
    this.fileComp.payload = {
      "type":"upload",
      "tableLogicalName":this.tableLogicalName,
      "recordId":this.rowData ? this.rowData[this.primaryColumn] : id,
      "fieldLogicalName":this.fileComp.id,
      "fileName":this.fileComp.fileName,
      "fileBytes":this.fileComp.value
    }
    this.tableOperationService.fileOperationOnRecord(this.fileComp.payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(close){
        this.toastr.success("Details updated successfully")
        this.disableButton = false
        this.closeForm(true,close)
        this.showLoader = false
      }
    })
  }

  callDeleteFileService(close ?: boolean){
   let payload = {
      "type":"delete",
      "fileid": this.fileStored
    }
    this.tableOperationService.fileOperationOnRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(close){
        this.toastr.success("Details updated successfully")
        this.disableButton = false
        this.closeForm(true,close)
        this.showLoader = false
      }
    })
  }

  downloadFile(comp: any){
    let payload = {
      "type":"download",
      "tableLogicalName":this.tableLogicalName,
      "recordId":this.rowData ? this.rowData[this.primaryColumn] : '',
      "fieldLogicalName":comp.id,
    }
    this.tableOperationService.fileOperationOnRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
      if(res.result){
        let elem = 'data:application/octet-stream;base64,' + res.result
        const downloadLink = document.createElement('a');
        downloadLink.href = elem;
        downloadLink.download = comp.fileName;
        downloadLink.click();
      }
      else{
        this.toastr.error("Failed to download file")
      }
    })
  }

  removeFile(comp : any){
    if(comp.value){
      this.fileStored = JSON.parse(JSON.stringify(comp.value))
      comp.value = null
      this.fileComp = null
      this.callDeleteFile = true
    }
  }

  changeActiveCss(e?: any){
    if(e){
      this.selectedTab = e.index == 0 ? 'General' : 'Timeline'
    }
    if(this.theme){
      let elem = document.getElementsByClassName('mat-tab-label')
      for(let i=0;i<elem.length;i++){
        if(elem[i]){
          (<HTMLElement>elem[i]).style.color = this.theme["form-tab_header_noselect_clr"]
        }
      }

      let elem1 = document.getElementsByClassName('mat-tab-label mat-tab-label-active')
      if(elem1){
        for(let i=0;i< elem1.length;i++){
          (<HTMLElement>elem1[i]).style.color = this.theme["form-tab_header_onselect_clr"]
        }
      }
      let elem2 = document.getElementsByClassName('mat-ink-bar')
      if(elem2){
        for(let i=0;i< elem2.length;i++){
          (<HTMLElement>elem2[i]).style.backgroundColor = this.theme["form-tab_header_onselect_clr"]
        }
      }
    }
  }

  changeToggleCss(comp : any){
    let elem6 = document.getElementsByClassName('mat-slide-toggle-thumb')
    if(elem6){
      for(let i=0;i< elem6.length;i++){
        (<HTMLElement>elem6[i]).style.backgroundColor = comp.value ? this.header_image_name_clr : '#FFFFFF'
      }
    }

    let elem7 = document.getElementsByClassName('mat-slide-toggle-bar')
    if(elem7){
      for(let i=0;i< elem7.length;i++){
        (<HTMLElement>elem7[i]).style.backgroundColor = comp.value ? this.header_image_name_clr : 'rgba(0,0,0,.38)'
      }
    }
  }
  passObject(comp : any){
    this.windowOpen++;
    localStorage.setItem('comp'+this.windowOpen, JSON.stringify(comp));
    this.router.navigate([]).then(result => {  window.open(`/showDetails/${this.windowOpen}/comp`, '_blank'); });
  }

}
