import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { User } from '../../models/User';
import { CommonService } from '../../services/common.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input()
  user : any

  userImage : string = "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"

  fullName !: string;
  email !: string;
  mobile !: string
  address !:string;
  dob !: string;
  gender !: string;
  idNumber !: string;
  company !: string;
  role !: string
  subscription !: string
  startDate !: string
  endDate !: string
  subscriptionDays !: number

  constructor(private commonService : CommonService,private dialogRef: MatDialogRef<ProfileComponent>) { }

  ngOnInit(): void {
    this.commonService.currentUser$.subscribe((u : any)=>{
      if(u){
        this.fullName = u.cre57_name
        this.email = u.cre57_email
        this.mobile = u.cre57_mobile
        this.idNumber = u.cre57_idno
        this.company = u.cre57_company
        this.role = u.cre57_role
        this.address = u.cre57_addressline1+" "+u.cre57_addressline2+" "+u.cre57_cityt
        +" "+u.cre57_state+" "+u.cre57_country+","+u.cre57_zip

        let dt = new Date(u.cre57_dob)
        let dtMonth = dt.getMonth()+1
        this.dob = dt.getDate()+"/"+dtMonth+"/"+dt.getFullYear()

        if(u.cre57_gender == "647510001"){
          this.gender = "Female"
        }
        else if(u.cre57_gender == "647510000"){
          this.gender="Male"
        }
        else{
          this.gender="Other"
        }
        if(u.cre57_image){
          this.userImage = 'data:image/jpg;base64,' + u .cre57_image
        }
        else{
          if(this.gender=="Female"){
            this.userImage =  "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2.webp"
          }
          else{
            this.userImage="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
          }
          // else if(this.gender=="Other"){
          //   this.userImage = "src/assets/Images/genderNeutralAvatar.png"
          // }
        }
      }
    })

    let userProfile = localStorage.getItem('userProfile')
    if(userProfile){
      let userSubscription = JSON.parse(userProfile).subcriptions[0]
      this.subscription = userSubscription.Name
      this.startDate = moment(userSubscription.StartDate).format('DD-MMM-YYYY');
      this.endDate = moment(userSubscription.EndDate).format('DD-MMM-YYYY');
      let currentDate = new Date()
      let Difference_In_Time = new Date(userSubscription.EndDate).getTime() - currentDate.getTime();
      this.subscriptionDays = Difference_In_Time / (1000 * 3600 * 24);
      this.subscriptionDays = Number(~~this.subscriptionDays)+1
    }
  }

  closeProfile(): void {
    this.dialogRef.close(true);
  }

}
