import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild , Input} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit {

  @ViewChild('formDrawer') formDrawer !: ElementRef<HTMLElement>;


  @Input()
  formDetails !: any

  @Input()
  rowData !: any

  @Input()
  newButton !: boolean

  @Input()
  dbTableName !: string

  @Input()
  token !: string

  @Input()
  primaryColumn !: string

  @Input()
  userId !: string

  @Input()
  tableId !: string

  @Input()
  formTemplate !: string

  formButton : any[] =  []
  showButtons :  any[] = []
  visibleButton : any = []
  dropdownButton : any = []
  saveButtonAction !: any

  showLoader = false

  myForm !: any
  buttonClicked = false

  @Output()
  emitCloseForm = new EventEmitter<boolean>();

  constructor(private _el : ElementRef,private commonService : CommonService,
    private tableOperationService : TableOperationService,
    private dialog: MatDialog,
    private toastr : ToastrService) { }

  ngOnInit(): void {

    if(this.rowData){
      this.saveButtonAction = "update"
    }
    else{
      this.saveButtonAction = "create"
    }

    this.formButton = this.commonService.sortDataBySequence(this.formDetails[0].formRibbon)
    for(let b of this.formButton){
      b.show = true
    }

    if(this.formTemplate == ""){
      this.formButton = this.tableOperationService.removeShareButton(this.formButton)
    }

    if(this.newButton){
      for(let b of this.formButton){
        if(b.actionApi == "Save"){
          b.show = true
        }
        else{
          b.show = false
        }
      }
    }
    if(this.formButton.length>2){
      this.visibleButton = this.formButton.slice(0,2)
      this.dropdownButton = this.formButton.slice(2)
    }
    this.myForm = JSON.parse(this.formDetails[0].Layout).values
    for(let i of this.myForm){
      i.value = this.rowData ? this.rowData[i.id] : ""
      if(i.type=="phone"){
        i.type="pno"
        i.minLength = "10"
        i.maxLength = "10"
      }
    }
  }

  ngAfterViewInit(): void {
    const elem = this._el.nativeElement.children[0]
    if(elem){
      elem.style.right= 5 + 'px'
      this.formDrawer.nativeElement.style.height = '92.5vh'
    }
    this.formDrawer.nativeElement.style.width = '100vw'
  }

  closeForm(){
    this.emitCloseForm.emit(this.buttonClicked)
  }

  getFormForView(elem : any){
    this.commonService.logging(elem)
  }

  performFormEvent(button : any){
    this.buttonClicked = true
    if(button.actionApi == "Save"){
      this.showLoader = true
      let payload : {[key: string]: string} = {}
      let rowId = ""
      for(let f of this.myForm){
        if(f.id != this.primaryColumn){
          payload[f.id] = f.value != "" ? f.value : null
        }
        else{
          rowId = f.value
        }
      }
      if(this.saveButtonAction == "create"){
        this.tableOperationService.createTableRecords(this.token,this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True'){
            this.toastr.success("Details saved successfully")
          }
          else{
            this.toastr.error("Failed to save details")
          }
          this.showLoader = false
        })
      }
      else if(this.saveButtonAction == "update"){
        this.tableOperationService.updateTableRecords(rowId,this.token,this.dbTableName,payload).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Details updated successfully")
          }
          else{
            this.toastr.error("Failed to update details")
          }
          this.showLoader = false
        })
      }
    }
    else if(button.actionApi == "Delete"){
      let rowId = ""
      for(let f of this.myForm){
        if(f.id == this.primaryColumn){
          rowId = f.value
        }
      }
      this.openDialog(rowId)
    }
    else if(button.actionApi == "Share"){
      let info = localStorage.getItem("primaryContactInfo")
      let primarInfo = ""
      if(info){
        primarInfo = JSON.parse(info)
      }
      this.openShareDialog(primarInfo)
    }
  }

  openShareDialog(primaryInfo : any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Share',
        cancel: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {
        this.showLoader = true
        let whatsapp = "", email = "", to_email = "", to_mobile = ""

        if(selectedContact.isEmailChecked && selectedContact.email){
          email = "true"
          to_email = selectedContact.email
        }

        if(selectedContact.isMobileChecked && selectedContact.mobile){
          whatsapp = "true"
          to_mobile = selectedContact.mobile
        }

        let data_id = ""
        if(this.myForm){
          for(let i of this.myForm){
            if(i.id == this.primaryColumn){
              data_id = i.value
            }
          }
        }

        let payload = {
          "loggedin_user_guid": this.userId,
          "whatsapp": whatsapp,
          "email": email,
          "to_mobile_no": to_mobile,
          "to_email": to_email,
          "table_id": this.tableId,
          "data_ids": data_id,
          "type": "form"
        }

        this.commonService.logging(payload)

        this.tableOperationService.shareTableRecord(payload,this.token).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Record shared successfully")
          }
          else{
            this.toastr.error("Failed to share record")
          }
          this.showLoader = false
        });
      })
  }

  openDialog(rowId : string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.callDeleteService(rowId)
      }
    });
  }

  callDeleteService(rowId: string){
    this.showLoader = true
    this.tableOperationService.deleteTableRecord(rowId,this.token,this.dbTableName).subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
        this.toastr.success("Record deleted successfully")
        this.closeForm()
      }
      else{
        this.toastr.error("Failed to delete record")
      }
      this.showLoader = false
    })
  }
}
