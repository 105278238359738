import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class EnrolledCourseFormCaptureService {

  constructor(private commonService: CommonService) {

  }

  onLoad(formData: any){
   this.commonService.logging("-- Enrolled Courses Form Loaded --")
   this.getTotalAmountByCourseLookUp(formData)
   this.validateEndDateGreaterThanStartDate(formData)
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Enrolled Courses')
  }

  onFieldChange(tableId : string, formId : string,formData: any,comp?:any,){
    this.commonService.logging('On field Change for Enrolled Courses')
    this.getTotalAmountByCourseLookUp(formData)
    this.validateEndDateGreaterThanStartDate(formData,comp)
  }

  onSubgridRefresh(tableId : string, formId : string,subgrid: any, formData: any, headerData: any){
    this.commonService.logging('On Subgrid Refresh for Enrolled Courses')
    this.getTotalAmountandNumberOfCourses(subgrid,formData,headerData)
    return false
  }

  getTotalAmountandNumberOfCourses(subgrid: any,formData : any,headerData : any){
    subgrid.totalCourseAmount = 0
    if(subgrid.tableData){
      for(let data of subgrid.tableData){
        if(data.eng_soldamount){
          subgrid.totalCourseAmount += data.eng_soldamount
        }
      }
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.calculated && comp.id == "eng_noofcourse"){
              comp.value = subgrid.tableData.length
            }
            if(comp.calculated && comp.id.includes('eng_noofstudents')){
              comp.value = subgrid.tableData.length
            }
            if(comp.calculated && comp.id == "eng_totalamount"){
              comp.value = subgrid.totalCourseAmount ? (Math.round(subgrid.totalCourseAmount * 100) / 100).toFixed(2) : 0
            }
          }
        }
      }
      for(let i of headerData){
        if(i.field.includes('eng_totalamount')){
          i.value = "₹" + (subgrid.totalCourseAmount ? subgrid.totalCourseAmount : 0) + ".00"
        }
      }
    }
  }

  getTotalAmountByCourseLookUp(formData : any){
    let allOption = []
    let selectedOption  : {[key: string]: string} = {}
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.datatype == "lookup" && comp.id == "eng_Course"){
            allOption = comp.amountsList
            selectedOption = JSON.parse(JSON.stringify(comp.selectedOptionLabel))
          }
        }
      }
    }
    let amount = ''
    for(let i of allOption){
      if(i.value == selectedOption.value){
        amount = (Math.round(i.amount * 100) / 100).toFixed(2)
      }
    }
    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.calculated && comp.id == "eng_amount"){
            comp.value = amount
          }
        }
      }
    }
  }

  validateEndDateGreaterThanStartDate( formData :  any,comp1?:any){
    let endDate=new Date(),startDate = new Date()

    // let elem = <HTMLInputElement>document.getElementById('Enrolled_Courses:Students_Management_System:AT-BU-1002eng_startdate@OData.Community.Display.V1.FormattedValue')
    // if(elem){
    //   startDate = new Date(elem.value)
    // }

    for(let i of formData){
      for(let sec of i.section){
        for(let comp of sec.components){
          if(comp.id.includes('eng_startdate')){
            startDate = new Date(comp.value)
            comp.errorMessage = ''
          }
          if(comp.id.includes('eng_enddate')){
            endDate = new Date(comp.value)
            comp.errorMessage = ''
          }
        }
      }
    }
    let startDateFormattted = startDate?.getTime()
    let endDateFormattted = endDate?.getTime()
    if(comp1){
      if(endDate && startDate){
        if (startDateFormattted > endDateFormattted) {
          comp1.errorMessage = "Start Date cannot be greater than End Date"
        }
        else{
          comp1.errorMessage = ''
        }
      }
    }
    else{
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id.includes('eng_enddate')){
              if(endDate && startDate){
                if (startDateFormattted > endDateFormattted) {
                  comp.errorMessage = "Start Date cannot be greater than End Date"
                }
                else{
                  comp.errorMessage = ''
                }
              }
            }
          }
        }
      }
    }
  }

}
