import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { loadingCollection } from 'src/app/app.global';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { SessionService } from 'src/app/services/session-service/session.service';
import { User } from '../../models/User';
import { CommonService } from '../../services/common.service';

const POLLING_COUNT : number = 2

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit, AfterViewInit {

  authToken !: string
  showProfile : boolean = false
  storeSettings : any
  applicationLoaded : boolean = false
  pollIntervalId !: any
  pollSessionIntervalId !: any
  count = 0
  version !: string
  website = true
  baseUrl = ''
  pageNotFound = false

  constructor(
    public commonService :CommonService,
    private _zone: NgZone,
    private sessionService:SessionService,
    private appService : ApplicationService,
    private router: Router,
    private toast : ToastrService
    ) { }

  ngOnInit(): void {
    this.commonService.logging("Home Page Component Initialized")
    this.getNewAuthToken();
    this.setCurrentUser()
    this.setCurrentToken()
    this.getVersion()
    let url = "/"+window.location.href.split('/')[window.location.href.split('/').length-1]
    if(url !== '/application' && url !== '/comp'){
      this.baseUrl = "/"+window.location.href.split('/')[window.location.href.split('/').length-1]
      localStorage.setItem('baseurl',JSON.stringify(this.baseUrl))
      localStorage.removeItem("applicationSettings")
    }
    else{
      let url = localStorage.getItem('baseurl')
      if(url){
        this.baseUrl = JSON.parse(url)
      }
    }
  }


  pollForTokenUpdate(timeLapse : any){
   clearInterval(this.pollIntervalId)
   this._zone.runOutsideAngular(()=>{
      this.pollIntervalId = setInterval(()=> this.getNewAuthToken(true),timeLapse)
   })
  }

  pollForSessionUpdate(timeLapse : any){
    clearInterval(this.pollSessionIntervalId)
    this._zone.runOutsideAngular(()=>{
       this.pollSessionIntervalId = setInterval(()=> this.checkActiveSession(),timeLapse)
    })
   }

  stopPolling(pollingId ?: any){
    this._zone.runOutsideAngular(()=>{
     if(pollingId){
      clearInterval(pollingId)
     }
     else{
      clearInterval(this.pollIntervalId)
     }
   })
    this.count=0
    this.commonService.removeToken()
    if(pollingId){
      sessionStorage.setItem('multipleSession','true')
    }
    let url = localStorage.getItem("baseurl")
    this.commonService.logoutUser()
    window.location.href =  this.baseUrl
  }


  setCurrentUser(){
    const user_string = localStorage.getItem('user')
    if (user_string) {
      const user : User = JSON.parse(user_string)
      this.commonService.setCurrentUser(user)
      let masterData = localStorage.getItem("applicationSettings")
      if(masterData){
        let setting = JSON.parse(masterData)
        this.pollForSessionUpdate(setting.session_polling_time_in_seconds*1000)
      }
    }
  }

  setCurrentToken(){
    const token_string = localStorage.getItem('token')
    if (token_string) {
      const token = JSON.parse(token_string)
      this.commonService.setCurrentToken(token)
    }
  }

  ngAfterViewInit(){
    this.pollForTokenUpdate(120000);
    // let firstRandomElement = loadingCollection[Math.floor((Math.random()*loadingCollection.length))];
    // let elem = <HTMLElement>document.getElementById("home-background")
    // elem.style.background = "url('"+firstRandomElement+"') no-repeat fixed center"
    if(window.location.href.includes("/woods-kitchen")){
      this.website = false
    }
  }

  getNewAuthToken(poll?: boolean){
    this.appService.getAuthToken().subscribe((res) => {
      if (res) {
        this.commonService.logging(res);
        this.authToken = res.token
        localStorage.setItem('token',JSON.stringify(this.authToken))
        // this.commonService.setCurrentToken(this.authToken)
        if(!poll){
          let masterData = localStorage.getItem("applicationSettings")
          if(!masterData){
            this.getApplicationSetting();
          }
          else{
            let url = window.location.href.split('/')[window.location.href.split('/').length-1]
            if((this.baseUrl!=='' && this.baseUrl!=='/') || url == 'application'){
              this.storeSettings = JSON.parse(masterData)
              this.applicationLoaded= true
            }
            else{
              this.pageNotFound=true
            }
          }
          // this.getUserActiveSession();
        }
        else{
          // console.log("no path changed")

        }
        // else{
        //   this.count++;
        //   // this.checkActiveSession()
        //   // if(this.count == POLLING_COUNT){
        //     this.stopPolling(this.pollIntervalId)
        //   }
        // }
      }
    },error =>{
      this.commonService.logging(error)
    })
  }

  getApplicationSetting(){
    if(this.baseUrl !== '' && this.baseUrl !== '/'){
      this.appService.getApplicationSetting(this.commonService.getAuthToken(), this.baseUrl).subscribe((res) => {
        if (res && res.value && res.value.length>0) {
         this.storeSettings = JSON.parse(res.value[0].cre57_homepageui.toString())
         localStorage.setItem("applicationSettings",JSON.stringify(this.storeSettings))
         this.applicationLoaded= true
        }
        else{
          this.pageNotFound = true
        }
      })
    }
    else{
      this.pageNotFound = true
    }
  }

  checkActiveSession(){
    let sessionId = JSON.parse(localStorage.getItem('session') as string)

    if(sessionId){
      this.sessionService.getActiveSessions(sessionId,this.commonService.getAuthToken()).subscribe((res) => {
        if (!res || !res.value || res.value.length == 0) {
          this.stopPolling(this.pollSessionIntervalId)
        }
      })
    }
  }

  newSessionCreated(){
    this.pollForSessionUpdate(this.storeSettings.session_polling_time_in_seconds*1000);
  }


  getVersion(){
    let d  = new Date()
    let month = d.getMonth()+1
    this.version = d.getDate()+'.'+month+'.'+d.getFullYear()+'.'+d.getHours()+'.'+d.getMinutes()
  }
}
