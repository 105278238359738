import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of as observableOf } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { SessionService } from 'src/app/services/session-service/session.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { User } from '../../models/User';
import { CommonService } from '../../services/common.service';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'checkin-checkout-component',
  templateUrl: './checkin-checkout-component.html',
  styleUrls: ['./checkin-checkout-component.scss']
})
export class CheckInCheckOutComponent implements OnInit, AfterViewInit {

  @Input()
  application !: any

  @Input()
  theme !: any


  logo !: string
  public webcamImage!: WebcamImage;


  otpNumber !: any
  showLoader : boolean = false
  showVerified: boolean = false
  errorMessage !: string
  emailId !: string
  pass !: string
  user !: User
  userProfile !: any
  currentLocation : string = ''
  lat !: any
  long !: any
  emailVerified : boolean = false
  emailAddress !: string
  modalErrorMessage = ""
  modalSuccessMessage=""
  progressBarIcon = '../../assets/Images/AT1.gif'
  themeColor !: string
  clickedButton !: string
  base64Audio = 'Your_Base64_Encoded_Audio_Data';
  audioSource!: string;

  otpForm: FormGroup = this.formBuilder.group({
    otp1: ['', [Validators.required, Validators.maxLength(1)]],
    otp2: ['', [Validators.required, Validators.maxLength(1)]],
    otp3: ['', [Validators.required, Validators.maxLength(1)]],
    otp4: ['', [Validators.required, Validators.maxLength(1)]],
    otp5: ['', [Validators.required, Validators.maxLength(1)]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private tableOperationService : TableOperationService,
    private sessionService: SessionService,
    private toast: ToastrService,
    private appService: ApplicationService) { }

  ngAfterViewInit(): void {
    let checkMultipleSession = sessionStorage.getItem('multipleSession')
    if(checkMultipleSession){
      this.toast.error('You have been logged out, Duplicate session not allowed')
      sessionStorage.removeItem('multipleSession')
    }
    let elem = document.getElementsByClassName('btn')
    if(elem && elem.length>0){
      for(let i=0; i<elem.length;i++){
        (<HTMLElement>elem[i]).style.backgroundColor = this.theme['main-header_clr']
      }
    }
    this.progressBarIcon = this.theme['progress-bar_icon']
    // this.callCssForCheckBox()
  }

  ngOnInit(): void {
    this.commonService.logging("Checkin Checkout Component initialized")
    if(this.application){
      this.logo = this.application.buIcon
    }
    // var back1 = <HTMLElement>document.getElementById("loginBackGround");
    // //back1.style.background =this.logo_details.login_background;
    // back1.style.background =this.logo_details.login_background_image + " no-repeat fixed center";
  }

  validateUser(loginForm: NgForm){
    this.errorMessage = ''
    this.showLoader = true
    this.appService.getUser(loginForm.value.email, loginForm.value.password,this.commonService.getAuthToken())
    .subscribe((res) => {
      if (res && res.value && res.value.length>0) {
        this.user = res.value[0]
        this.getUserProfile()
      }
      else{
        this.showLoader = false
        this.resetFormField();
        this.errorMessage = "Username or Password incorrect";
      }
    })
}

  getUserProfile(){
    this.commonService.getUserProfile(this.user.cre57_at_userid,this.commonService.getAuthToken()).subscribe((res)=>{
      this.commonService.logging("User Profile Fetched")
      this.user.cre57_userprofile = (res && res.json) ? res.json : this.user.cre57_userprofile
      if(this.user.cre57_userprofile){
        this.userProfile = JSON.parse(this.user.cre57_userprofile)
        let res3 = this.userProfile.subcriptions
      }
    })
  }

  closeModal() {
    let modal = document.getElementById("myOtpModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementById("mycontainer");
    if (container) {
      container.classList.remove("blur");
    }
    this.resetFormField()
  }

  confimOtp() {
    let enteredOtp = this.otpForm.value.otp1 + this.otpForm.value.otp2 + this.otpForm.value.otp3 + this.otpForm.value.otp4 + this.otpForm.value.otp5
    if (this.otpNumber == enteredOtp) {
    }
    else {
      this.showVerified = false
      this.resetOtpForm();
      this.errorMessage = "Please enter correct otp"
    }
  }

  resetOtpForm(){
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required, Validators.maxLength(1)]],
      otp2: ['', [Validators.required, Validators.maxLength(1)]],
      otp3: ['', [Validators.required, Validators.maxLength(1)]],
      otp4: ['', [Validators.required, Validators.maxLength(1)]],
      otp5: ['', [Validators.required, Validators.maxLength(1)]]
    });
  }
  resendOtp(mno: any) {
    this.showVerified = false
    this.otpNumber = Math.floor(Math.random() * 90000) + 10000;

    const otpData = {
      "cre57_name": this.otpNumber.toString(),
      "cre57_mobile": mno
    }
    this.commonService.sendSMS(otpData).subscribe((res) => {
      this.commonService.logging(res);
    })
  }

  resetFormField() {
    this.emailId=''
    this.pass = ''
    // this.loginForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', [Validators.required]]
    // });
    //
  }

  openUserNameModal(){
    let modal = document.getElementById("userNameModal");
    if (modal) {
      modal.style.display = "block";
      var testarray = document.getElementsByClassName("login-container");
      for (var i = 0; i < testarray.length; i++) {
        testarray[i].className += " blur";
      }
    }
  }

  closeUserNameModal(){
    this.emailAddress = ""
    this.modalErrorMessage = ""
    this.modalSuccessMessage = ""
    let modal = document.getElementById("userNameModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementsByClassName("login-container");
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove("blur")
    }
    this.resetFormField()
  }

  verifyEmail(){
    this.modalErrorMessage = ""
    this.showLoader = true
    if(this.emailAddress){
        this.commonService.validateUserByUserName(this.emailAddress,this.commonService.getAuthToken()).subscribe((res)=>{
          if(res && res.value && res.value.length>0){
            // console.log(res)
            // this.sendMail(res.value[0].cre57_at_userid)
          }
          else{
            this.modalErrorMessage = "Please enter valid user name"
          }
          this.showLoader = false
        })
    }
  }

  sendMail(userId : string){
    let payload = {
      "cre57_resetpassword":true
    }
    this.tableOperationService.updateTableRecords(userId,this.commonService.getAuthToken(),'cre57_at_users',payload).subscribe((res)=>{
      if(res && res.status &&  res.status == "True"){
        this.modalSuccessMessage = "An email has been sent with new password"
      }
      else{
        this.modalErrorMessage = "Failed to send email"
      }
    })
  }

  handleImage(webcamImage : WebcamImage) {
    this.webcamImage = webcamImage;
    let base64Image = this.webcamImage.imageAsDataUrl.split(',')[1]
    // if(this.clickedButton){
    //   this.callCheckInCheckOutApi(base64Image)
    // }
  }

  buttonClicked(button : any){
    this.clickedButton = button
    if(this.webcamImage){
      let base64Image = this.webcamImage.imageAsDataUrl.split(',')[1]
      this.callCheckInCheckOutApi(base64Image)
    }
  }

  callCheckInCheckOutApi(base64Image  : string){
    this.showLoader = true
    let url =   localStorage.getItem('baseurl')
    let baseUrl = url ? (JSON.parse(url).toString()).split('/')[1] : "mbp"
    let payload={
      "group":baseUrl,
      "base64":base64Image,
      "action":this.clickedButton,
    }
    this.tableOperationService.faceDetection(payload,this.commonService.getAuthToken()).subscribe((res)=>{
    if(res && res.msg){
      this.base64Audio = res.audio
      const audio = new Audio();
      audio.src = 'data:audio/wav;base64,' + this.base64Audio;
      audio.load();
      audio.play();
      if(!res.result){
        this.toast.error(res.msg)
      }
      else{
        this.toast.success(res.msg)
      }
      this.showLoader = false
    }
    else{
      this.toast.error("Failed to checkin/checkout")
      this.showLoader = false
    }
  })
  }

}
