import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApplicationDetailService } from 'src/app/services/application-detail-service/application-detail-service';
import { CommonService } from 'src/app/services/common.service';
import { EMPTY_API, GET_CHART_TYPE } from 'src/app/app.global';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { FormCaptureService } from 'src/app/services/form-capture/form-capture.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

  userId !: string
  userName !: string
  theme !: any
  roles !: any
  userRoleName !: string
  checkin = false
  promo = false
  dashboard_app_cards_color  !: any
  dashboard_app__borderline_color !: any
  dashboard_app_card_text_color !:any
  header_nav_backgroundColor !: any
  button_text_clr !: any
  view_dropdown_text_clr !: any
  page_background_clr !: any
  header_clr !: any

  tableData !: any
  dashboardData !: any
  dashboardGuid !: string
  showLoader = true
  application !:  any
  businessId !: string
  business : any
  chartCreated = false
  dashboardKPIs :  any = []
  tableDataAvailable !: boolean
  viewDataAvailable : boolean = false
  showDashboard = true
  showTable = false
  val !: any
  tableAllDataFetched = false
  windowOpen  = 0
  primaryColumn = ""
  tableTemplate = ""
  formTemplate = ""

  displayRule !: any
  layout !: any
  iconValue !: any
  tableIcon !: any
  mobileLayout !: any
  rowData !: any;
  viewList : any[] = []
  selectedView !:  any
  formGeneration : any[] = []
  quickAddFormAvailable = false
  clickedRowData !: any
  headerButton : any[] =  []
  showButtons :  any[] = []
  visibleButton : any = []
  dropdownButton : any = []
  tableId = ""
  filterTableData = false
  ownerId !: string
  checkedRows : any = []
  headerName !: any
  selectedContent : string = "Dashboard"
  selectedIcon !: string
  errorMessageDashboard !: string
  dbTableName = ""
  Table_Logical_Name = ""
  userDetails : any = []
  daysFilter : any = []
  selectedUser : string|undefined
  selectedUserJSON : any|undefined
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  endDate !: any
  dashboardPayload = {}
  tableRowClicked = false
  triggeredNewButton = false
  tableNewClicked = false
  quickCreateAvailable = false
  quickAddAvailable =  false
  comp : any = {}
  viewChoose !: any
  sideNavClose = false

  formGenerationQuick !: any
  clickedRowDataQuick!: any
  triggeredNewButtonQuick!: any
  dbTableNameQuick!: any
  primaryColumnQuick!: any
  selectedViewQuick!: any
  tableIdQuick !: string
  quickCreateAvailableForLookUp = false
  tableLookUpQuickCreate = false
  saveAndNewButton = false
  tableLogicalName !: string
  tableLogicalNameQuick !: string
  loaderIcon !: string

  @ViewChild("chart") chartDiv !: ElementRef

  constructor(private detailService: ApplicationDetailService,
    public commonService: CommonService,
    private tableOperationService : TableOperationService,
    private router: Router,
    private dialog: MatDialog,
    private formService: FormCaptureService,
    private sanitizer: DomSanitizer,
    private toastr : ToastrService) { }

  ngOnInit(): void {
      const user = localStorage.getItem('user')
      if(user){
        this.userId = JSON.parse(user).cre57_at_userid
        this.userName = JSON.parse(user).cre57_name
        let profile = JSON.parse(user).cre57_userprofile
        if(JSON.parse(profile).business){
          this.theme = JSON.parse(JSON.parse(profile).business.Theme)
          this.dashboard_app_cards_color = this.theme["dashboard_cards_clr"];
          this.dashboard_app__borderline_color = this.theme["dashboard_cards_borderline_clr"];
          this.dashboard_app_card_text_color = this.theme["dashboard_cards_text_clr"];
          this.header_nav_backgroundColor = this.theme["app-home_ribbon_clr"];
          this.button_text_clr = this.theme["dashboard-home_buttons_text_clr"];
          this.page_background_clr = this.theme["dashboard-home_background_clr"]
          this.header_clr = this.theme['main_header_text_clr']
          this.loaderIcon = this.theme['progress-bar_icon']
          this.business = JSON.parse(profile).business
        }
        if(JSON.parse(profile).roles){
          let userRoles = JSON.parse(profile).roles[0]
          this.roles = userRoles.Access
          this.userRoleName = userRoles.Name
        }
      }

      const app = localStorage.getItem('application')
      if(app){ 
        this.application = JSON.parse(app)
        this.businessId = this.application.buId
        this.tableData =  this.commonService.sortDataBySequence(this.application.tables)
        this.dashboardData = this.commonService.sortDataBySequence(this.application.dashboards)
        this.showButtons = [{Label: "Refresh", actionApi: "Refresh",Icon:  "https://i.postimg.cc/brkpf9ZD/Refresh.png", show: true}]
        this.commonService.checkUserRoles(this.roles,this.tableData)
        if(this.application.defaultitemtype == 'Table'){
          this.showTable = true
          this.showDashboard = false
          let tableGuid = '', name = ''
          for(const i of this.tableData){
            if(i.uid == this.application.defaultitemId){
              tableGuid = i.Guid
              name = i.Name
            }
          }
          this.val = {
            listOf  : 'table',
            tableId : tableGuid,
            name : name
          }
        }
        else if(this.application.defaultitemtype == 'Dashboard'){
          this.showTable = false
          this.showDashboard = true
          let tableGuid = '',name = ''
          for(const i of this.dashboardData){
            if(i.uid == this.application.defaultitemId){
              tableGuid = i.Guid
              name = i.Name
            }
          }
          this.val = {
            listOf  : 'dashboard',
            tableId : tableGuid,
            name : name
          }
        }
      }
      for(let i of this.dashboardData){
        i.clicked = true
        this.headerName = i.Name
        this.selectedIcon = i.Icon
        break
      }
      if(this.dashboardData && this.dashboardData.length>0 && this.dashboardData[0])
      this.dashboardGuid = this.dashboardData[0].Guid
      this.dashboardPayload = {
        "id": this.dashboardGuid,
        "startDate":"",
        "endDate":"",
        "ownerid":""
      }
      this.val ? this.selectedSideNav(this.val) : this.getDashboardKpi()
    

    if(this.application && (this.application.uid == 'AT-APPD-1028:AT-BU-1000' || this.application.uid == 'AT-APPD-1032:AT-BU-1007')){
      this.checkin = true
    }
    if(this.application && this.application.uid == 'AT-APPD-1030:AT-BU-1000'){
      this.promo = true
    }
    let info = localStorage.getItem("primaryUserInfo")
    if(info){
      let primarInfo = JSON.parse(info)

      for(let i of primarInfo.userDetails){
        this.userDetails.push({
          labelId : i[primarInfo.userId],
          labelName: i[primarInfo.userName]
        })
      }
    }

  }

  private createChart(chartDataRes: any,chartLabelRes: any): void {

   
    let chartType : ChartType = GET_CHART_TYPE[chartLabelRes.Chart]as ChartType

    if(chartType == 'webresource'  as ChartType){
      if(chartDataRes){
        this.dashboardKPIs.push({
          chartData:  this.sanitizer.bypassSecurityTrustHtml(chartDataRes),
          chartType: chartType,
          chartLabels: chartLabelRes.Label,
          Id: chartLabelRes.Id
        })
      }
    }
    else{
      let json = chartDataRes ? JSON.parse(chartDataRes) : {}
      let chartPlugins
      let chartLegend = true
      let chartOptions :ChartOptions = {

      };

    chartOptions = {
      responsive: true,
      scales: { xAxes: [{
        ticks: {
          min: 0
        }
      }], yAxes: [{
        ticks: {
           min: 0
         }
     }] },
    };
    if(chartType == 'pie' || chartType  == 'doughnut'){
      chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          datalabels: {
            formatter: (value : any, ctx:any) => {
              if (ctx.chart.data.labels) {
                return ctx.chart.data.labels[ctx.dataIndex];
              }
            },
          },
        }
      };
    chartPlugins = [ DatalabelsPlugin ];

    }
    let datasets = json.datasets

    if(chartType == 'value' as ChartType){
      datasets = json
    }
    if(datasets){
      this.dashboardKPIs.push({
        label: chartLabelRes.Label,
        chartData: datasets,
        chartType: chartType,
        chartLabels: json.labels,
        chartLegend: chartLegend,
        chartOptions: chartOptions,
        plugins: chartPlugins,
        seq: chartLabelRes.seq,
        Id: chartLabelRes.Id
      })
      // console.log(this.dashboardKPIs)
    }
    if(this.dashboardKPIs && this.dashboardKPIs.length>0){
      this.dashboardKPIs = this.commonService.sortDataBySequence(this.dashboardKPIs)
    }
    }
    
  }

  private createTableInsights(){

  }


  private createChartNew(chartDataRes: any,chartLabelRes: any): void {
    let chartData : ChartDataSets[] = []
    let chartType : ChartType = GET_CHART_TYPE[chartLabelRes.Chart]as ChartType
    let chartLabels: Label[] = []
    let chartLegend = true
    let chartOptions : ChartOptions = {
      responsive: true,
    };


    let val = JSON.parse(chartLabelRes.ChartSetting.toString())
    const data: any[] = [];

    let label = val.y_label
    if(chartType === 'bubble'){
      let x = val.x
      let y = val.y
      let r = val.r
      for(let i of chartDataRes.value){
        chartData.push({x: i[y],y: i[y],r: i[y]/100} as ChartDataSets)
      }
    }
    else if( chartType === "scatter"){
      let x = val.x
      let y = val.y
      for(let i of chartDataRes.value){
        chartData.push({x: i[y],y: i[y]} as ChartDataSets)
      }
    }
    else{
      let labelName = val.name
      let dataName = val.y
      for(let i of chartDataRes.value){
        chartLabels.push(i[labelName])

        chartData.push(i[dataName])
      }
    }
  let finalData
  if(chartType == "pie" || chartType == "doughnut"){
    finalData = [{data : chartData}]
  }
  else{
    finalData = [{data : chartData,label:label}]
  }

    this.dashboardKPIs.push({
      label: chartLabelRes.Label,
      chartData: finalData,
      chartType: chartType,
      chartLabels: chartLabels,
      chartLegend: chartLegend,
      chartOptions: chartOptions,
      Id: chartLabelRes.Id
    })
  }

  selectedUserData(elem:any){
    this.selectedUserJSON = elem
  }

  selectedTimeline(elem : any){
    if(elem){
      if(this.range.value){
        this.endDate = new Date()
        this.range.value.end = new Date()
        if(elem.timieline == 0){
          this.range.value['start'] = new Date()
        }
        if(elem.timieline == 15){
          let d = new Date();
          d.setDate(d.getDate() - 15);
          this.range.value['start'] = d
        }
        else if(elem.timieline == 30){
          let d = new Date();
          d.setDate(d.getDate() - 30);
          this.range.value['start'] = d
        }
        else if(elem.timieline == 90){
          let d = new Date();
          d.setDate(d.getDate() - 90);
          this.range.value['start'] = d
        }
        else if(elem.timieline == 180){
          let d = new Date();
          d.setDate(d.getDate() - 180);
          this.range.value['start'] = d
        }
      }
    }
    else{
      this.range.value.start = null
      this.range.value.end = null
      this.endDate = null
    }
    
  }

  applyDashboardFilter(){
    this.showLoader = true
    let startDate = ''
    let endDate = ''
    if(this.range.value){
      startDate = moment(this.range.value.start).format('YYYY-MM-DD');
      endDate = moment(this.range.value.end).format('YYYY-MM-DD');
    }
    this.dashboardPayload = {
      "id": this.dashboardGuid,
      "startDate":startDate,
      "endDate":endDate,
      "ownerid":this.selectedUserJSON ? this.selectedUserJSON.labelId : ''
    }
    if(startDate!=='' && endDate!==''){
      this.getDashboardKpi()
    }
  }

  resetDashboardFilter(){
    this.showLoader = true
    this.dashboardPayload = {
      "id": this.dashboardGuid,
      "startDate":"",
      "endDate":"",
      "ownerid":""
    }
    this.selectedUser = undefined
    this.selectedUserJSON = undefined
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.getDashboardKpi()
  }



  getDashboardKpi(){
    this.dashboardKPIs = []
    this.daysFilter = []
    let currentKpis !: any
    this.daysFilter.push({labelName : 'Today',timieline:0})
    this.daysFilter.push({labelName : 'Last 15 days',timieline:15})
    this.daysFilter.push({labelName : 'Last 30 days',timieline:30})
    this.daysFilter.push({labelName : 'Last 3 months',timieline:90})
    this.daysFilter.push({labelName : 'Last 6 months',timieline:180})
    for(let k of this.dashboardData){
      if(this.dashboardGuid == k.Guid){
        currentKpis = k.kpis
      }
    }
    if(this.dashboardData && this.dashboardData.length>0 && currentKpis && currentKpis.length>0){
      this.detailService.createDashboardFromKPI(this.dashboardGuid,this.dashboardPayload,this.commonService.getAuthToken()).subscribe((res)=>{
        this.showLoader  = false
        if(res && res.json){
          let data = JSON.parse(res.json)
          for(let i in data.KPIs){
            for(let k of currentKpis){
              if(k.Id == i){
                this.createChart( data.KPIs[i].chart_setting,k);
              }
            }
          }
        }
        else{
          this.errorMessageDashboard = "No dashboard KPIs data available"
          this.chartCreated = true
          this.showLoader = false
        }
      })

    }
    else{
      this.errorMessageDashboard = "No dashboard KPIs available"
      this.chartCreated = true
      this.showLoader = false
    }

  }


  selectedSideNav(val : any){
    this.showButtons = []
    this.visibleButton = []
    this.dropdownButton = []
    this.showLoader = true
    this.headerName = val.name
    this.viewDataAvailable = false
    this.quickCreateAvailable = false
    this.quickAddAvailable = false
    this.tableRowClicked = false
    this.errorMessageDashboard  = ""
    this.selectedContent = (val.listOf == "dashboard") ? "Dashboard" : "Data" 
    if(val.listOf=="table"){
      this.showTable = true
      this.showDashboard = false
      this.filterTableData  = false
      this.button_text_clr = this.theme["app-home_buttons_text_clr"]
      this.view_dropdown_text_clr = this.theme["nav-background_clr"]
      this.page_background_clr = this.theme["app-home_background_clr"]
      for(const i of this.tableData){
        if(i.Guid == val.tableId){
          this.primaryColumn = i.Primary_Column
          this.tableTemplate = i.TableTemplate
          this.formTemplate = i.FormTemplate
          this.viewList =   this.commonService.sortDataBySequence(i.views)
          this.formGeneration = this.commonService.getDefaultFormFirst(i.forms)
          this.quickAddFormAvailable = this.commonService.checkQuickFormExist(i.forms)
          this.tableIcon = i.Icon
          this.selectedIcon = i.Icon
          this.tableId = i.uid
          this.dbTableName = i.Data_Entity_Set
          this.Table_Logical_Name = i.Table_Logical_Name
          this.filterTableData = i.filterUserRecords
          this.ownerId = i.OwnerId
          this.tableLogicalName = i.Table_Logical_Name
          this.headerButton = this.commonService.sortDataBySequence(i.tableRibbons)
          if(this.tableTemplate ==""){
           this.headerButton = this.tableOperationService.removeShareButton(this.headerButton)
          }
          this.resetButtons()
          this.selectedView = this.viewList ? this.viewList.length>0 ? this.viewList[0] : undefined : undefined
          if(this.selectedView){
            this.getTableForView(this.selectedView)
          }
          else{
            this.tableDataAvailable = false
            this.errorMessageDashboard = "No view available"
            this.showLoader = false
            this.showDashboard = false
          }
          this.comp = {
          "formGeneration": this.formGeneration,
          "dbTableName" : this.dbTableName,
          "tableid" : this.tableId,
          "primary_column":  this.primaryColumn
          }
        }
      }
    }
    else{
      this.showDashboard = true
      this.showTable = false
      this.button_text_clr = this.theme["dashboard-home_buttons_text_clr"];
      this.page_background_clr = this.theme["dashboard-home_background_clr"]
      this.showLoader = false
      this.dashboardGuid = val.tableId
      this.resetDashboardFilter()
      this.showButtons = [{Label: "Refresh", actionApi: "Refresh",Icon:  "https://i.postimg.cc/brkpf9ZD/Refresh.png",show: true}]
      let currentKpis
      if(this.dashboardData){
        for(let k of this.dashboardData){
          if(this.dashboardGuid == k.Guid){
            currentKpis = k.kpis
            this.selectedIcon = k.Icon
          }
        }
      }

      if(!this.dashboardData || !(this.dashboardData.length>0) || !currentKpis || !(currentKpis.length>0)){
        this.errorMessageDashboard = "No dashboard KPIs available"
        this.chartCreated = true
      }
    }
  }

  sideNavStatus(val : boolean){
    let elem = document.getElementById('app-container')
    if(elem){
      elem.style.width = val ? 'calc(100% - 210px)' : '100%'
      elem.style.marginLeft = val  ? '210px' : '0px'
    }
    let elem2 = document.getElementById('angular-table')
    if(elem2){
      elem2.style.width = val ? 'calc(100% - 210px)' : '100%'
    }
    let elem4 = document.getElementsByClassName('search')
    if(elem4 && elem4.length>0){
      (<HTMLElement>elem4[0]).style.right = val ? '235px' : '25px'
    }
    let elem3 = document.getElementsByClassName('header-nav')
    for(let i = 0;i<elem3.length;i++){
      if(elem3[i]){
       (<HTMLElement>elem3[i]).style.width = val ? 'calc(100% - 210px)' : '100%'
      }
    }
    this.sideNavClose = !val
  }

  getTableForSelectedView(event : any){
    this.resetButtons()
    for(let view of this.viewList){
      if(view.Name == event.target.value){
        this.selectedView = view
        this.getTableForView(view)
      }
    }
  }

  getTableForView(view : any){
    this.viewChoose = JSON.parse(JSON.stringify(this.selectedView))
    this.showLoader = true
    this.tableDataAvailable = false
    if(EMPTY_API.includes(view.DataApi) || view.DataApi == ""){
      this.tableDataAvailable = false
      this.errorMessageDashboard = "No table data available in this view"
      this.showLoader = false
      this.viewDataAvailable = true
      this.showDashboard = false
    }
    else{
      this.errorMessageDashboard = ""
      this.displayRule = view.Displayrule ? JSON.parse(view.Displayrule.toString()) : []
      this.layout =  view.Layout ? JSON.parse(view.Layout.toString()).values : []
      this.iconValue = JSON.parse(view.Layout.toString()).icon ? JSON.parse(view.Layout.toString()).icon : false 
      this.mobileLayout = this.layout.slice(0,2)
      let app_name = this.businessId.replace(/-/g,'_')
      this.resetButtons()
      let payload = 'top=100'
      if((view.Name.toLowerCase().trim()).startsWith('my')){
        payload += "="+this.ownerId+"="+this.userId
      }
      this.tableAllDataFetched = false
      this.detailService.getTableData(app_name,view.DataApi,this.commonService.getAuthToken(),payload).subscribe((res)=>{
        if(res && res.value && res.value.length>0){
          this.setTableData(res,false)
          this.getAllTableData(app_name,view)
          // this.showLoader = true
        }
        else{
          this.tableDataAvailable = false
          this.errorMessageDashboard = "No table data available in this view"
          this.showLoader = false
          this.viewDataAvailable = true
          this.showDashboard = false
        }
      })
    }
  }


  // getAll(view :  any){
  //   this.displayRule = view.Displayrule ? JSON.parse(view.Displayrule.toString()) : []
  //   this.layout =  view.Layout ? JSON.parse(view.Layout.toString()).values : []
  //   this.mobileLayout = this.layout.slice(0,2)
  //   let app_name = this.businessId.replace(/-/g,'_')
  //   this.resetButtons()
  //   this.getAllTableData(app_name,view)
  // }
  getAllTableData(app_name : string,view:any){
    let payload = this.userId
    if((view.Name.toLowerCase().trim()).startsWith('my')){
      payload = this.ownerId+"="+this.userId
    }

    this.detailService.getTableData(app_name,view.DataApi,this.commonService.getAuthToken(),payload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        // if(res.value.length !== this.rowData.length){
          this.setTableData(res,true)
        // }
      }
    })
  }

  setTableData(res:any,allData: boolean){
    if(res["@odata.context"]){
      let str = res["@odata.context"]
      this.dbTableName = str.substring(
        str.indexOf("#") + 1,
        str.indexOf("(")
      );
    }
    this.rowData = JSON.parse(JSON.stringify(res.value))
    if(this.filterTableData){
      let ownerName = ''
      for(let table of this.tableData){
        if(this.tableId == table.uid){
          ownerName = table.OwnerName
        }
      }
      this.filterTableDataByLoggeInUser(ownerName)
    }
    this.createSubChildTableData()
    let dateId = ""
    for(const i of this.layout){
      if(i.type && (i.type).toLowerCase()=="date"){
        dateId = i.id
        for(const j of this.rowData){
          if(j[dateId] && j[dateId]!=""){
             var d = new Date(j[dateId])
            j[dateId] = moment(d).format('DD-MMM-YYYY');
          }
        }
      }
    }
    let ownerName = ''
    for(let table of this.tableData){
      if(this.tableId == table.uid){
        ownerName = table.OwnerName
      }
    }
    let header_primary = JSON.parse(this.formGeneration[0].Layout).header.primery_field
    for(const row of this.rowData){
      this.assignIconToEachRow(row,header_primary)
      for(let j in row){
        if(j == "createdon"){
          let utcDate = row[j]; 
          let localDate = (new Date(utcDate)).toString();
          row['createdon@OData.Community.Display.V1.FormattedValue'] = localDate.substring(0,localDate.indexOf('G'))
        }
      }
    }
    this.tableDataAvailable =  true
    this.showLoader = false
    this.viewDataAvailable = true
    this.showDashboard = false
    this.tableAllDataFetched = allData
  }

  assignIconToEachRow(row : any,header_primary : string){
    let flag = false
    if(typeof (this.iconValue) == "string"){
      for(let i in row){
        if(i == this.iconValue && row[i]){
          row.checkboxIcon = 'data:image/jpg;base64,'+row[i]
          row.checkBoxIconType = "image"
          row.checkboxIconColor = "#FFF"
          flag = true
        }
      }
      if(!flag){
        row.checkboxIcon = this.tableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
    else{
      if(!this.iconValue){
        this.setInitials(row,header_primary)
      }
      else{
        row.checkboxIcon = this.tableIcon
        row.checkBoxIconType = "icon_image"
      }
    }
  }

  setInitials(row: any, header_primary: string){
    if(row[header_primary]){
      let str = ((row[header_primary]).trim()).toString().split(" ")
      let initials = ""
      if(str.length == 1){
        initials = str[0].charAt(0)+(str[0].charAt(1) ? str[0].charAt(1) : str[0].charAt(0))
      }
      else{
        initials =  (str[str.length-1].charAt(0) == '(' || str[str.length-1].charAt(0) == ')') ? str[str.length-2].charAt(0) : str[str.length-1].charAt(0)
        initials = str[0].charAt(0) + initials
      }
      row.checkboxIcon = initials.toUpperCase()
      row.checkBoxIconType = "initial"
    }
  }

  filterTableDataByLoggeInUser(ownerString : string){
    let data = []
    for(let i of  this.rowData){
      if(i[ownerString] == this.userName){
        data.push(i)
      }
    }
    this.rowData = data
  }

  createSubChildTableData(){
    for(let i of  this.rowData){
      this.addSubChildToTableData(i)
    }
  }

  addSubChildToTableData(i: any){
    for(let elem in i){
      if(i[elem] instanceof Object){
        this.addSubChildToTableData(i[elem])
        for(let j in i[elem]){
          let str = elem+"."+ j
          i[str] = i[elem][j]
       }
      }
    }
  }
  closeTableModal(){
    let modal = document.getElementById("myTableModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementById("app-container");
    if (container) {
      container.classList.remove("blur");
    }
  }

  performSelectedTask(button : any){
    if(button.actionApi == "New"){
      if(this.formGeneration && this.formGeneration.length>0){
        this.tableRowClicked = true
        if(this.formGeneration[0].Type == 'Quick Create'){
          this.quickCreateAvailable = true
        }
        else if(this.formGeneration[0].Type == 'Quick Add'){
          this.quickAddAvailable = true
        }
      }
      this.clickedRowData = undefined
      this.triggeredNewButton = true
    }
    else if(button.actionApi == "Refresh"){
      if(button.TableName){
        this.getTableForView(this.selectedView)
      }
      else{
        this.showLoader = true
        this.dashboardKPIs = []
        this.resetDashboardFilter()
      }
    }
    else if(button.actionApi == "Edit"){
      if(this.formGeneration && this.formGeneration.length>0){
        this.tableRowClicked = true
        if(this.formGeneration[0].Type == 'Quick Create'){
          this.quickCreateAvailable = true
        }
        else if(this.formGeneration[0].Type == 'Quick Add'){
          this.quickAddAvailable = true
        }
      }
      this.clickedRowData =  this.checkedRows.length>0 ? this.checkedRows[0] : []
      this.callDateCoversion()
    }
    else if(button.actionApi == "Delete"){
      if(this.checkedRows && this.checkedRows.length>0){
        this.openDialog(this.checkedRows,this.primaryColumn)
      }
    }
    else if(button.actionApi == "Active"){
      if(this.checkedRows && this.checkedRows.length>0){
        this.openDialogForActiveInactive(this.checkedRows,this.primaryColumn,'Are you sure you want to activate?','Active')
      }
    }
    else if(button.actionApi == "Inactive"){
      if(this.checkedRows && this.checkedRows.length>0){
        this.openDialogForActiveInactive(this.checkedRows,this.primaryColumn,'Are you sure you want to inactivate?','Inactive')
      }
    }
    else if(button.actionApi == "Share"){
      let info = localStorage.getItem("primaryContactInfo")
      let primarInfo = ""
      if(info){
        primarInfo = JSON.parse(info)
      }
        this.openShareDialog(primarInfo)
    }
    else if(button.actionApi == "Assign"){
      let info = localStorage.getItem("primaryUserInfo")
      let primarInfo = ""
      if(info){
        primarInfo = JSON.parse(info)
      }
        this.openAssignDialog(primarInfo,this.primaryColumn)
    }
    else if(button.actionApi == "ExportCSV"){
      // this.showLoader = true
      // this.exportcsv()
      this.downloadCsv()
    }
    else if(button.actionApi == "ConvertToLead"){
      let info = localStorage.getItem("primaryUserInfo")
      let primarInfo = ""
      if(info){
        primarInfo = JSON.parse(info)
      }
      this.openConvertToLeadDialog(primarInfo,this.primaryColumn)
    }
    else if(button.actionApi == "Insights"){
      this.formService.createTableInsights(this.tableId,this.formGeneration[0].uid,this.rowData)
    }
  }

  downloadCsv(){
    let columnHeader: any[] = []
    let displayCols = []
    for(const i of this.layout){
      columnHeader.push(i.id)
      displayCols.push(i.label)
    }
    let  data = JSON.parse(JSON.stringify(this.checkedRows))
    for(let i of data){
      for(let j in i){
        if( typeof(i[j]) == "string" && i[j].includes('₹')){
          i[j] = Number(i[j].replace(/[^0-9\.-]+/g,""));
        }
      }
    }
    const replacer = (key : any, value : any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = displayCols;
    let csv = data.map((row :any) => 
      columnHeader.map((fieldName : any) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, this.selectedView.Name+".csv");
  }

  callExportCSV(){
    let allCheckedId = ''
    for(let row of this.checkedRows){
      allCheckedId += row[this.primaryColumn] + ','
    }
    allCheckedId = allCheckedId.substring(0,allCheckedId.length-1)
    let tableId = this.Table_Logical_Name+','+this.primaryColumn+','+this.selectedView.Name
    let viewLayout = this.selectedView.Layout
    this.tableOperationService.exportTableRecord(tableId,viewLayout,allCheckedId,this.commonService.getAuthToken()).subscribe((res)=>{
      if(res.base64){
        let elem = 'data:application/octet-stream;base64,' + res.base64
        const downloadLink = document.createElement('a');
        downloadLink.href = elem;
        downloadLink.download = res.filename ? res.filename : 'sample.csv';
        downloadLink.click();
      }
      this.showLoader = false
    })
  }

  callDeleteService(rowId: string){
    this.tableOperationService.deleteTableRecord(rowId,this.commonService.getAuthToken(),this.dbTableName).subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
        this.toastr.success("Record deleted successfully")
      }
      else{
        this.toastr.error("Failed to delete record")
      }
    })
  }

  openDialog(rowData: any,rowId : string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.showLoader = true
        let i=0;
        for(i=0;i<this.checkedRows.length;i++){
          this.callDeleteService(this.checkedRows[i][rowId])
        }
        if(i==this.checkedRows.length){
          setTimeout(()=>{
            this.getTableForView(this.selectedView)},2000);
        }
      }
    });
  }

  openDialogForActiveInactive(rowData: any,rowId : string,message : string,buttonAction: string) {

    let myTable : {[key: string]: string} = {}
    for(let table of this.tableData){
      if(table.uid == this.tableId){
        myTable = table
      }
    }
    let statusOption = JSON.parse(myTable.StatusOptions)
    let setStatus: string , setStatusOption, setStatusValue: string, reverseStatus: string
    for(let status of statusOption){
      if((status.Text).toLowerCase() == buttonAction.toLowerCase()){
        setStatus = status.Value
      }
    }
    if(buttonAction.toLowerCase() == 'active'){
      setStatusOption = JSON.parse(myTable.ActiveStatusOptions)
      setStatusValue = myTable.ActiveStatus
      reverseStatus = myTable.InActiveStatus
    }
    else{
      setStatusOption = JSON.parse(myTable.InActiveStatusOptions)
      setStatusValue = myTable.InActiveStatus
      reverseStatus = myTable.ActiveStatus
    }
    let payload: {[key: string]: string|null} = {}
    // payload[myTable.Status] = setStatus
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '300px'

    dialogConfig.data = {
      message: message,
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      },
      buttonColor: this.theme['main-header_clr'],
      dataInfo: setStatusOption,
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((confirmed  : any) => {
      if (confirmed) {
        payload[myTable.Status] = setStatus
        payload[setStatusValue] = confirmed
        payload[reverseStatus] = null
        this.showLoader = true
        let i=0;
        for(i=0;i<this.checkedRows.length;i++){
          this.callUpdateService(this.checkedRows[i][rowId],buttonAction,payload)
        }
        if(i==this.checkedRows.length){
          setTimeout(()=>{
            this.getTableForView(this.selectedView)},2000);
        }
      }
    });
  }

  openShareDialog(primaryInfo : any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'
    dialogConfig.height = '320px'

    dialogConfig.data = {
      message: 'Are you sure want to delete?',
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Share',
        cancel: 'Cancel'
      },
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {

      if(selectedContact && selectedContact !==""){
        let whatsapp = "", email = "", to_email = "", to_mobile = ""
        if(selectedContact.isEmailChecked && selectedContact.email){
          email = "true"
          to_email = selectedContact.email
        }

        if(selectedContact.isMobileChecked && selectedContact.mobile){
          whatsapp = "true"
          to_mobile = selectedContact.mobile
        }

        let data_id = ""
        if(this.checkedRows){
          for(let i of this.checkedRows){
            if(i[this.primaryColumn]){
              data_id += i[this.primaryColumn]+","
            }
          }
        }

        data_id = data_id.substring(0,data_id.length-1)

        let payload = {
          "loggedin_user_guid": this.userId,
          "whatsapp": whatsapp,
          "email": email,
          "to_mobile_no": to_mobile,
          "to_email": to_email,
          "table_id": this.tableId,
          "data_ids": data_id,
          "type": "table"
        }

        this.commonService.logging(payload)

        this.tableOperationService.shareTableRecord(payload,this.commonService.getAuthToken()).subscribe((res)=>{
          if(res && res.status && res.status == 'True' ){
            this.toastr.success("Record shared successfully")
          }
          else{
            this.toastr.error("Failed to share record")
          }
        });
      }
      })
  }

  openAssignDialog(primaryInfo : any, column : string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'
    dialogConfig.height = '320px'

    dialogConfig.data = {
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Assign',
        cancel: 'Cancel'
      },
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {

      if(selectedContact && selectedContact !==""){

         let payload : {[key: string]: string} = {}
         let ownerId = "", ownerName = ""
         for(let table of this.tableData){
          if(this.tableId == table.uid){
            ownerId = table.OwnerId
            ownerName = table.OwnerName
          }
        }
        payload[ownerId] = selectedContact.userId
        payload[ownerName] = selectedContact.label
        let date = new Date();
        payload['eng_assignedon'] = ( moment(date).format('MM-DD-YYYY')).toString()

        this.showLoader = true
        let i=0;
        for(i=0;i<this.checkedRows.length;i++){
          this.tableOperationService.assignTableRecord(payload,this.checkedRows[i][column],this.dbTableName,this.commonService.getAuthToken()).subscribe((res)=>{
            if(res && res.status && res.status == 'True' ){
              this.toastr.success("Record assigned successfully")
            }
            else{
              this.toastr.error("Failed to assign record")
            }
          });
        }
        if(i==this.checkedRows.length){
          setTimeout(()=>{
            this.getTableForView(this.selectedView)},2000);
          }
        }
      })
  }

  openConvertToLeadDialog(primaryInfo : any, column : string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px'
    dialogConfig.height = '320px'

    dialogConfig.data = {
      dataInfo: primaryInfo,
      buttonText: {
        ok: 'Convert',
        cancel: 'Cancel'
      },
      buttonColor: this.theme['main-header_clr']
    };

    const dialogRef = this.dialog.open(ShareDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((selectedContact: any) => {

      if(selectedContact && selectedContact !==""){

         let payload : {[key: string]: any} = {}
         let ownerId = "", ownerName = ""
         for(let table of this.tableData){
          if(this.tableId == table.uid){
            ownerId = table.OwnerId
            ownerName = table.OwnerName
          }
        }
        payload[ownerId] = selectedContact.userId
        payload[ownerName] = selectedContact.label
        let date = new Date();
        payload['eng_assignedon'] = ( moment(date).format('MM-DD-YYYY')).toString()

        this.showLoader = true
        let i=0;
        for(i=0;i<this.checkedRows.length;i++){
          if(this.dbTableName.includes('school')){
            payload['eng_leadorigin'] = 787980000
            payload['eng_leadtype'] = 787980000
            payload['eng_source'] = 787980000
            payload['eng_status'] =  787980000
            payload['eng_School@odata.bind'] = '/eng_engschools('+this.checkedRows[i]['eng_engschoolid']+')'
          }
          else if(this.dbTableName.includes('student')){
            payload['eng_leadorigin'] = 787980001
            payload['eng_leadtype'] = 787980001
            payload['eng_source'] = 787980000
            payload['eng_status'] =  787980000
            payload['eng_Student@odata.bind'] = '/eng_engstudents('+this.checkedRows[i]['eng_engstudentid']+')'
          }
          this.tableOperationService.createTableRecords(this.commonService.getAuthToken(),'eng_engleads',payload).subscribe((res)=>{
            if(res && res.status && res.status == 'True'){
              this.toastr.success("Lead Created Successfully")
            }
            else{
              this.toastr.error("Failed to create lead")
            }
          })
        }
        if(i==this.checkedRows.length){
          this.getTableForView(this.selectedView)
        }
      }
    })
  }


  callDateCoversion(){
    let dateId
      for(const i of this.layout){
        if(i.type=="date"){
          dateId = i.id
          if(this.clickedRowData[dateId] && this.clickedRowData[dateId]!=""){
              var d = new Date(this.clickedRowData[dateId])
              this.clickedRowData[dateId] = moment(d).format('YYYY-MM-DD');
            }
        }
      }
  }

  callUpdateService(rowId :  string,buttonAction: string,payload: any){
    this.tableOperationService.updateTableRecords(rowId,this.commonService.getAuthToken(),this.dbTableName,payload).subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
        buttonAction.toLowerCase() == 'active' ? this.toastr.success("Record activated successfully") : this.toastr.success("Record inactivated successfully")
      }
      else{
        buttonAction.toLowerCase() == 'active' ?  this.toastr.error("Failed to activate record") :  this.toastr.error("Failed to inactivate record")
      }
    })
  }

  checkTableRows(selection : any){
    this.showButtons = []
    this.visibleButton = []
    this.dropdownButton = []
    for(let b of this.headerButton){
      if(b.scope == "No Selection "){
        this.showButtons.push(b)
      }
    }
    if(selection.selected && selection.selected.length>1){
      this.checkedRows = selection._selected
      for(let i=0;i<this.headerButton.length;i++){
        if(this.headerButton[i].scope=="Multiple Selection"){
          this.showButtons.push(this.headerButton[i])
        }
      }
    }
    else{
      if(selection.selected.length==1){
        this.checkedRows = selection._selected
        for(let i=0;i<this.headerButton.length;i++){
          if(this.headerButton[i].scope=="Single Selection" || this.headerButton[i].scope=="Multiple Selection"){
            this.showButtons.push(this.headerButton[i])
          }
        }
      }
    }
    this.showButtons = this.commonService.sortDataBySequence(this.showButtons)
    let ownerName = ''
    for(let table of this.tableData){
      if(this.tableId == table.uid){
        ownerName = table.OwnerName
      }
    }
    this.checkButtonVisibilityByRole(ownerName)
    if(this.showButtons.length>7){
      this.visibleButton = this.showButtons.slice(0,6)
      this.dropdownButton = this.showButtons.slice(6)
    }
  }

  checkButtonVisibilityByRole(ownerString : string){
    for(let i of this.showButtons){
      if(i.hide){
        i.show = false
      }
      else if(i.hideNonUser){
        let count = 0
        this.checkedRows.forEach((elem: any)=>{
          if(elem[ownerString] !== this.userName){
           count++;
          }
        })
        i.show = count==0 ? true : false
      }
      else{
        i.show = true
        // if(i.actionApi == 'ConvertToLead'){
        //   if(this.business.Name.includes('Engrezi.com') && (this.userRoleName.includes('Admin')||this.userRoleName.includes('Sales Manger')) ){
        //     i.show = true
        //   }
        //   else{
        //     i.show = false
        //   }
        // }
      }
    }
  }

  resetButtons(){
    this.showButtons = []
    this.visibleButton = []
    this.dropdownButton = []
    for(let b of this.headerButton){
      b.show = true
      if(b.scope == "No Selection "){
        this.showButtons.push(b)
      }
    }
    let ownerName = ''
    for(let table of this.tableData){
      if(this.tableId == table.uid){
        ownerName = table.OwnerName
      }
    }
    this.checkButtonVisibilityByRole(ownerName)
    if(this.showButtons.length>5){
      this.visibleButton = this.showButtons.slice(0,5)
      this.dropdownButton = this.showButtons.slice(5)
    }
  }

  checkRowClicked(row : any){
    if(!this.tableLookUpQuickCreate){
      this.closeQuickCreate(false)
      this.closeQuickCreateForLookUp({clicked: false, close: true})
       this.clickedRowData = row
       if(this.formGeneration && this.formGeneration.length>0){
         this.tableRowClicked = true
         if(this.formGeneration[0].Type == 'Quick Create'){
           this.quickCreateAvailable = true
         }
         else if(this.formGeneration[0].Type == 'Quick Add'){
          this.quickAddAvailable = true
        }
       }
       this.triggeredNewButton = false
       this.callDateCoversion()
    }
  }

  closeTableForm(buttonClicked : boolean){
    this.tableRowClicked = false
    this.triggeredNewButton = false
    this.quickCreateAvailable = false
    this.quickAddAvailable = false
    this.quickCreateAvailableForLookUp = false
    this.clickedRowData = undefined
    this.resetButtons()
    if(buttonClicked){
      this.getTableForView(this.selectedView)
    }
  }
  closeQuickCreate(elem : any){
    if(elem.clicked){
      this.getTableForView(this.selectedView)
    }
    if(elem.close){
      this.tableRowClicked = false
      this.triggeredNewButton = false
      this.quickCreateAvailable = false
      this.clickedRowData = undefined
    }
  }

  closeQuickAdd(elem : any){
    if(elem.clicked){
      this.getTableForView(this.selectedView)
    }
    if(elem.close){
      this.tableRowClicked = false
      this.triggeredNewButton = false
      this.quickAddAvailable = false
      this.clickedRowData = undefined
    }
  }

  sendLookUpComponent(comp :  any){
    // this.closeQuickCreate({clicked: false, close: true})
    this.closeQuickCreateForLookUp({clicked: false, close: true})
    this.formGenerationQuick=comp.formGeneration
    this.clickedRowDataQuick = comp.clickedRowData
    this.triggeredNewButtonQuick = comp.triggeredNewButton
    this.dbTableNameQuick = comp.lookupTableName
    this.primaryColumnQuick = comp.lookUpTablePrimaryColumn
    this.tableLogicalNameQuick = comp.tableTableLogicalName
    this.tableIdQuick = comp.tableid
    this.quickCreateAvailableForLookUp = true
    this.quickCreateAvailable = false
    this.quickAddAvailable = false
  }

  closeQuickCreateForLookUp(elem : any){
    let e = this.clickedRowData ? JSON.parse(JSON.stringify(this.clickedRowData)) : undefined
    this.clickedRowData = undefined
    if(elem.clicked){
      this.getTableForView(this.selectedView)
    }
    if(elem.close){
      this.triggeredNewButtonQuick = false
      this.quickCreateAvailableForLookUp = false
      this.quickCreateAvailable = false
      this.clickedRowDataQuick = undefined
      this.clickedRowData = e ? JSON.parse(JSON.stringify(e)) : undefined
      if(this.clickedRowData){
        if(this.quickAddAvailable){
          this.quickAddAvailable = true
        }
        else{
          this.quickCreateAvailable = true
        }
      }
    }
  }

  getCellClickedData(data : any){
    this.tableLookUpQuickCreate = false
    let layOutValue = ''
    for(let i of this.layout){
      for(let table of this.tableData){
        if(i.type='lookup' && table.uid == i.tableid && i.id == data.col){
          layOutValue = data.row[i.columnid]
          this.showTableLookupData(layOutValue,table)
        }
      }
     
      // for(let table of this.tableData){
      //   if(table.uid == i.tableid){
      //     layOutValue = data.row[table.Primary_Column] ? data.row[table.Primary_Column] : data.row[table.Table_Schema_Name+"."+table.Primary_Column]
      //     this.showTableLookupData(layOutValue,table)
      //   }
      // }
    }
    // for(let r in data.row){
    //   if(data.row[r] == data.elem){
    //     for(let i of this.layout){
    //       if(i.id == r){
    //         layOutKey = r
    //       }
    //     }
    //   }
    // }
    // for(let i of this.layout){
    //   if(i.type == 'lookup' && i.id == layOutKey){
    //     let elem = layOutKey.split('@')
    //     let storeLookUpId = data.row[elem[0]]
    //     for(let table of this.tableData){
    //       if(table.uid == i.tableid){
    //         this.showTableLookupData(storeLookUpId,table)
    //       }
    //     }
    //   }
    // }
  }

  showTableLookupData(storeLookUpId : string,table : any){
    let primaryPayload = table.Primary_Column+"="+storeLookUpId
    let view = table.views[0]
    let comp : {[key : string]: any} = {}
    comp.formGeneration = this.commonService.getViewFormFirst(table.forms)
    comp.tableid = table.uid
    this.detailService.getTableDataById(this.businessId.replace(/-/g,'_'),view.DataApi,this.commonService.getAuthToken(),primaryPayload).subscribe((res)=>{
      if(res && res.value && res.value.length>0){
        comp.clickedRowData = res.value[0]
        if(res["@odata.context"]){
          let str = res["@odata.context"]
          comp.dbTableName = str.substring(
            str.indexOf("#") + 1,
            str.indexOf("(")
          );
        }
        comp.triggeredNewButton = false
        if(comp.formGeneration[0].Type == 'Standard'){
          this.passObject(comp)
        }
        else if(comp.formGeneration[0].Type == "Quick Create" || comp.formGeneration[0].Type == "Quick Add"){
          this.selectedViewQuick = view
          this.formGenerationQuick=comp.formGeneration
          this.clickedRowDataQuick = comp.clickedRowData
          this.triggeredNewButtonQuick = comp.triggeredNewButton
          this.dbTableNameQuick = comp.dbTableName
          this.tableLogicalNameQuick = table.Table_Logical_Name
          this.primaryColumnQuick = table.Primary_Column
          this.tableIdQuick = comp.tableid
          this.quickCreateAvailableForLookUp = true
        }
      }
      else{
        this.showLoader = false
        this.toastr.error('Failed to load lookup form')
      }
    })
    this.closeQuickCreateForLookUp({clicked: false, close: true})
    this.tableLookUpQuickCreate = true
  }

  passObject(comp : any){
    this.windowOpen++;
    localStorage.setItem('lookupComp'+this.windowOpen, JSON.stringify(comp));
    this.router.navigate([]).then(result => {  window.open(`/showDetails/${this.windowOpen}/lookup`, '_blank'); });
  }
}
