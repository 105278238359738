<mat-dialog-content>
	<div style="font-size:14px; text-align:center">
		{{message}}
  </div>
</mat-dialog-content>
<div *ngIf="displayContacts && displayContacts.length>0">
  <ul *ngFor="let i of displayContacts">
    <li class="nameList">{{i.name}}</li>
  </ul>
</div>
<!-- <mat-radio-button></mat-radio-button> -->
<mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="selectedData"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let data of radioButtonData"
      [value]="data.Value" (click)="changeCSS()" [checked]="data.checked"
    >
      {{ data.Text }}
    </mat-radio-button>
  </mat-radio-group>
<mat-dialog-actions align="center">
	<button class="mat-raised-button mat-primary" style="margin-right:20px" (click)="onConfirmClick()" tabindex="1"
    [ngStyle]="{
      'background-color' : buttonColor ? buttonColor : '#673ab7',
      'opacity' : !selectedData && radioButtonData.length>0 ? '0.5' : '1'
    }" [disabled]="!selectedData && radioButtonData.length>0" 
  >{{confirmButtonText}}</button>
	<button  *ngIf="cancelButtonText" class="mat-raised-button" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>

