<div style="margin-top:50px">
  <app-standard-form
  [formDetails]="comp.formGeneration"
  [rowData] = "comp.clickedRowData"
  [newButton] = "comp.triggeredNewButton"
  [dbTableName] = "comp.dbTableName"
  [primaryColumn] = "comp.primary_column"
  [selectedView] = "comp.selectedView"
  [lookupTableName]="comp.lookupTableName"
  [userId]="userId"
  [userName] = "userName"
  [theme]="theme"
  [businessId]="businessId"
  [tableId]="comp.tableid"
  [allTables]="allTables"
  [newWindow]="true"
  [formTemplate] = "formTemplate"
  [comp] = "comp"
  [lookups] = "comp.storeLookups"
></app-standard-form>
</div>



<!-- <h1 style="margin-top:100px;color:black">Hi</h1> -->
