<div class='form-container slide-left' #formDrawer>
  <div class=""  [ngStyle]="{
      opacity: showLoader ? '0.5' : '1',
      'pointer-events': showLoader ? 'none' : 'all'
  }">
    <div class="allButtons" *ngIf="formButton && formButton.length>0" [ngClass]="{
      'display-allButtons' : formButton.length>2
    }">
      <div class="headButton" *ngFor="let b of formButton" (click)="performFormEvent(b)">
        <span *ngIf="b.show"><img *ngIf="b.Icon!=''" class="button-icon" [src]="b.Icon">{{b.Label}}</span>
      </div>
    </div>

    <div *ngIf="visibleButton.length>0" class="display-buttonDropdown">
      <div class="allButtons">
        <div class="headButton" *ngFor="let b of visibleButton" (click)="performFormEvent(b)">
          <span *ngIf="b.show"><img *ngIf="b.Icon!=''" class="button-icon" [src]="b.Icon">{{b.Label}}</span>
        </div>
      </div>
      <div class="dropdown" dropdown>
        <div class="headButton" style="padding: 2px;" data-toggle="dropdown" dropdownToggle>
          <img src="../../../assets/Images/dotMenu.png" class="dotMenu" >
        </div>
        <div class="dropdown-menu" style="margin-left: -30px;">
            <div *ngFor="let item of dropdownButton" class="dropdown-item" style="padding:10px 13px;font-size:14px" (click)="performFormEvent(item)">
              <span *ngIf="item.show"><img *ngIf="item.Icon!=''" class="button-icon" [src]="item.Icon">{{item.Label}}</span>
            </div>
        </div>
      </div>

    </div>
  </div>
  <img src="../../../assets/Images/close.png" (click)="closeForm()" class="close-form">

  <div *ngIf="formDetails && formDetails.length>0"  [ngStyle]="{
    opacity: showLoader ? '0.5' : '1',
    'pointer-events': showLoader ? 'none' : 'all'
}">
    <select class="dropdown-toggle custom-select">
      <option class="custom-option" *ngFor="let elem of formDetails" value="{{elem.Name}}" (onChange)="getFormForView(elem)">{{elem.Name}}</option>
    </select>
  </div>

  <div class="row table-form" [ngStyle]="{
    opacity: showLoader ? '0.5' : '1',
    'pointer-events': showLoader ? 'none' : 'all'
}">
    <div class="col-lg-8">
      <div class="card mb-4">
        <div class="card-body">
          <form>
            <div class="row form-group" *ngFor="let fm of myForm">
              <div class="col-sm-3" *ngIf="fm.label!=='Id'">
                <p class="mb-0">{{fm.label}}</p>
              </div>
              <div class="col-sm-9" *ngIf="fm.label!=='Id'">
                <input type="{{fm.type}}" value="{{fm.value}}" [(ngModel)] = "fm.value" name="{{fm.label}}"
                class="form-control input-type" [attr.minlength]="fm.minLength"  [attr.maxlength]="fm.maxLength">
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>

</div>

<app-loader *ngIf="showLoader"></app-loader>

<div id="overlayDiv">
  <div id="overlayFormDiv"></div>
</div>
