<div class="footer">
  <span class="col-xs-4 tp5">
    <i class="fa fa-envelope footerImage"></i>
    <span class="footer-text">{{emailSupport}}</span>
  </span>
  <span class="col-xs-4 tp5">
    <i class="fa fa-copyright footerImage"></i>
    <span class="footer-text">{{copyright}}</span>
  </span>
  <span class="col-xs-4 tp5">
    <i class="fa fa-whatsapp footerImage"></i>
    <span class="footer-text">{{whatsappSupport}}</span>
  </span>
</div>
