import { Component, Input, OnInit } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';

@Component({
  selector: 'app-woods-kitchen',
  templateUrl: './woods-kitchen.component.html',
  styleUrls: ['./woods-kitchen.component.scss']
})
export class WoodsKitchenComponent implements OnInit {

  myForm !: any

  showLoader = false

  constructor(private woodsService: TableOperationService, private toastr: ToastrService,
    private commonService: CommonService
   ) { }

  ngOnInit(): void {
    this.myForm = [
      {
        label: 'Attend By',
        type: 'select',
        value: {'Value':null,'Text':'--Select--'},
        options:[{'Value':null,'Text':'--Select--'},{'Value':127720000,'Text':'Vimal'},{'Value':127720001,'Text':'Pranav'},{'Value':127720002,'Text':'Mitali'},{'Value':127720003,'Text':'Namrata'}],
        payloadName: 'wk_attendby'
      },
      {
        label: 'First Name',
        type: 'text',
        value: null,
        payloadName: 'wk_firstname'
      },
      {
        label: 'Last Name',
        type: 'text',
        value: null,
        payloadName: 'wk_lastname'
      },
      {
        label: 'Mobile No.',
        type: 'number',
        value: null,
        maxLength: 10,
        minLength: 10,
        payloadName: 'wk_mobileno'
      },
      {
        label: 'Email Id',
        type: 'email',
        value: null,
        payloadName: 'wk_email'
      },
      {
        label: 'Complete Address',
        type: 'text',
        value: null,
        payloadName: 'wk_completeaddress'
      },
      {
        label: 'Lead Priority',
        type: 'select',
        value: {'Value':null,'Text':'--Select--'},
        options:[{'Value':null,'Text':'--Select--'},{'Value':127720000,'Text':'Hot'},{'Value':127720001,'Text':'Warm'},{'Value':127720002,'Text':'Cold'}],
        payloadName: 'wk_leadpriorityk'
      },
      {
        label: 'Source',
        type: 'select',
        value: {'Value':null,'Text':'--Select--'},
        options:[{'Value':null,'Text':'--Select--'},{'Value':127720000,'Text':'Social Network'},{'Value':127720001,'Text':'External Refrence'},{'Value':127720002,'Text':'Internal Refrence'},{'Value':127720003,'Text':'Walk-in'},{'Value':127720004,'Text':'Architect'},{'Value':127720005,'Text':' Other'}],
        payloadName: 'wk_source'
      },
      {
        label: 'Architect Name',
        type: 'text',
        value: null,
        payloadName: 'wk_architectname'
      },
      {
        label: 'Architect Phone',
        type: 'number',
        value: null,
        maxLength: 10,
        minLength: 10,
        payloadName: 'wk_architectphone'
      },
      {
        label: 'DOB',
        type: 'date',
        value: null,
        payloadName: 'wk_dob'
      }
    ]
  }

  compareFn(a: any,b: any){
    return a && b && a.Value == b.Value;
  }

  onSaveClick(){
    this.showLoader = true
    let payload :{[key: string]: string} = {}

    for(let i of this.myForm){
      if(i.type == 'select'){
        payload[i.payloadName] = i.value.Value== 'null' ? null : i.value.Value
      }
      else{
        payload[i.payloadName] = i.value
      }
    }
    let tableName = 'wk_customers'
    this.woodsService.createTableRecords(this.commonService.getAuthToken(),tableName,payload).subscribe((res)=>{
      if(res && res.status && res.status == 'True' ){
        this.toastr.success("Details saved successfully")
        this.resetForm()
      }
      else{
        this.toastr.error("Failed to save details")
      }
      this.showLoader = false
    })
  }

  resetForm(){
    for(let i of this.myForm){
      if(i.type=='select'){
        i.value={Value:null,Text:'--Select--'}
      }
      else{
        i.value = null
      }
    }
  }

}
