<nav class="navbar navbar-expand-md navbar-dark fixed-top">
  <!-- <a class="home-route" routerLink='/' routerLinkActive='active'> -->
  <img *ngIf="homeIcon !==''" class="homeIcon-image" [src]="homeIcon" (click)="goBackToHome()" >
  <i *ngIf="homeIcon == ''" class="fa fa-home home-icon" (click)="goBackToHome()" [ngStyle]="{
    color : homeIconColor ? homeIconColor : '#FFF'
  }"></i>
  <!-- </a> -->
  <div class="container">

      <!-- <ul class="navbar-nav me-auto">
         *ngIf="accountService.currentUser$ | async"
          <ng-container>
              <li class="nav-item">
                  <a class="nav-link" routerLink='/members' routerLinkActive='active' >Matches</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" routerLink='/lists' routerLinkActive='active'>Lists</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" routerLink='/messages'routerLinkActive='active' >Messages</a>
              </li>
              <li *appHasRole='["Admin", "Moderator"]' class="nav-item">
                  <a class="nav-link" routerLink='/admin'routerLinkActive='active' >Admin</a>
              </li>
          </ng-container>
          <li class="nav-item">
              <a class="nav-link" routerLink='/errors'routerLinkActive='active' >Errors</a>
          </li>
      </ul> -->

      <div class="dropdown" dropdown>
        <!-- *ngIf="(accountService.currentUser$ | async) as user" -->
          <!-- <img src="{{user.photoUrl || './assets/user.png'}}" alt="{{user.username}}"> -->

          <a class="dropdown-toggle text-light ms-2 text-decoration-none" dropdownToggle>
            <span class="display-drop">Welcome {{firstName}}</span>
            &nbsp;<img *ngIf="userImage!==''; else showNameImage" class="dropdown-toggle display-image imgspan" [src]="userImage">
            <ng-template #showNameImage>
                <span class="imageName-class">{{imageName}}</span>
            </ng-template>

          </a>
          <div class="dropdown-menu mt-3"s style="font-size: 12px;margin-top:46px !important">
              <a class="dropdown-item" (click)="viewProfile()">View Profile</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="showTermsAndConditions()" >Terms and Conditions</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="resetPassword()" >Reset Password</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()" >Logout</a>
          </div>
      </div>

  </div>

</nav>
