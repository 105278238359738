<div class="quick-form">
  <div class="quick-class">
    <div id="quick-subdiv">
      <div *ngIf="showFormMessage" class="formMessage">
        <div>
          <span style="margin-left:10px;vertical-align: middle;">{{formStatusReason}}</span>
          <span class="close" (click)="closeMessage()">X</span>
        </div>
        </div>
      <div class="quick-header">
        <span>
          <div *ngIf="headerData && headerData.length>0" class="header-details">
            <ng-container *ngFor="let head of headerData">
              <ng-container *ngIf="head.primaryField">
                <div>
                  <div class="head-content">
                    <span *ngIf="head.value !== null && head.icon == 'initials'" class="imageName-class"
                      [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}">{{head.initials | uppercase}}</span>
                      <span *ngIf="head.value !== null && head.icon == 'image' && head.iconColor" class="imageName-class" style="padding: 0px"
                      [ngStyle]="{'background-color': head.iconColor ? head.iconColor : '#FF7851','border':'2px solid black'}"><img style="width:40px;height:40px;border-radius: 50%;" [src] = "head.initials"></span>
                      <span *ngIf="head.value !== null && head.icon == 'image' && !head.iconColor" class="imageName-class"
                      [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}"><img style="width:24px;height:25px" [src] = "head.initials"></span>
                    <div>
                      <span class="head-value" *ngIf="head.value !== null">{{head.value}}</span>
                      <span class="head-label">{{head.label}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </span>
        <span  (click)="closeForm(false,true)" class="fa fa-times-circle quick-close"
        [ngStyle]="{
          'color': form_section_header_back_clr ? form_section_header_back_clr : '#000'
        }"
        ></span>
      </div>
      <mat-tab-group  (selectedTabChange)="changeActiveCss($event)" [ngStyle]="{'margin-top': '96px'}">
      <ng-container *ngFor="let data of formData">
        <mat-tab *ngIf="data.visible" label="{{data.name}}">
            <ng-container *ngIf="data.section && data.section.length>0">
                <ng-container *ngFor="let sec of data.section">
                    <div *ngIf="sec.visible && sec.formType!=='NewForm'">
                        <div class="section-card" [ngStyle]="{'border-color': form_section_border_clr  ? form_section_border_clr : ''}">
                            <div [ngStyle]="{
                              'color': form_section_text_clr ? form_section_text_clr : '',
                              'background-color': form_section_header_back_clr ? form_section_header_back_clr : ''
  
                            }" class="sectionName">{{sec.name}}</div>
                            <hr [ngStyle]="{'color': form_section_header_line_clr ? form_section_header_line_clr : '',
                            'background-color': form_section_header_back_clr ? form_section_header_back_clr : ''
                          }">
                            <form [ngStyle]="{'color': form_text_clr ? form_text_clr : '',
                                            'background-color': form_back_clr ? form_back_clr : '',
                                            'font-size': '12px'
                              }">
                                <div class="row form-group" *ngFor="let comp of sec.components">
                                    <ng-container  *ngIf="comp.type == 'field' && comp.visible ">
                                        <div class="col-sm-3" style="display: inline-flex;">
                                            <p class="mb-0">{{comp.label}}</p>
                                            <div *ngIf="comp.required" class="asterisk">*</div>
                                        </div>
  
                                        <ng-container *ngIf="comp.datatype == 'text' || comp.datatype == 'email' || comp.datatype == 'date' || comp.datatype == 'number'">
                                            <div class="col-sm-9">
                                              <img *ngIf="comp.icon" [src]="comp.icon" class="divImage" >
                                                <input type="{{comp.datatype}}" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                                [ngStyle]="{
                                                  'padding-left' : comp.icon ? '30px' : ''
                                                }"
                                                [attr.min]="comp.minValue" 
                                                >
                                                <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                  {{comp.label}}: {{comp.errorMessage}}
                                               </div>
                                                <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                    {{comp.label}}: Required fields must be filled in.
                                                 </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="comp.datatype == 'address_auto_complete'">
                                          <div class="col-sm-9">
                                            <input type="{{comp.datatype}}" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                            placeholder="Enter a location"
                                            class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                            [ngStyle]="{
                                              'padding-left' : comp.icon ? '30px' : ''
                                            }" autocomplete="off"
                                            >
                                          </div>
                                        </ng-container>
                                        <ng-container *ngIf="comp.datatype == 'multilinetext'">
                                          <div class="col-sm-9">
                                            <textarea class="form-control input-type" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                            id="{{tableId}}{{comp.id}}"  #MultiLine="ngModel" style="min-height:62px" (change)="valuechange($event,comp)"
                                            ></textarea>
  
                                              <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (MultiLine?.dirty || MultiLine?.touched) && comp.required) || comp.error">
                                                  {{comp.label}}: Required fields must be filled in.
                                               </div>
                                          </div>
                                      </ng-container>
  
                                        <ng-container *ngIf="comp.datatype == 'mobile'">
                                            <div class="col-sm-9">
                                                <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Number="ngModel" [attr.minlength]="comp.minLength"  [attr.maxlength]="comp.maxLength"
                                                (input)="validatePhoneNumber($event,comp)"
                                                >
                                                <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                  {{comp.label}}: {{comp.errorMessage}}
                                               </div>
                                                <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                    {{comp.label}}: Required fields must be filled in.
                                                 </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="comp.datatype == 'multitext'">
                                            <div class="col-sm-9">
                                                <textarea class="form-control input-type" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}" style="height:80px" id="{{tableId}}{{comp.id}}"  #TextArea="ngModel"></textarea>
                                                <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (TextArea?.dirty || TextArea?.touched) && comp.required) || comp.error">
                                                    {{comp.label}}: Required fields must be filled in.
                                                 </div>
                                            </div>
                                        </ng-container>
  
  
                                        <ng-container *ngIf="comp.datatype == 'decimal'">
                                          <div class="col-sm-9">
                                              <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                              class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Decimal="ngModel" (change)="valuechange($event,comp)">
                                              <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (Decimal?.dirty || Decimal?.touched) && comp.required) || comp.error">
                                                <div>{{comp.label}}: Required fields must be filled in.</div>
                                               </div>
                                          </div>
                                      </ng-container>
  
                                        <ng-container *ngIf="comp.datatype == 'amount'">
                                            <div class="col-sm-9">
                                              <img *ngIf="comp.icon" [src]="comp.icon" class="divImage">
                                                <span style="position: absolute;
                                                padding: 7px;"
                                                [ngStyle]="{
                                                  'margin-left' : comp.icon ? '20px' : '0px'
                                                }"
                                                >{{comp.currency}}</span>
                                                <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Amount="ngModel" (focusout) = "validateAmount(comp)"
                                                [ngStyle]="{
                                                  'padding-left' : comp.icon ? '35px' : '16px'
                                                }" (change)="valuechange($event,comp)"
                                                >
                                            </div>
                                        </ng-container>
  
                                        <ng-container  *ngIf="comp.datatype== 'optionset'">
                                            <div class="col-sm-9">
                                                <select [(ngModel)]="comp.selectedOptionLabel"  class="form-control input-type"  [ngModelOptions]="{standalone: true}" [compareWith]="compareFn" #OptionSet="ngModel"
                                                (change)="valuechange($event,comp)" id="{{tableId}}{{comp.id}}"
                                                >
                                                    <option *ngFor="let option of comp.options" [ngValue]="option">{{option.key}}</option>
                                                </select>
                                                <div class="text-danger" *ngIf="comp.errorMessage && comp.errorMessage!==''">
                                                  {{comp.label}}: {{comp.errorMessage}}
                                               </div>
                                                <div class="text-danger" *ngIf="(comp.selectedOptionLabel.value=='' || comp.selectedOptionLabel.value == null) && comp.required && comp.error ">
                                                  {{comp.label}}: Required fields must be filled in.
                                               </div>
                                            </div>
                                        </ng-container>
  
                                        <ng-container *ngIf="(comp.datatype == 'multioptionset' || comp.datatype == 'coupon') && this.formLoaded ">
                                          <div class="col-sm-9">
                                            <form [formGroup]="comp.dropDownForm">
                                              <ng-multiselect-dropdown
                                              [settings]="comp.dropdownSettings"
                                              [data]="comp.options"
                                              [disabled]="comp.lock"
                                              (onSelect)="onItemSelect(comp,$event)"
                                              (onSelectAll)="onSelectAll(comp,$event)"
                                              (onDeSelect)="onItemDeSelect(comp,$event)"
                                              (onDeSelectAll)="onUnSelectAll(comp)"
                                              (ngModelChange)="callMethod($event,comp)"
                                              [formControl]="comp.dropDownForm.controls['myItems']"
                                              >
                                            </ng-multiselect-dropdown>
                                            </form>
                                            <div class="text-danger" *ngIf="(comp.selectedItem.length==0) && comp.error ">
                                              {{comp.label}}: Required fields must be filled in.
                                           </div>
                                        </div>
                                        </ng-container>

                                        
                                        <ng-container *ngIf="comp.datatype == 'file'">
                                          <div class="col-sm-9">
                                            <input *ngIf="!comp.value"
                                            type="file" accept=".txt,.jpg,.jpeg,.png,.gif,.rar,.zip"
                                            (change)="onFileSelected($event,comp)">
                                            <div class="download-file" (click)="downloadFile(comp)" *ngIf="comp.value">Download 
                                              <span [ngStyle]="{'color': form_section_header_back_clr ? form_section_header_back_clr : ''}">{{comp.fileName}}</span>
                                              <i class="fa fa-times" style="float:right;font-size: 16px;" (click) = "removeFile(comp)" aria-hidden="true"></i>
                                            </div>
                                          </div>
                                      </ng-container>

                                        <ng-container *ngIf="comp.datatype == 'datetime'">
                                          <div class="col-sm-9">
                                            <img *ngIf="comp.icon" [src]="comp.icon" class="divImage" >
                                              <input type="{{comp.datatype}}-local" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                              class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                              [ngStyle]="{
                                                'padding-left' : comp.icon ? '30px' : ''
                                              }"
                                              [attr.min]="comp.minValue" 
                                              >
                                              <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                {{comp.label}}: {{comp.errorMessage}}
                                             </div>
                                              <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                  {{comp.label}}: Required fields must be filled in.
                                               </div>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="comp.datatype == 'boolean'">
                                        <div class="col-sm-9">
                                          <mat-slide-toggle [(ngModel)]="comp.value" [ngModelOptions]="{standalone: true}" (change)="changeToggleCss(comp)"></mat-slide-toggle>
                                        </div>
                                      </ng-container>
                                        <ng-container *ngIf="comp.datatype == 'lookup'">
                                            <div class="col-sm-9">
                                              <i *ngIf="!comp.calculated && !comp.lock" class="fa fa-plus plus-icon" (click)="lookUpClicked(comp,true)" [ngStyle]="{
                                                color : form_section_header_back_clr ? form_section_header_back_clr  : '#000'
                                              }"></i>
                                                <img *ngIf="comp.icon" [src]="comp.icon" class="divImage">
  
                                                <img class="divImage"  [src] = "comp.lookUpIcon">
                                                <ng-select *ngIf="comp.selectedOptionLabel" [(ngModel)]="comp.selectedOptionLabel"
                                                id="{{comp.selectedOptionLabel.id}}"
                                                placeholder="Select"
                                                [compareWith]="compareFnLookup"
                                                [disabled]="comp.calculated || comp.lock"
                                                (dblclick)="lookUpClicked(comp,false)"
                                                (clear)="resetCalculations(comp)"
                                                (change)="selectedLookup($event,comp)"  [ngModelOptions]="{standalone: true}" #lookUpComp>
                                                  <ng-option *ngFor="let option of comp.options" [value]="option" >{{option.value}}
                                                    <span class="lookup-hint">{{option.hint}}</span>
                                                  </ng-option>
                                                 </ng-select>
                                                 <div class="text-danger" *ngIf="(comp.selectedOptionLabel && (comp.selectedOptionLabel.value=='' || comp.selectedOptionLabel.value == null)) && comp.required && comp.error">
                                                    {{comp.label}}: Required fields must be filled in.
                                                 </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </form>
  
                        </div>
                      </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="data.section && data.section.length==0">
              <app-notes *ngIf="rowData"
              [quick]="true"
              [theme]="theme"
              [ownerId]="userId"
              [ownerName]="userName"
              [tableLogicalName]="tableLogicalName"
              [rowId]="this.rowData[this.primaryColumn]"></app-notes>
          </ng-container>
        </mat-tab>    
      </ng-container>
      </mat-tab-group>
      <app-loader *ngIf="showLoader" [icon]='loaderIcon' [width]="'100px'"></app-loader>
  
    </div>
    <div class="quick-button" align="center" *ngIf="selectedTab == 'General'">
      <!-- <button  class="mat-raised-button mat-primary" style="margin-right:20px" [disabled] = 'disableButton'
      [ngStyle]="{
        'background-color' : buttonColor ? buttonColor : '#673ab7',
        'cursor' : disableButton ? 'not-allowed' : 'pointer'
      }"
    (click)="onConfirmClick(false)">Save</button> -->
      <button  class="mat-raised-button mat-primary" style="margin-right:20px"  [disabled] = 'disableButton'
      [ngStyle]="{
        'background-color' : buttonColor ? buttonColor : '#673ab7',
        'cursor' : disableButton ? 'not-allowed' : 'pointer'
      }"
    (click)="onConfirmClick(true)">Save and Close</button>
    <button class="mat-raised-button" (click)="closeForm(false,true)">Cancel</button>
    </div>
  </div>
</div>
