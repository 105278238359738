import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent{
  options : any = [];

  toggleNotSelected = false

  selectedOption !: any
  selectedOptionLabel !: string
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  buttonColor !: string

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,private dialogRef: MatDialogRef<ShareDialogComponent>) {
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      this.buttonColor = data.buttonColor
      if(this.confirmButtonText == 'Share'){
        for(let i of data.dataInfo.contacts){
          this.options.push({
            label : i[data.dataInfo.name],
            value : i[data.dataInfo.name],
            email : i[data.dataInfo.email],
            mobile : i[data.dataInfo.mobile],
            isMobileChecked : true,
            isEmailChecked : true
          })
        }
      }
      else{
        for(let i of data.dataInfo.userDetails){
          this.options.push({
            label : i[data.dataInfo.userName],
            userId: i[data.dataInfo.userId],
          })
        }
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(this.selectedOption);
  }


  onContactChanged(contactName  : string) {
    this.selectedOption = this.getSelectedContactByName(contactName);
  }

  getSelectedContactByName(selectedName: string): any {
    return this.options.find((option : any) => option.label === selectedName);
  }

  toggle(e : any, checkedItem : string){
    this.toggleNotSelected = false
    if(checkedItem == "mobile"){
      this.selectedOption.isMobileChecked = e.checked
    }
    else{
      this.selectedOption.isEmailChecked = e.checked
    }
    if( !this.selectedOption.isMobileChecked && (!this.selectedOption.isEmailChecked || this.selectedOption.email == null)){
      this.toggleNotSelected = true
    }
  }
}
