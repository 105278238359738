import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of as observableOf } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { SessionService } from 'src/app/services/session-service/session.service';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';
import { User } from '../../models/User';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.html',
  styleUrls: ['./login-component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {


  @Input()
  logo_details !: any

  @Output()
  emitNewSession = new EventEmitter<boolean>();

  otpNumber !: any
  showLoader : boolean = false
  showVerified: boolean = false
  errorMessage !: string
  emailId !: string
  pass !: string
  user !: User
  userProfile !: any
  currentLocation : string = ''
  lat !: any
  long !: any
  emailVerified : boolean = false
  emailAddress !: string
  modalErrorMessage = ""
  modalSuccessMessage=""
  progressBarIcon = '../../assets/Images/AT1.gif'
  themeColor !: string

  // loginForm: FormGroup = this.formBuilder.group({
  //   email: ['', [Validators.required]],
  //   password: ['', [Validators.required]]
  // });

  signUpForm: FormGroup = this.formBuilder.group({
    fname: ['', [Validators.required]],
    lname: ['', [Validators.required]],
    mno: ['', [Validators.required]],
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
    rPassword: ['', [Validators.required]]
  });

  otpForm: FormGroup = this.formBuilder.group({
    otp1: ['', [Validators.required, Validators.maxLength(1)]],
    otp2: ['', [Validators.required, Validators.maxLength(1)]],
    otp3: ['', [Validators.required, Validators.maxLength(1)]],
    otp4: ['', [Validators.required, Validators.maxLength(1)]],
    otp5: ['', [Validators.required, Validators.maxLength(1)]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private tableOperationService : TableOperationService,
    private sessionService: SessionService,
    private toast: ToastrService,
    private appService: ApplicationService) { }

  ngAfterViewInit(): void {
    let checkMultipleSession = sessionStorage.getItem('multipleSession')
    if(checkMultipleSession){
      this.toast.error('You have been logged out, Duplicate session not allowed')
      sessionStorage.removeItem('multipleSession')
    }
    let elem = document.getElementsByClassName('btn')
    if(elem && elem.length>0){
      for(let i=0; i<elem.length;i++){
        (<HTMLElement>elem[i]).style.backgroundColor = this.logo_details.theme_clr
      }
    }
    this.callCssForCheckBox()
  }

  ngOnInit(): void {
    this.commonService.logging("Login Component initialized")
    this.progressBarIcon = this.logo_details['progress-bar_icon'] 
    this.getLocation();
    var back1 = <HTMLElement>document.getElementById("loginBackGround");
    //back1.style.background =this.logo_details.login_background;
    back1.style.background =this.logo_details.login_background_image + " no-repeat fixed center";
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.currentLocation = this.lat+','+this.long
        }
      })
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  validateUser(loginForm: NgForm){
    this.errorMessage = ''
    this.showLoader = true
    this.appService.getUser(loginForm.value.email, loginForm.value.password,this.commonService.getAuthToken())
    .subscribe((res) => {
      if (res && res.value && res.value.length>0) {
        this.user = res.value[0]
        this.getUserProfile()
      }
      else{
        this.showLoader = false
        this.resetFormField();
        this.errorMessage = "Username or Password incorrect";
      }
    })
}


  signUpNewUser() {
    let modal = document.getElementById("myOtpModal");
    if (modal) {
      modal.style.display = "block";
      var testarray = document.getElementsByClassName("container");
      for (var i = 0; i < testarray.length; i++) {
        testarray[i].className += " blur";
      }
    }
    this.otpNumber = Math.floor(Math.random() * 90000) + 10000;
    const otpData = {
      "cre57_name": this.otpNumber.toString(),
      "cre57_mobile": this.signUpForm.value.mno
    }
    this.showVerified = false
    this.commonService.sendSMS(otpData).subscribe((res)=>{
        this.commonService.logging(res)
    })

    // this.commonService.logging(this.otpNumber)

    // this.commonService.createUser(userDetail)
    //   .pipe(
    //     mergeMap((res : any) => {
    //       if (res) {
    //         let modal = document.getElementById("myOtpModal");
    //         if (modal) {
    //           modal.style.display = "block";
    //           var testarray = document.getElementsByClassName("container");
    //           for (var i = 0; i < testarray.length; i++) {
    //             testarray[i].className += " blur";
    //           }
    //         }
    //         this.otpNumber = Math.floor(Math.random() * 90000) + 10000;

    //         const otpData = {
    //           "cre57_name": this.otpNumber,
    //           "cre57_mobile": userDetail.cre57_mobile
    //         }
    //         this.showVerified = false
    //         return this.commonService.sendSMS(otpData);
    //       }
    //       return of(undefined)
    //     })
    //   )
    //   .subscribe(val => this.commonService.logging(val));
  }

  callCssForCheckBox(){
    let elem1 = document.querySelectorAll('input[type=checkbox]')
    if(elem1 && elem1.length>0){
      for(let i=0; i<elem1.length;i++){
        if((<HTMLFormElement>elem1[i]).checked){
          (<HTMLFormElement>elem1[i]).style.backgroundColor = this.logo_details.theme_clr,
          (<HTMLFormElement>elem1[i]).style.borderColor = this.logo_details.theme_clr
        }
        else{
          (<HTMLFormElement>elem1[i]).style.backgroundColor = '#FFF',
          (<HTMLFormElement>elem1[i]).style.borderColor = this.logo_details.theme_clr
        }
      }
    }
  }

  callCssForInputFocus(){
    let elem2 = document.querySelectorAll('input:focus')
    if(elem2 && elem2.length>0){
      for(let i=0; i<elem2.length;i++){
        (<HTMLElement>elem2[i]).style.setProperty('--form-theme-color',this.logo_details.theme_clr );
        (<HTMLElement>elem2[i]).style.setProperty('--form-text-color',this.logo_details.form_text_Clr);
      }
    }
    let elem3 = document.getElementsByClassName('form-label')
    if(elem3 && elem3.length>0){
      for(let i=0; i<elem3.length;i++){
        (<HTMLElement>elem3[i]).style.setProperty('--form-theme-color',this.logo_details.theme_clr );
        (<HTMLElement>elem3[i]).style.setProperty('--form-text-color',this.logo_details.form_text_Clr);
      }
    }
  }

  getUserActiveSession(){
    this.sessionService.getUserActiveSessions(this.user.cre57_at_userid,this.commonService.getAuthToken()).subscribe((res)=>{
      // this.commonService.logging(res)
      if(res && res.value){
        for(const i of res.value){
          if(!i.cre57_end){
            this.commonService.closeSession(i.cre57_at_sessionid,this.commonService.getAuthToken())
          }
        }
        this.startUserSession()
      }
    })
  }

  startUserSession(){
    const date = this.commonService.formatDate(new Date(),true)
    this.sessionService.startSession(this.user.cre57_at_userid,date,this.currentLocation,this.commonService.getAuthToken()).subscribe((res)=>{
      // this.commonService.logging(res)
      if(res && res.status && res.id){
        this.showLoader = false
        localStorage.setItem('session',JSON.stringify(res.id))
        localStorage.setItem('user',JSON.stringify(this.user))
        localStorage.setItem('userProfile',JSON.stringify(this.userProfile))
        this.commonService.setCurrentUser(this.user)
        this.commonService.setCurrentSession(res.id)
        this.emitNewSession.emit(true)
      }
      else{
        this.showLoader = false;
        this.errorMessage = "New session creation failed"
      }
    })
  }

  getUserProfile(){
    this.commonService.getUserProfile(this.user.cre57_at_userid,this.commonService.getAuthToken()).subscribe((res)=>{
      this.commonService.logging("User Profile Fetched")
      this.user.cre57_userprofile = (res && res.json) ? res.json : this.user.cre57_userprofile
      if(this.user.cre57_userprofile){
        this.userProfile = JSON.parse(this.user.cre57_userprofile)
        let res3 = this.userProfile.subcriptions
        if(res3 && res3.length>0){
          let startDate  = new Date(res3[0].StartDate)
          let endDate  = new Date(res3[0].EndDate)
          let currentDate= new Date()
          let startDateFormattted = this.commonService.formatDate(startDate,false)
          // let endDateFormattted = this.commonService.formatDate(endDate,false)
          // let currentDateFormattted = this.commonService.formatDate(currentDate,false)


          let endDateFormattted : any = new Date(res3[0].EndDate);
          let currentDateFormattted :  any = new Date();
          const diffTime = endDateFormattted - currentDateFormattted;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          if (diffDays > 0) {
            if(this.userProfile.roles && this.userProfile.roles.length>0){
              // this.getUserActiveSession()
              this.startUserSession()
            }
            else{
              this.showLoader = false
              this.resetFormField();
              this.errorMessage = "No roles are assigned";
            }
          }
          else{
            this.showLoader = false
            this.resetFormField();
            this.errorMessage = "Your subscription is expired";
          }
        }
        else{
          this.showLoader = false
          this.resetFormField();
          this.errorMessage = "No active subscription found";
        }
      }
    })
  }

  closeModal() {
    let modal = document.getElementById("myOtpModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementById("mycontainer");
    if (container) {
      container.classList.remove("blur");
    }
    this.resetFormField()
  }

  confimOtp() {
    let enteredOtp = this.otpForm.value.otp1 + this.otpForm.value.otp2 + this.otpForm.value.otp3 + this.otpForm.value.otp4 + this.otpForm.value.otp5
    if (this.otpNumber == enteredOtp) {
      const userDetail = {
        "cre57_firstname": this.signUpForm.value.fname,
        "cre57_lastname": this.signUpForm.value.lname,
        "cre57_mobile": this.signUpForm.value.mno,
        "cre57_email": this.signUpForm.value.email,
        "cre57_password": this.signUpForm.value.password
      }
      this.commonService.createUser(userDetail).subscribe((res)=>{
          this.showVerified = true
          this.errorMessage = ''
        // if(res){
        //   this.showVerified = true
        //   this.errorMessage = ''
        // }
        // else{
        //   this.showVerified = false
        //   this.resetOtpForm();
        //   this.errorMessage = "Error in creating user"
        // }
      })
    }
    else {
      this.showVerified = false
      this.resetOtpForm();
      this.errorMessage = "Please enter correct otp"
    }
  }

  resetOtpForm(){
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required, Validators.maxLength(1)]],
      otp2: ['', [Validators.required, Validators.maxLength(1)]],
      otp3: ['', [Validators.required, Validators.maxLength(1)]],
      otp4: ['', [Validators.required, Validators.maxLength(1)]],
      otp5: ['', [Validators.required, Validators.maxLength(1)]]
    });
  }
  resendOtp(mno: any) {
    this.showVerified = false
    this.otpNumber = Math.floor(Math.random() * 90000) + 10000;

    const otpData = {
      "cre57_name": this.otpNumber.toString(),
      "cre57_mobile": mno
    }
    this.commonService.sendSMS(otpData).subscribe((res) => {
      this.commonService.logging(res);
    })
  }

  resetFormField() {
    this.emailId=''
    this.pass = ''
    // this.loginForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', [Validators.required]]
    // });

    this.signUpForm = this.formBuilder.group({
      fname: ['', [Validators.required]],
      lname: ['', [Validators.required]],
      mno: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rPassword: ['', [Validators.required]]
    });
  }

  openForgotPasswordModal(){
    let modal = document.getElementById("forgotPasswordModal");
    if (modal) {
      modal.style.display = "block";
      var testarray = document.getElementsByClassName("login-container");
      for (var i = 0; i < testarray.length; i++) {
        testarray[i].className += " blur";
      }
    }
  }

  closeForgotPasswordModal(){
    this.emailAddress = ""
    this.modalErrorMessage = ""
    this.modalSuccessMessage = ""
    let modal = document.getElementById("forgotPasswordModal");
    if (modal) {
      modal.style.display = "none";
    }
    var container = document.getElementsByClassName("login-container");
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove("blur")
    }
    this.resetFormField()
  }

  verifyEmail(){
    this.modalErrorMessage = ""
    if(this.emailAddress){
      // var re = /\S+@\S+\.\S+/;
      // let status = re.test(this.emailAddress);
      // if(!status){
      //   this.modalErrorMessage = "Email address is invalid"
      // }
      // else{
        this.commonService.validateUserByUserName(this.emailAddress,this.commonService.getAuthToken()).subscribe((res)=>{
          if(res && res.value && res.value.length>0){
            this.sendMail(res.value[0].cre57_at_userid)
          }
          else{
            this.modalErrorMessage = "No user exist"
          }
        })
      // }
    }
  }

  sendMail(userId : string){
    let payload = {
      "cre57_resetpassword":true
    }
    this.tableOperationService.updateTableRecords(userId,this.commonService.getAuthToken(),'cre57_at_users',payload).subscribe((res)=>{
      if(res && res.status &&  res.status == "True"){
        this.modalSuccessMessage = "An email has been sent with new password"
      }
      else{
        this.modalErrorMessage = "Failed to send email"
      }
    })
  }

}
