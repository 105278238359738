<div class="form-container">
  <div class="center-content row">
    <img class="woods-logo" src="../../../assets/Images/woods-kitchen-logo.jpeg">
    <h2 style="margin-top: -45px;text-align:center">Woodz Kitchenz Lead Form</h2>
  </div>
  <form class="my-form">
    <div class="row form-group" *ngFor="let fm of myForm">
      <div class="col-sm-3"></div>
      <div class="col-sm-3">
        <p class="mb-0">{{fm.label}}</p>
      </div>
      <div class="col-sm-3" *ngIf="fm.type !== 'select'">
        <input type="{{fm.type}}" value="{{fm.value}}" [(ngModel)] = "fm.value" name="{{fm.label}}"
        class="form-control input-type" [attr.minlength]="fm.minLength"  [attr.maxlength]="fm.maxLength">
      </div>
      <div class="col-sm-3" *ngIf="fm.type == 'select'">
        <select [(ngModel)]="fm.value"  class="form-control input-type"  [ngModelOptions]="{standalone: true}" [compareWith]="compareFn" #OptionSet="ngModel">
          <option *ngFor="let option of fm.options" [ngValue]="option">{{option.Text}}</option>
      </select>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </form>
    <button  class="mat-raised-button mat-primary woods-submit center-content"
    [ngStyle]="{
      'background-color' : '#673ab7'
    }"
  (click)="onSaveClick()" tabindex="1" >Save and New</button>
  <app-loader *ngIf="showLoader" ></app-loader>
</div>



