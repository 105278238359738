<div class='form-container' #formDrawer>
  <div *ngIf="showFormMessage" class="formMessage">
    <div>
      <span style="margin-left:10px;vertical-align: middle;">{{formStatusReason}}</span>
      <span class="close" (click)="closeMessage()">X</span>
    </div>
    </div>
    <div class="form-top">
      <div style="margin-left:40px" [ngStyle]="{
        'opacity': showLoader ? '0.5' : '1',
        'pointer-events': showLoader ? 'none' : 'all'
    }">
        <app-button
            [visibleButton]="visibleButton"
            [dropdownButton]="dropdownButton"
            [formButton]="formButton"
            (emitButtonClick)="performFormEvent($event)"
        ></app-button>
      </div>

      <i *ngIf="!newWindow" (click)="closeForm()" class="fa fa-arrow-left close-form" [ngStyle]="{'color': closeIconColor ? closeIconColor : '#000'}"></i>

      <div *ngIf="headerData && headerData.length>0" class="row header-details">
        <ng-container *ngFor="let head of headerData">
          <ng-container *ngIf="head.primaryField">
            <div class="col-md-6" >
              <div class="head-content">
                <span *ngIf="head.value !== null && head.icon == 'initials'" class="imageName-class"
                  [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}">{{head.initials | uppercase}}</span>
                  <span *ngIf="head.value !== null && head.icon == 'image'" class="imageName-class"
                  [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}"><img style="width:24px;height:25px" [src] = "head.initials"></span>
                <div>
                  <span class="head-value" *ngIf="head.value !== null">{{head.value}}</span>
                  <span class="head-label">{{head.label}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!head.primaryField && head.value !== null">
            <div class="col-md-2" style="margin-top:10px">
              <span class="head-value">{{head.value}}</span>
              <span class="head-label">{{head.label}}</span>
            </div>

          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="headerData && headerData.length>0" class="header-details_tab">
        <ng-container *ngFor="let head of headerData">
          <ng-container *ngIf="head.primaryField">
            <div class="head_tab">
              <div class="head-content">
                <span *ngIf="head.value !== null && head.icon == 'initials'" class="imageName-class"
                  [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}">{{head.initials | uppercase}}</span>
                  <span *ngIf="head.value !== null && head.icon == 'image'" class="imageName-class"
                  [ngStyle]="{'background-color': form_section_header_back_clr ? form_section_header_back_clr : '#FF7851'}"><img style="width:24px;height:25px" [src] = "head.initials"></span>
                <div>
                  <span class="head-value" *ngIf="head.value !== null">{{head.value}}</span>
                  <span class="head-label">{{head.label}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="head_next_tab" *ngIf="!head.primaryField && head.value !== null">
            <div style="margin-top:10px">
              <span class="head-value">{{head.value}}</span>
              <span class="head-label">{{head.label}}</span>
            </div>

          </div>
        </ng-container>
      </div>
    </div>


    <mat-tab-group (selectedTabChange)="changeActiveCss()" [ngStyle]="{'margin-top': headerData.length>0 ? '156px' : '96px'}">
        <ng-container *ngFor="let data of formData">
            <mat-tab *ngIf="data.visible" label="{{data.name}}">
                <ng-container *ngIf="data.section && data.section.length>0">
                    <ng-container *ngFor="let sec of data.section">
                        <ng-container *ngIf="sec.visible && sec.formType!=='NewForm'">
                            <div class="col-sm-{{sec.size}} section-card" [ngStyle]="{'border-color': form_section_border_clr  ? form_section_border_clr : ''}">
                                <div [ngStyle]="{
                                  'color': form_section_text_clr ? form_section_text_clr : '',
                                  'background-color': form_section_header_back_clr ? form_section_header_back_clr : ''

                                }" class="sectionName">{{sec.name}}</div>
                                <hr [ngStyle]="{'color': form_section_header_line_clr ? form_section_header_line_clr : '',
                                'background-color': form_section_header_back_clr ? form_section_header_back_clr : ''
                              }">
                                <form [ngStyle]="{'color': form_text_clr ? form_text_clr : '',
                                                'background-color': form_back_clr ? form_back_clr : ''
                                  }">
                                    <div class="row form-group" *ngFor="let chunk of sec.chunkedArray">
                                      <ng-container *ngFor="let comp of chunk">

                                        <span class="columns col-sm-{{sec.colSize}}" *ngIf="comp.type == 'field' && comp.visible ">
                                            <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}" style="display: inline-flex;">
                                                <p class="mb-0">{{comp.label}}</p>
                                                <div *ngIf="comp.required" class="asterisk">*</div>
                                            </div>

                                            <ng-container *ngIf="comp.datatype == 'text' || comp.datatype == 'email' || comp.datatype == 'date' || comp.datatype == 'number'">
                                                <div class=" col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                  <img *ngIf="comp.icon" [src]="comp.icon" class="divImage" >
                                                    <input type="{{comp.datatype}}" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                    class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                                    [ngStyle]="{
                                                      'padding-left' : comp.icon ? '30px' : ''
                                                    }"
                                                    [attr.min]="comp.minValue" 
                                                    >
                                                    <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                      {{comp.label}}: {{comp.errorMessage}}
                                                   </div>
                                                    <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                        {{comp.label}}: Required fields must be filled in.
                                                     </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'address_auto_complete'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <input type="{{comp.datatype}}" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                placeholder="Enter a location"
                                                class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                                [ngStyle]="{
                                                  'padding-left' : comp.icon ? '30px' : ''
                                                }" autocomplete="off"
                                                >
                                              </div>
                                            </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'multilinetext'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <textarea class="form-control input-type" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                id="{{tableId}}{{comp.id}}"  #MultiLine="ngModel" style="min-height:62px" (change)="valuechange($event,comp)"
                                                ></textarea>

                                                  <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (MultiLine?.dirty || MultiLine?.touched) && comp.required) || comp.error">
                                                      {{comp.label}}: Required fields must be filled in.
                                                   </div>
                                              </div>
                                          </ng-container>

                                            <ng-container *ngIf="comp.datatype == 'mobile'">
                                                <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                    <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                    class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Number="ngModel" [attr.minlength]="comp.minLength"  [attr.maxlength]="comp.maxLength"
                                                    (input)="validatePhoneNumber($event,comp)"
                                                    >
                                                    <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                      {{comp.label}}: {{comp.errorMessage}}
                                                   </div>
                                                    <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                        {{comp.label}}: Required fields must be filled in.
                                                     </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'multitext'">
                                                <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                    <textarea class="form-control input-type" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}" style="height:80px" id="{{tableId}}{{comp.id}}"  #TextArea="ngModel"></textarea>
                                                    <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (TextArea?.dirty || TextArea?.touched) && comp.required) || comp.error">
                                                        {{comp.label}}: Required fields must be filled in.
                                                     </div>
                                                </div>
                                            </ng-container>


                                            <ng-container *ngIf="comp.datatype == 'decimal'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                  <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                  class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Decimal="ngModel" (change)="valuechange($event,comp)"
                                                  [attr.min]="comp.minValue"   [attr.max]="comp.maxValue"
                                                  >
                                                  <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (Decimal?.dirty || Decimal?.touched) && comp.required) || comp.error">
                                                    <div>{{comp.label}}: Required fields must be filled in.</div>
                                                   </div>
                                              </div>
                                          </ng-container>

                                            <ng-container *ngIf="comp.datatype == 'amount'">
                                                <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                  <img *ngIf="comp.icon" [src]="comp.icon" class="divImage">
                                                    <span style="position: absolute;
                                                    padding: 7px;"
                                                    [ngStyle]="{
                                                      'margin-left' : comp.icon ? '20px' : '0px'
                                                    }"
                                                    >{{comp.currency}}</span>
                                                    <input type="number" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                    class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Amount="ngModel" (focusout) = "validateAmount(comp)"
                                                    [ngStyle]="{
                                                      'padding-left' : comp.icon ? '35px' : '16px'
                                                    }" (change)="valuechange($event,comp)"
                                                    >
                                                    <!-- <div class="text-danger" *ngIf="((comp.value=='' || comp.value == null) && (Amount?.dirty || Amount?.touched) && comp.required) || comp.error">
                                                      <div *ngIf="!comp.errorMessage">{{comp.label}}: Required fields must be filled in.</div>
                                                        <div *ngIf="comp.errorMessage">{{comp.label}}: {{comp.errorMessage}}</div>
                                                     </div> -->
                                                </div>
                                            </ng-container>

                                            <ng-container  *ngIf="comp.datatype== 'optionset'">
                                                <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                    <select [(ngModel)]="comp.selectedOptionLabel"  class="form-control input-type"  [ngModelOptions]="{standalone: true}" [compareWith]="compareFn" #OptionSet="ngModel"
                                                    (change)="valuechange($event,comp)" id="{{tableId}}{{comp.id}}"
                                                    >
                                                        <option *ngFor="let option of comp.options" [ngValue]="option">{{option.key}}</option>
                                                    </select>
                                                    <div class="text-danger" *ngIf="comp.errorMessage && comp.errorMessage!==''">
                                                      {{comp.label}}: {{comp.errorMessage}}
                                                   </div>
                                                    <div class="text-danger" *ngIf="(comp.selectedOptionLabel.value=='' || comp.selectedOptionLabel.value == null) && comp.required && comp.error ">
                                                      {{comp.label}}: Required fields must be filled in.
                                                   </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="(comp.datatype == 'multioptionset' || comp.datatype == 'coupon') && this.formLoaded ">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <form [formGroup]="comp.dropDownForm">
                                                  <ng-multiselect-dropdown
                                                  [settings]="comp.dropdownSettings"
                                                  [data]="comp.options"
                                                  [disabled]="comp.lock"
                                                  (onSelect)="onItemSelect(comp,$event)"
                                                  (onSelectAll)="onSelectAll(comp,$event)"
                                                  (onDeSelect)="onItemDeSelect(comp,$event)"
                                                  (onDeSelectAll)="onUnSelectAll(comp)"
                                                  (ngModelChange)="callMethod($event,comp)"
                                                  [formControl]="comp.dropDownForm.controls['myItems']"
                                                  >
                                                </ng-multiselect-dropdown>
                                                </form>
                                                <div class="text-danger" *ngIf="(comp.selectedItem && comp.selectedItem.length==0) && comp.error ">
                                                  {{comp.label}}: Required fields must be filled in.
                                               </div>
                                            </div>
                                            </ng-container>

                                            <ng-container *ngIf="comp.datatype == 'lookup'">
                                              <!--  id="{{comp.selectedOptionLabel.id}}" -->
                                                <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                    <i class="fa fa-plus plus-icon" *ngIf="!comp.calculated && !comp.lock" (click)="lookUpClicked(comp,true)" [ngStyle]="{
                                                      color : plusIconColor ? plusIconColor  : '#000'
                                                    }"></i>
                                                    <img *ngIf="comp.icon" [src]="comp.icon" class="divImage">
                                                    <!-- <ng-select *ngIf="comp.options && comp.options.length>0" [items]="comp.options"
                                                      bindLabel="value"
                                                      placeholder="Select"
                                                      id="{{tableId}}{{comp.id}}"

                                                      [(ngModel)]="comp.selectedOptionLabel"
                                                      (clear)="resetCalculations(comp)"
                                                      (change)="selectedLookup($event,comp)"
                                                      [ngModelOptions]="{standalone: true}"
                                                    >
                                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                                      {{item.value}}<span class="lookup-hint">{{item.hint}}</span>
                                                  </ng-template>
                                                  </ng-select> -->
                                                    <img class="divImage"  [src] = "comp.lookUpIcon">
                                                    <ng-select *ngIf="comp.selectedOptionLabel" [(ngModel)]="comp.selectedOptionLabel"
                                                    id="{{comp.selectedOptionLabel.id}}"
                                                    placeholder="Select"
                                                    [disabled]="comp.calculated || comp.lock"
                                                    [compareWith]="compareFnLookup"
                                                    (dblclick)="lookUpClicked(comp,false)"
                                                    (clear)="resetCalculations(comp)"
                                                    (change)="selectedLookup($event,comp)"  [ngModelOptions]="{standalone: true}" #lookUpComp>
                                                      <ng-option *ngFor="let option of comp.options" [value]="option" >{{option.value}}
                                                        <span class="lookup-hint">{{option.hint}}</span>
                                                      </ng-option>
                                                     </ng-select>
                                                     <div class="text-danger" *ngIf="(comp.selectedOptionLabel && (comp.selectedOptionLabel.value=='' || comp.selectedOptionLabel.value == null)) && comp.required && comp.error">
                                                        {{comp.label}}: Required fields must be filled in.
                                                     </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'file'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <input *ngIf="!comp.value"
                                                type="file" accept=".txt,.jpg,.jpeg,.png,.gif,.rar,.zip"
                                                (change)="onFileSelected($event,comp)">
                                                <div class="download-file" (click)="downloadFile(comp)" *ngIf="comp.value">Download 
                                                  <span [ngStyle]="{'color': form_section_header_back_clr ? form_section_header_back_clr : ''}">{{comp.fileName}}</span>
                                                  <i class="fa fa-times" style="float:right;font-size: 16px;" (click) = "removeFile(comp)" aria-hidden="true"></i>
                                                </div>
                                              </div>
                                          </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'datetime'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <img *ngIf="comp.icon" [src]="comp.icon" class="divImage" >
                                                  <input type="{{comp.datatype}}-local" value="{{comp.value}}" [(ngModel)] = "comp.value" name="{{comp.label}}"
                                                  class="form-control input-type" id="{{tableId}}{{comp.id}}"  #Name="ngModel"  (change)="valuechange($event,comp)"
                                                  [ngStyle]="{
                                                    'padding-left' : comp.icon ? '30px' : ''
                                                  }"
                                                  [attr.min]="comp.minValue" 
                                                  >
                                                  <div class="text-danger" *ngIf="(comp.errorMessage && comp.errorMessage!=='')">
                                                    {{comp.label}}: {{comp.errorMessage}}
                                                 </div>
                                                  <div class="text-danger" *ngIf="(comp.value=='' || comp.value == null) && comp.required && comp.error">
                                                      {{comp.label}}: Required fields must be filled in.
                                                   </div>
                                              </div>
                                            </ng-container>
                                            <ng-container *ngIf="comp.datatype == 'boolean'">
                                              <div class="col-sm-{{sec.eachColSize ? sec.eachColSize : sec.colSize}}">
                                                <mat-slide-toggle [(ngModel)]="comp.value" [ngModelOptions]="{standalone: true}" (change)="changeToggleCss(comp)"></mat-slide-toggle>
                                              </div>
                                            </ng-container>
                                        </span >
                                        <span  class="columns col-sm-{{sec.colSize}}" *ngIf="(comp.datatype == 'lookup' && comp.lookupOpen)">
                                          <ng-container
                                          [ngTemplateOutlet]="comp.tableRowClicked && comp.formGeneration && comp.formGeneration.length>0 ? tableRowClick : subgridDisplay"
                                          [ngTemplateOutletContext]="{comp:comp}">
                                          </ng-container>
                                        </span >
                                        <span  class="col-sm-{{sec.colSize}}"*ngIf="(comp.type == 'subgrid' && comp.visible)">
                                          <ng-container
                                            [ngTemplateOutlet]="subgridDisplay"
                                            [ngTemplateOutletContext]="{comp:comp}">
                                          </ng-container>
                                            <!-- <ng-container
                                            [ngTemplateOutlet]="comp.tableRowClicked && comp.formGeneration && comp.formGeneration.length>0 ? tableRowClick : subgridDisplay"
                                            [ngTemplateOutletContext]="{comp:comp}">

                                            </ng-container> -->
                                        </span >
                                        <span *ngIf="(comp.type == 'timeline' && comp.visible)">
                                          <app-notes *ngIf="rowData"
                                            [theme]="theme"
                                            [ownerId]="userId"
                                            [ownerName]="userName"
                                            [tableLogicalName]="tableLogicalName"
                                            [rowId]="this.rowData[this.primaryColumn]"
                                          ></app-notes>
                                        </span >
                                        <span  class="columns col-sm-{{sec.colSize}}" *ngIf="(comp.type == 'blank')">
                                          <div class="blank-box"></div>
                                        </span >
                                        <!-- <ng-container *ngIf="comp.tableRowClicked && comp.formGeneration && comp.formGeneration.length>0">
                                          <ng-container
                                            [ngTemplateOutlet]="tableRowClick"
                                            [ngTemplateOutletContext]="{comp:comp}">
                                          </ng-container>
                                        </ng-container> -->
                                      </ng-container>
                                      </div>
                                </form>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>

    <app-loader *ngIf="showLoader" [icon]='loaderIcon' [width]="'100px'"></app-loader>

</div>

<ng-template #showMessage>
    <div *ngIf="errorMessage !=''">
        {{this.errorMessage}}
      </div>
</ng-template>


<ng-template #tableRowClick let-comp="comp">
  <a target="_blank" [routerLink]="['/details',this.windowOpen]" (click)="passObject(comp)"></a>
<!-- <window > -->
  <!-- <div class="overlayDiv"> -->
        <!-- <app-standard-form
        [formDetails]="comp.formGeneration"
        [rowData] = "comp.clickedRowData"
        [newButton] = "comp.triggeredNewButton"
        [dbTableName] = "comp.dbTableName"
        [primaryColumn] = "comp.primary_column"
        [token]="token"
        [userId]="userId"
        [theme]="theme"
        [businessId]="businessId"
        [tableId]="comp.tableid"
        [allTables]="allTables"
        [formTemplate] = "formTemplate"
        (emitCloseForm)="closeTableForm(comp)"
      ></app-standard-form> -->
    <!-- </div> -->
  <!-- </window> -->

</ng-template>

<ng-template #subgridDisplay let-comp = "comp">
    <div style="margin-top: -20px;">

        <div style="margin-top:13px">
            <app-button
            [visibleButton]="comp.visibleButton"
            [dropdownButton]="comp.dropdownButton"
            [formButton]="comp.showButtons"
            (emitButtonClick)="performTableEvent($event, comp)"
            ></app-button>
        </div>

        <app-angular-table *ngIf="comp.tableData && comp.tableData.length>0 ; else showMessage"
            [initialDisplayedColumns] = "comp.layout"
            [displayedColumnForMobilesPhones] = "comp.mobileLayout"
            [tableAllDataFetched] = 'true'
            [rowData]="comp.tableData"
            [theme]="theme"
            [showSearch]="false"
            [subgridTable]="true"
            (emitSelectedRows)="checkComponentTableRows($event,comp)"
            (emitTableClick)="checkRowClicked($event,comp)"
          ></app-angular-table>
        </div>
</ng-template>

  <app-quick-create  *ngIf="showQuickCreateForm && clickedComp && clickedComp.formGeneration && clickedComp.formGeneration.length>0"
    [formDetails]="clickedComp.formGeneration"
    [rowData] = "clickedComp.clickedRowData"
    [newButton] = "clickedComp.triggeredNewButton"
    [dbTableName] = "clickedComp.dbTableName"
    [primaryColumn] = "clickedComp.primary_column"
    [lookups] = "clickedComp.storeLookups"
    [selectedView] = "clickedComp.selectedView"
    [userId]="userId"
    [userName]="userName"
    [theme]="theme"
    [tableId]="clickedComp.tableid"
    [allTables]="allTables"
    [newFormLookup] = "this.lookups"
    [businessId] = "businessId"
    [parentHeaderData]=" headerData"
    [tableLogicalName]="clickedComp.tableTableLogicalName"
    (emitFormClose) = 'closeQuickCreate($event,clickedComp)'
    (emitRowAdd) = 'getSubgridTableForView(clickedComp)'
    >
  </app-quick-create>


  <app-quick-add  *ngIf="showQuickAddForm && clickedComp && clickedComp.formGeneration && clickedComp.formGeneration.length>0"
    [formDetails]="clickedComp.formGeneration"
    [rowData] = "clickedComp.clickedRowData"
    [newButton] = "clickedComp.triggeredNewButton"
    [dbTableName] = "clickedComp.dbTableName"
    [primaryColumn] = "clickedComp.primary_column"
    [lookups] = "clickedComp.storeLookups"
    [selectedView] = "clickedComp.selectedView"
    [userId]="userId"
    [userName]="userName"
    [theme]="theme"
    [tableId]="clickedComp.tableid"
    [allTables]="allTables"
    [newFormLookup] = "this.lookups"
    [businessId] = "businessId"
    [parentHeaderData]=" headerData"
    [tableLogicalName]="clickedComp.tableTableLogicalName"
    (emitFormClose) = 'closeQuickCreate($event,clickedComp)'
    (emitRowAdd) = 'getSubgridTableForView(clickedComp)'
    >
  </app-quick-add>