import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolFormCaptureService {

  constructor(private commonService: CommonService) {
    this.onLoad()
  }

  onLoad(){
    this.commonService.logging("-- School Form Loaded --")
  }
  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for School')
  }
  onFieldChange(tableId : string, formId : string,comp?: any,formData?: any){
    this.commonService.logging('On field Change for School')
    if(comp.datatype == 'optionset' && (comp.id.includes('eng_endtime') || comp.id.includes('eng_starttime'))){
      this.validateEndTime(comp,formData)
    }
  }
  onSubgridRefresh(tableId : string, formId : string){
    this.commonService.logging('On Subgrid Refresh for School')
    return false
  }

  validateEndTime(comp1: any,formData: any){
    let endTimeData = comp1.selectedOptionLabel
    let startTimeData : {[key: string]: string} = {}
    if(comp1.selectedOptionLabel){
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.id && (comp.id).includes('eng_starttime')){
              startTimeData = comp.selectedOptionLabel
              comp.errorMessage = ''
            }
            if(comp.id && (comp.id).includes('eng_endtime')){
              endTimeData = comp.selectedOptionLabel
              comp.errorMessage = ''
            }
          }
        }
      }
      let numEndTime = Number(endTimeData.key.replace(':','.'))
      let numStartTime = Number(startTimeData.key.replace(':','.'))
      if(numEndTime < numStartTime){
        comp1.errorMessage = "End Time cannot be less than Start time"
      }
      else if(numEndTime == numStartTime){
        comp1.errorMessage = "End Time cannot be equal to Start time"
      }
      else{
        comp1.errorMessage = ''
      }
    }
  }
}
