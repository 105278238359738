import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class LeadsFormCaptureService {

  constructor(private commonService : CommonService) {

  }

  onLoad(formData: any){
   this.commonService.logging("-- Leads Form Loaded --")
   this.showSchoolOrStudentByLeadOrigin(formData)
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Lead')
  }

  onFieldChange(tableId : string, formId : string, formData : any){
    this.commonService.logging('On field Change for Lead')
    this.showSchoolOrStudentByLeadOrigin(formData,true)
  }

  onSubgridRefresh(tableId : string, formId : string){
    this.commonService.logging('On Subgrid Refresh for Lead')
    return false
  }

  setFormData(tableId : string, formId : string,formData :  any,headerData : any){
    let labelName = ""
    if(headerData){
      for(let i of headerData){
        if(i.primaryField){
          labelName = i.label
        }
      }
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if((comp.id).includes('eng_leadorigin')){
              for(let op of comp.options){
                if((labelName).includes(op.key)){
                  comp.selectedOptionLabel = op
                }
              }
            }
            if((comp.id).includes('eng_leadtype')){
              for(let op of comp.options){
                if((labelName).includes(op.key.trim())){
                  comp.selectedOptionLabel = op
                }
                else if((labelName.includes('Student'))){
                  comp.selectedOptionLabel = op
                }
              }
            }
          }
        }
      }
    }
  }

  createTableInsights(tableId : string, formId : string,rowData :  any){
    // console.log(rowData)
    let statusMap = new Map<string,number>()
    let count = 1
    for(let row of rowData){
      for(let i in row){
        if(i == 'eng_status@OData.Community.Display.V1.FormattedValue'){
          if(statusMap.has(row[i])){
            count++
          }
          else{
            count=1
          }
          statusMap.set(row[i],count)
        }
      }
    }
    // console.log(statusMap)
  }

  showSchoolOrStudentByLeadOrigin(formData : any,fieldChange?: boolean){
    let leadOrigin : {[key: string]: string} = {}
      for(let i of formData){
        for(let sec of i.section){
          for(let comp of sec.components){
            if(comp.datatype == "optionset" && comp.id.includes("eng_leadorigin")){
              leadOrigin = comp.selectedOptionLabel
            }
          }
        }
      }
      if(leadOrigin && leadOrigin.key && leadOrigin.key !=='--select--'){
        for(let i of formData){
          for(let sec of i.section){
            for(let comp of sec.components){
              if(comp.datatype=="lookup"){
                if(leadOrigin.key == "Student"){
                  if(comp.id == "eng_Student"){
                    comp.required = true
                    comp.visible = true
                  }
                  else if(comp.id == "eng_School"){
                    comp.required = false
                    comp.visible = false
                    // comp.selectedOptionLabel = {
                    //   value: undefined,
                    //   id : undefined
                    // }
                  }
                }
                else if(leadOrigin.key == "School"){
                  if(comp.id == "eng_Student"){
                    comp.required = false
                    comp.visible = false
                    // comp.selectedOptionLabel = {
                    //   value: undefined,
                    //   id : undefined
                    // }
                  }
                  else if(comp.id == "eng_School"){
                    comp.required = true
                    comp.visible = true
                  }
                }
                // if(comp.id == "eng_Student"){
                //   if(leadOrigin.key == "Student"){
                //     comp.required = true
                //     comp.visible = true
                //   }
                //   else if(leadOrigin.key == "School"){
                //     comp.required = false
                //     comp.visible = false
                //   }
                // }
                // if(comp.id == "eng_School"){
                //   if(leadOrigin.key == "School"){
                //     comp.required = true
                //     comp.visible = true
                //   }
                //   else if(leadOrigin.key == "Student"){
                //     comp.required = false
                //     comp.visible = false
                //   }
                // }
              }
            }
          }
        }
      }
      else{
        for(let i of formData){
          for(let sec of i.section){
            for(let comp of sec.components){
              if(comp.id == "eng_Student" || comp.id == "eng_School"){
                comp.required = false
                comp.visible = false
              }
            }
          }
        }
      }
  }
}
