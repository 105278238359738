import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { DEFAULT_APP_ICON } from 'src/app/app.global';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application-service/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsAndConditionComponent } from '../terms-and-condition/terms-and-condition.component';
import { TableOperationService } from 'src/app/services/table-operation-service/table-operation.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  user !: any
  userId !: string
  content !: string[]
  tncAccept : string = ""
  profile !: any
  userApplications !: any
  userRoles !: any

  iconWidth : any = 90
  iconHeight : any = 90
  loaderIcon !:string
  theme : {[key: string]: string} = {}
  message !: string
  showLoader : boolean = false
  aboutToExpire = false
  Difference_In_Days = 0
  subscriptionMessage = ""
  constructor(
    public commonService: CommonService,
    private router: Router,
    private applicationService : ApplicationService,
    private dialog: MatDialog,
    private tableOperationService : TableOperationService,
    ) { }
  ngAfterViewInit(): void {
    if(this.theme){
      let elem1 = <HTMLElement>document.getElementsByClassName("businessName")[0]
      if(elem1 && this.theme["myapps-bu_name_text_clr"]){
        elem1.style.color = this.theme["myapps-bu_name_text_clr"]
        elem1.style.borderColor = this.theme['main-header_clr']
      }
      let elem2 = document.getElementsByClassName("card")
      for (let i = 0; i < elem2.length; i++) {
        if(elem2[i]){
          (<HTMLElement>elem2[i]).style.backgroundColor = this.theme["myapps-app_cards_clr"];
          (<HTMLElement>elem2[i]).style.borderColor = this.theme["myapps-app_cards_borderline_clr"];
          (<HTMLElement>elem2[i]).style.color = this.theme["myapps-app_cards_text_clr"];
        }
      }
      let elem3 = <HTMLElement>document.getElementsByClassName("myapps")[0]
      if(elem3){
        elem3.style.backgroundColor = this.theme["myapps-background_clr"]
      }

      let elem4 = <HTMLElement>document.getElementsByClassName("headerName")[0]
      if(elem4 && this.theme["main_header_text_clr"]){
        elem4.style.color = this.theme["main_header_text_clr"]
      }
    }
    let tncAgree = localStorage.getItem('tncAgree')
    if(this.tncAccept=="" && tncAgree!=='true'){
      this.showTermsAndConditions()
    }
  }

  ngOnInit(): void {
    // this.showLo ader = true
    /**Get User*/
    this.commonService.currentUser$.subscribe((u : any)=>{
      this.user = u
      this.userId = u.cre57_at_userid
      if(this.user.cre57_userprofile){
        this.profile = JSON.parse(this.user.cre57_userprofile)
        this.userApplications = this.profile.apps
        for(const u of this.userApplications){
          u.AppIcon = u.AppIcon ?
          u.AppIcon : (u.cre57_image ? "data:image/jpg;base64,"+u.cre57_image : "data:image/jpg;base64,"+DEFAULT_APP_ICON)
        }
        this.userRoles  = this.profile.roles
        this.user.cre57_role = this.userRoles[0].Name
        localStorage.setItem('user',JSON.stringify(this.user))
        localStorage.setItem('accessConfig',this.userRoles[0]["access_config"])

        let info = localStorage.getItem("primaryContactInfo")
        let primaryContactInfo1 = ""
        if(info){
          primaryContactInfo1 = JSON.parse(info)
        }
        this.theme = JSON.parse(this.profile.business.Theme)
        if(this.theme){
          this.iconHeight =  this.theme["main-home_icon_h"] ?  this.theme["main-home_icon_h"] : '90'
          this.iconWidth =  this.theme["main-home_icon_w"] ? this.theme["main-home_icon_w"] : '90'
          this.loaderIcon = this.theme['progress-bar_icon']
        }

        if(this.profile.subcriptions){
          let endDate = new Date(this.profile.subcriptions[0].EndDate)
          let currentDate = new Date()
          this.aboutToExpire = true
          let Difference_In_Time = endDate.getTime() - currentDate.getTime();
          this.Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          this.Difference_In_Days = Number(~~this.Difference_In_Days)+1
          if(this.Difference_In_Days == 0){
            this.subscriptionMessage = "Your subscription will expire today"
          }
          else{
            this.subscriptionMessage = "Your subscription is will expire in next "+ this.Difference_In_Days +" days"
          }   
        }

        if(this.profile.business && this.profile.business.PrimeryContactsInfo && primaryContactInfo1==""){
          let primaryContactInfo = JSON.parse(this.profile.business.PrimeryContactsInfo)
          let contactActionapi = primaryContactInfo.api
          let businessId = (this.profile.business.Id).replace(/-/g,'_')
          this.applicationService.getBusinessContacts(businessId,contactActionapi,this.commonService.getAuthToken()).subscribe((res) => {
            if(res && res.value){
              localStorage.setItem('primaryContactInfo', JSON.stringify({
                email : primaryContactInfo.email,
                mobile: primaryContactInfo.mobile,
                name : primaryContactInfo.name,
                contacts: res.value
              }))
            }
          })
        }

        if(this.profile.tnc){
          this.content = this.profile.tnc.Condtions.split('*')
          this.tncAccept = this.profile.tnc.SignedOn
        }


        let info2 = localStorage.getItem("primaryUserInfo")
        let primaryUserInfo1 = ""
        if(info2){
          primaryUserInfo1 = JSON.parse(info2)
        }

        if(this.profile.business && this.profile.business.PrimeryUsersInfo && primaryUserInfo1==""){
          let primaryUserInfo = JSON.parse(this.profile.business.PrimeryUsersInfo)
          let contactActionapi = primaryUserInfo.api
          let businessId = (this.profile.business.Id).replace(/-/g,'_')
          this.applicationService.getBusinessContacts(businessId,contactActionapi,this.commonService.getAuthToken()).subscribe((res) => {
            if(res && res.value){
              localStorage.setItem('primaryUserInfo', JSON.stringify({
                userId : primaryUserInfo.userid,
                userName: primaryUserInfo.username,
                userDetails: res.value
              }))
            }
          })
        }
      }
    })
  }

  closeMessage(){
    this.aboutToExpire = false
  }

  applicationClicked(application: any){
    localStorage.setItem('application',JSON.stringify(application))
    this.router.navigate(['application'])
  }

  showTermsAndConditions(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%'
    dialogConfig.height = '83%'

    dialogConfig.data = {
      buttonText: {
        ok: 'Agree'
      },
      dataInfo: this.content,
      buttonColor: this.theme['main-header_clr']
    };

    if(this.content && this.content.length>0){
      const dialogRef = this.dialog.open(TermsAndConditionComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((status: any) => {
        if(status){
          let payload = {
            "cre57_termsandconditionssignedon": new Date()
          }
          this.tableOperationService.updateTableRecords(this.userId,this.commonService.getAuthToken(),'cre57_at_users',payload).subscribe((res)=>{
            this.commonService.logging(res)
            if(res && res.status && res.status == "True"){
              localStorage.setItem('tncAgree',"true")
            }
          })
        }
      })
    }
  }
}
