import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { AUTH_TOKEN, EXPORT_CSV, FACE_DETECT, FILE_OPERATIONS, GET_NOTES, GET_USER_BY_USERNAME_PASSOWRD, RIBBON_ACTION, SETTINGS, SHARE_DATA, USER_APPS, USER_ROLES} from '../../app.global';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFqYWluQGFtYXlyYXRlY2guY29tIiwibmJmIjoxNjUyMTYzNTM5LCJleHAiOjE2ODM2OTk1MzksImlhdCI6MTY1MjE2MzUzOX0.iFjVbuSYAybzp_kp5aBe2FGidtOfNLtV630p9kYmjYk"
  }),
  withCredential: true
}
@Injectable({
  providedIn: 'root'
})
export class TableOperationService {

  constructor(private http: HttpClient,private envService: EnvService) { }

  updateTableRecords(rowId: string,token: string,tableName: string,payload: any){
    let endpointUrl = this.envService.apiUrl+"/u-"+tableName
    endpointUrl += "("+rowId+")"
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.patch(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  createTableRecords(token: string,tableName: string,payload: any){
    let endpointUrl = this.envService.apiUrl+"/c-"+tableName
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  deleteTableRecord(rowId: string,token: string,tableName: string){
    let endpointUrl = this.envService.apiUrl+"/r-"+tableName
    endpointUrl += "("+rowId+")"
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    let payload = {
      "statecode": "1"
    }
    return this.http.patch(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  ribbonActionOnRecord(token: string,tableName: string,recordId : string,uid: string){
    let endpointUrl = this.envService.apiUrl+RIBBON_ACTION
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    let payload = {
        "tablename": tableName,
        "recordid":recordId,
        "ribbonuid": uid
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
 
  }

  shareTableRecord(jobDetails : any,token : string){
    let endpointUrl = this.envService.apiUrl+SHARE_DATA
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }

    let payload = {
      "cre57_whatsappstatus": 647510000,
      "cre57_emailstatus": 647510000,
      "cre57_jobdetails": JSON.stringify(jobDetails)
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  assignTableRecord(payload : any,rowId : string, tableName : string,token : string){
    let endpointUrl = this.envService.apiUrl+"/as-"+tableName
    endpointUrl += "("+rowId+")"
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.patch(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  getNotes(token : string,rowId: string,tableName: string):Observable<any>{
    let endpointUrl = this.envService.apiUrl+GET_NOTES+'/'
    endpointUrl+=tableName +":"+rowId+GET_NOTES
    var options = {
      'method': 'GET',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.get(endpointUrl,options).pipe(map(res=>res as any));
  }

  exportTableRecord(tableId : string, viewId : string,selectedIds : string,token : string){
    let endpointUrl = this.envService.apiUrl+EXPORT_CSV
    var options = {
      'method': 'PATCH',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    let payload = {
      "tableid":tableId,
      "viewid":viewId,
      "ids": selectedIds
    }

    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  fileOperationOnRecord(payload : any,token : string){
    let endpointUrl = this.envService.apiUrl+FILE_OPERATIONS
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    let payload1 = {
      "json" : JSON.stringify(payload)
    }
    return this.http.post(endpointUrl,payload1,options).pipe(map(res=>res as any));
  }

  faceDetection(payload : any,token : string){
    let endpointUrl = this.envService.apiUrl+FACE_DETECT
    var options = {
      'method': 'POST',
      'url': endpointUrl,
      'headers': {
        'Authorization': 'Bearer '+token
      }
    }
    return this.http.post(endpointUrl,payload,options).pipe(map(res=>res as any));
  }

  removeShareButton(buttons : any){
    for(let i=0; i<buttons.length;i++){
      if(buttons[i].actionApi == "Share"){
        buttons.splice(i,1)
      }
    }
    return buttons
  }

}
