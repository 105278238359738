import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './common/home-page/home-page.component';
// import { LoginComponent } from './login/login-component';
import { ProfileComponent } from './common/profile/profile.component';

const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('./common/home-page/home.module').then(m => m.HomeModule),
    outlet:'parent'
  },
  // {
  //   path:'',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
