<!-- <app-loader *ngIf="!chartCreated" [marginTop]="'300px'"></app-loader> -->
<ng-container *ngIf="!checkin && !promo">
  <ng-container>
    <div class="app-container" id="app-container" *ngIf="tableData && dashboardData; else showMessageDashboard"
    [ngStyle]="{
      'opacity': quickCreateAvailable || quickCreateAvailableForLookUp ? '0.2' : '1',
      'pointer-events': quickCreateAvailable || quickCreateAvailableForLookUp ? 'none' : 'all'
    }"
    
    
    >
      <span class="headerName" [ngStyle]="{
        'color' : header_clr ? header_clr : '#FFF'
      }"> <img [src]="selectedIcon" class="header-icon">{{headerName}} {{selectedContent}}</span>
      <div class="header-nav"
        [ngStyle]="{
          'border-bottom' : showButtons.length>0 ? '1px solid #d1d1d1' : 'none',
          'background-color' : header_nav_backgroundColor ? header_nav_backgroundColor : '#FFF'
        }"
      >
        <app-side-nav
         [tableData] = "tableData"
         [dashboardData] = "dashboardData"
         [theme]="theme"
         (emitsideNavState)="sideNavStatus($event)"
         (emitSelectedSideNav) = "selectedSideNav($event)"
        ></app-side-nav>
  
        <div class="allButtons" style="display:inline-flex" *ngIf="showButtons.length>0" [ngClass]="{
          'display-allButtons' : showButtons.length>6
        }">
        <div *ngFor="let b of showButtons">
          <div class="headButton" *ngIf="b.show" (click)="performSelectedTask(b)">
            <span ><img *ngIf="b.Icon!=''" class="button-icon" [src]="b.Icon">
              <span class="button-text"  [ngStyle]="{
                'color' : button_text_clr ? button_text_clr : '#000'
              }">{{b.Label}}</span>
            </span>
          </div>
        </div>
        </div>
  
        <div *ngIf="visibleButton.length>0" class="display-buttonDropdown">
          <div class="allButtons">
            <div class="headButton" *ngFor="let b of visibleButton" (click)="performSelectedTask(b)">
              <img *ngIf="b.Icon!='' && b.show" class="button-icon" [src]="b.Icon">
              <span class="button-text" *ngIf="b.show" [ngStyle]="{
                'color' : button_text_clr ? button_text_clr : '#000'
              }">{{b.Label}}</span>
            </div>
          </div>
          <div class="dropdown" dropdown>
            <div class="headButton" style="padding: 2px;" data-toggle="dropdown" dropdownToggle>
              <img src="../../../assets/Images/dotMenu.png" class="dotMenu" >
            </div>
            <div class="dropdown-menu"  style="margin-top: 15px;margin-left:-100px">
                <div *ngFor="let item of dropdownButton" class="dropdown-item" style="padding:10px 13px;font-size:14px" (click)="performSelectedTask(item)">
                  <img *ngIf="item.Icon!=''" class="button-icon" [src]="item.Icon"><span class="button-text" [ngStyle]="{
                    'color' : button_text_clr ? button_text_clr : '#000'
                  }">{{item.Label}}</span>
                </div>
            </div>
          </div>
  
        </div>
      </div>
  
  
  
      <div *ngIf="showDashboard" [ngStyle]="{
        'background-color' : page_background_clr ? page_background_clr : '#FFF'
      }" class="dashboard-container">
        <div class="header-nav filter-nav">
          <div class="filter-label">Filter</div>
          <div class="dropdown-ngSelect" *ngIf="daysFilter && daysFilter.length>0">
            <ng-select [(ngModel)]="selectedUser" placeholder="Select Timeline"   (change)="selectedTimeline($event)">
              <ng-option *ngFor="let elem of daysFilter" [value]="elem">{{elem.labelName}}</ng-option>
            </ng-select>
          </div>
          <div class="dropdown-ngSelect" *ngIf="userDetails && userDetails.length>0">
            <ng-select [(ngModel)]="selectedUser" placeholder="Select User"   (change)="selectedUserData($event)">
              <ng-option *ngFor="let elem of userDetails" [value]="elem">{{elem.labelName}}</ng-option>
            </ng-select>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" [ngModel]="range.value.start" placeholder="Start date">
              <input matEndDate formControlName="end" [ngModel]="endDate" placeholder="End date">
            </mat-date-range-input>
  
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
  
          <button class="dashboardButton" (click)="applyDashboardFilter()">Apply</button>
          <button class="dashboardButton" (click)="resetDashboardFilter()">Reset</button>
        </div>
        <div class="row" *ngIf="dashboardKPIs && dashboardKPIs.length>0; else showMessageDashboard">
          <ng-container *ngFor="let userA of dashboardKPIs">
            <div class="card col-md-4" [ngStyle]="{
              'color' : dashboard_app_card_text_color ? dashboard_app_card_text_color : '#000',
              'background-color' :  dashboard_app_cards_color ? dashboard_app_cards_color : '#FFF',
              'border-color' :  dashboard_app__borderline_color ? dashboard_app__borderline_color : ''
            }"  *ngIf="commonService.checkAccess(userA.Id,'show')">
              <div class="card-img-top">
                <ng-container *ngIf="(userA.chartData  &&  userA.chartData[0] && userA.chartData[0].data && userA.chartData[0].data.length>0) || (userA.chartData && userA.chartData.image) ||  userA.chartType == 'webresource';  else showNoChartData">
                  <canvas baseChart *ngIf="userA.chartType !== 'value' && userA.chartType !== 'webresource'; else showValueChart "
                  [datasets]="userA.chartData"
                  [options]="userA.chartOptions"
                  [chartType]="userA.chartType"
                  [legend]="userA.chartLegend"
                  [labels]="userA.chartLabels"
                  [plugins]="userA.plugins"
                  >
                  </canvas>
                  <ng-template #showValueChart>
                    <div align="center" *ngIf="userA.chartType == 'value'">
                      <img class="value-chart-image" [src] = "userA.chartData.image">
                      <div class="value-chart-label">{{userA.chartData.value}}</div>
                    </div>
                    <div align="center" *ngIf="userA.chartType == 'webresource'">
                      <div [innerHtml]="userA.chartData"></div>
                      <div class="web-chart-label">{{userA.chartLabels}}</div>
                    </div>
                  </ng-template>
                </ng-container>
  
                <ng-template #showNoChartData>
                  <div align="center">
                    <div class="value-chart-label">No Data Available</div>
                  </div>
                </ng-template>
  
              </div>
              <div class="card-body">
                <h4 class="card-title"><a>{{userA.label}}</a></h4>
              </div>
            </div>
  
            </ng-container>
        </div>
      </div>
  
  
      <div *ngIf="showTable">
        <div class="main-container" style="width:99%" *ngIf="(viewDataAvailable && !tableRowClicked) || (quickCreateAvailableForLookUp || quickCreateAvailable); else showMessageDashboard"
        [ngStyle]="{
          'background-color' : page_background_clr ? page_background_clr : '#FFF'
        }"
        >
        <div class="table-top">
          <div class="dropdown-content" *ngIf="viewList && viewList.length>0">
            <select class="dropdown-toggle custom-select" (change)="getTableForSelectedView($event)" [ngStyle]="{
              'color' : view_dropdown_text_clr ? view_dropdown_text_clr : '#000'
            }" [(ngModel)]="viewChoose.Name">
            <ng-container *ngFor="let elem of viewList">
              <option class="custom-option"  *ngIf="commonService.checkAccess(elem.Id,'show')" value="{{elem.Name}}">{{elem.Name}}</option>
            </ng-container>
            </select>
            </div>
        </div>
  
          <div *ngIf="tableDataAvailable; else showMessageDashboard">
            <app-angular-table *ngIf="rowData"
              [initialDisplayedColumns] = "layout"
              [displayedColumnForMobilesPhones] = "mobileLayout"
              [rowData]="rowData"
              [theme]="theme"
              [viewList]="viewList"
              [selectedView]="selectedView"
              [sideNav] = "sideNavClose"
              [tableAllDataFetched] = 'tableAllDataFetched'
              (emitSelectedRows) = "checkTableRows($event)"
              (emitTableClick)="checkRowClicked($event)"
              (emitSelectedView) ="getTableForSelectedView($event)"
              (emitCellClicked) = "getCellClickedData($event)"
            ></app-angular-table>
          </div>
  
        </div>
      </div>
      <app-loader *ngIf="showLoader" [icon]='loaderIcon'  [width]="'100px'"></app-loader>
    </div>
  </ng-container>
  
  <ng-template #showMessageDashboard>
    <!-- <app-loader *ngIf="showLoader" [marginTop]="'200px'"></app-loader> -->
    <div class="headerName mt-160" *ngIf="errorMessageDashboard && errorMessageDashboard !=''">
      {{this.errorMessageDashboard}}
    </div>
  </ng-template>
  
  <app-standard-form *ngIf="tableRowClicked && (formGeneration[0].Type=='Standard')"
    [formDetails]="formGeneration"
    [rowData] = "clickedRowData"
    [newButton] = "triggeredNewButton"
    [dbTableName] = "dbTableName"
    [primaryColumn] = "primaryColumn"
    [userId]="userId"
    [userName]="userName"
    [theme]="theme"
    [tableId]="tableId"
    [allTables]="tableData"
    [tableLogicalName]="tableLogicalName"
    [businessId] = "businessId"
    [selectedView] = "selectedView"
    [comp]="comp"
    [formTemplate] = "formTemplate"
    (emitCloseForm)="closeTableForm($event)"
  ></app-standard-form>
  
  <app-quick-create  *ngIf="tableRowClicked && formGeneration[0].Type=='Quick Create' && quickCreateAvailable"
    [formDetails]="formGeneration"
    [rowData] = "clickedRowData"
    [newButton] = "triggeredNewButton"
    [dbTableName] = "dbTableName"
    [primaryColumn] = "primaryColumn"
    [selectedView]="selectedView"
    [userId]="userId"
    [userName]="userName"
    [theme]="theme"
    [tableId]="tableId"
    [allTables]="tableData"
    [tableLogicalName]="tableLogicalName"
    [businessId] = "businessId"
    (emitFormClose) = 'closeQuickCreate($event)'
    (emitLookupElement) = 'sendLookUpComponent($event)'
  ></app-quick-create>

  <app-quick-add  *ngIf="tableRowClicked && formGeneration[0].Type=='Quick Add' && quickAddAvailable"
  [formDetails]="formGeneration"
  [rowData] = "clickedRowData"
  [newButton] = "triggeredNewButton"
  [dbTableName] = "dbTableName"
  [primaryColumn] = "primaryColumn"
  [selectedView]="selectedView"
  [userId]="userId"
  [userName]="userName"
  [theme]="theme"
  [tableId]="tableId"
  [allTables]="tableData"
  [tableLogicalName]="tableLogicalName"
  [businessId] = "businessId"
  (emitFormClose) = 'closeQuickAdd($event)'
  (emitLookupElement) = 'sendLookUpComponent($event)'
></app-quick-add>
  
  <app-quick-create  *ngIf="formGenerationQuick && formGenerationQuick[0].Type=='Quick Create' && quickCreateAvailableForLookUp"
    [formDetails]="formGenerationQuick"
    [rowData] = "clickedRowDataQuick"
    [newButton] = "triggeredNewButtonQuick"
    [dbTableName] = "dbTableNameQuick"
    [primaryColumn] = "primaryColumnQuick"
    [selectedView]="selectedViewQuick"
    [userId]="userId"
    [userName]="userName"
    [theme]="theme"
    [tableId]="tableIdQuick"
    [allTables]="tableData"
    [tableLogicalName]="tableLogicalNameQuick"
    [businessId] = "businessId"
    (emitFormClose) = 'closeQuickCreateForLookUp($event)'
    (emitLookupElement) = 'sendLookUpComponent($event)'
  ></app-quick-create>
</ng-container>

<ng-container *ngIf="checkin">
  <checkin-checkout-component 
   [application]="application"
   [theme] = "theme"
   ></checkin-checkout-component>
</ng-container>

<ng-container *ngIf="promo">
 <app-promotion
  [application]="application"
  [theme]="theme"></app-promotion>
</ng-container>

<!-- <app-new-form *ngIf="triggeredNewButton">

</app-new-form> -->
<!-- <app-form *ngIf="tableRowClicked"
  [formDetails]="formGeneration"
  [rowData] = "clickedRowData"
  [newButton] = "triggeredNewButton"
  [dbTableName] = "dbTableName"
  [primaryColumn] = "primaryColumn"
  [token]="authToken"
  [userId]="userId"
  [tableId]="tableId"
  [formTemplate] = "formTemplate"
  (emitCloseForm)="closeTableForm($event)"
></app-form> -->

<!-- <div class="modal" id="myTableModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="height:260px;">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeTableModal()">
          <span aria-hidden="true">&times;</span>
        </button>
       <h3>Some random modal</h3>
      </div>
    </div>
  </div>
</div> -->
<!-- <ng-template #showMessageTable>
  <app-loader *ngIf="!errorMessageTable || errorMessageTable ==''"  [marginTop]="'300px'"></app-loader>
  <div class="headerName mt-160" *ngIf="errorMessageTable && errorMessageTable !=''">
    {{this.errorMessageTable}}
  </div>
</ng-template> -->

