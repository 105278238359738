import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class TaskFormCaptureService {

  constructor(private commonService: CommonService) {
    this.onLoad()
  }

  onLoad(){
   this.commonService.logging("-- Task Form Loaded --")
  }

 onSave(tableId : string, formId : string){
  this.commonService.logging('Save data for Task')
 }

 onFieldChange(tableId : string, formId : string){
  this.commonService.logging('On field Change for Task')
 }

 onSubgridRefresh(tableId : string, formId : string){
  this.commonService.logging('On Subgrid Refresh for Task')
  return false
 }
}
