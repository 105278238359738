<div style="text-align:center;margin-top: 65px;">
	<div>
		<webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
		 [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
		 (initError)="handleInitError($event)"></webcam>
		<br/>
		<!-- <div class="actionBtn" title="Take Picture">📸</div> -->
		<div class="actionBtn" (click)="showNextWebcam(true);" title="Switch Camera">🔁</div>
		<div>
			<div class="center-content" *ngIf="checkin"><button class="btn btn-primary checkin"  (click)="triggerSnapshot('check-in');" >Check In</button></div>
			<div class="center-content"  *ngIf="checkout"><button class="btn btn-primary checkout center-content"  (click)="triggerSnapshot('check-out')">Check Out</button></div>
		</div>
	</div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul>