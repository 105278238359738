import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentFormCaptureService {

  constructor(private commonService : CommonService) {
    this.onLoad()
  }

  onLoad(){
   this.commonService.logging("-- Enrollment Form Loaded --")
  }

  onSave(tableId : string, formId : string){
    this.commonService.logging('Save data for Enrollment')
  }

  onFieldChange(tableId : string, formId : string){
    this.commonService.logging('On field Change for Enrollment')
  }

  onSubgridRefresh(tableId : string, formId : string){
    this.commonService.logging('On Subgrid Refresh for Enrollment')
    return false
  }
}
